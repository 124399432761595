import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Popconfirm, Button, Tag } from 'antd';
import moment from 'moment';
import { AdStatus, AdStatusColor } from '../../config/ad';
import { BarChartOutlined } from '@ant-design/icons';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { encodeQueryString } from '../../api/helpers';

export const adListTableColumns = ({ onDelete, onUpdateAdStatus }) => [
  {
    title: 'Ad Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    render: (id, record) => <Link to={`/ads/${record.id}/edit`}>{id}</Link>,
  },
  {
    title: 'Report',
    dataIndex: 'id',
    key: 'chart',
    render: (id, record) => (
      <Link
        to={`/report/campaign?adId=${record.id}&campaignId=${record.campaign?.id}&advertiserId=${
          record.campaign?.advertiser?.id
        }&start=${encodeQueryString(
          moment(record.startAt).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ'),
        )}&end=${encodeQueryString(moment(record.endAt).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ'))}`}
      >
        <BarChartOutlined />
      </Link>
    ),
  },
  {
    title: 'Ad',
    dataIndex: 'name',
    key: 'name',
    className: 'nowrap',
    render: (text, record) => <Link to={`/ads/${record.id}/edit`}>{text}</Link>,
  },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
    className: 'nowrap',
    render: (campaign, record) => <Link to={`/campaign/${campaign.id}`}>{campaign.name}</Link>,
    sorter: (a, b) => (a.campaign.name < b.campaign.name ? -1 : 1),
    sortDirections: ['ascend', 'descend'],
  },
  // {
  //   title: "Type",
  //   dataIndex: "adUnit",
  //   key: "type",
  //   render: (adUnit) => <span>{adUnit.adGroup.adGroupType.name}</span>,
  //   sorter: (a, b) =>
  //     a.adUnit.adGroup.adGroupType.name < b.adUnit.adGroup.adGroupType.name
  //       ? -1
  //       : 1,
  //   sortDirections: ["ascend", "descend"],
  // },
  {
    title: 'Quota',
    dataIndex: 'quota',
    key: 'quota',
    render: (quota) => <span>{quota.toLocaleString()}</span>,
  },
  {
    title: 'Ad Unit',
    dataIndex: 'adUnit',
    key: 'adUnit',
    className: 'nowrap',
    render: (adUnit) => (
      <Link to={`/setting/adunit/${adUnit.id}`}>
        {adUnit.adGroup.publisher.countryCode}: {adUnit.adGroup.name}
      </Link>
    ),
    sorter: (a, b) => (a.adUnit.adGroup.name < b.adUnit.adGroup.name ? -1 : 1),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: Object.keys(AdStatus).map((x) => ({
      text: AdStatus[x],
      value: x,
    })),
    render: (status) => <Tag color={AdStatusColor[status]}>{AdStatus[status]}</Tag>,
    onFilter: (value, record) => record.status === value,
  },

  // {
  //   title: "Clicks",
  //   dataIndex: "click",
  //   key: "click",
  //   render: (click) => <span>{click}</span>,
  // },
  {
    title: 'Start',
    dataIndex: 'startAt',
    key: 'startAt',
    className: 'nowrap',
    render: (startAt) => (
      <span>
        <Tooltip title={moment(startAt).format('D MMM YY HH:mm')}>{moment(startAt).format('D MMM YY')}</Tooltip>
      </span>
    ),
    sorter: (a, b) => new Date(a.startAt) - new Date(b.startAt),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'End',
    dataIndex: 'endAt',
    key: 'endAt',
    className: 'nowrap',
    render: (endAt) => (
      <span style={moment().diff(moment(endAt)) > 0 ? { color: 'red' } : null}>
        <Tooltip title={moment(endAt).format('D MMM YY HH:mm')}>{moment(endAt).format('D MMM YY')}</Tooltip>
      </span>
    ),
    sorter: (a, b) => new Date(a.endAt) - new Date(b.endAt),
    sortDirections: ['ascend', 'descend'],
  },
  // {
  //   title: "Create",
  //   dataIndex: "createdDate",
  //   key: "createdDate",
  //   className: "nowrap",
  //   render: (createdDate) => (
  //     <span>
  //       <Tooltip title={moment.utc(createdDate).format("D MMM YYYY HH:mm")}>
  //         {moment.utc(createdDate).format("D MMMM YYYY")}
  //       </Tooltip>
  //     </span>
  //   ),
  //   sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
  //   sortDirections: ["ascend", "descend"],
  // },
  // {
  //   title: "Set To",
  //   key: "actionStatus",
  //   style: { paddingTop: 18 },
  //   className: "nowrap",
  //   render: (text, record) => <></>,
  // },
  {
    title: 'Action',
    key: 'action',
    style: { paddingTop: 18 },
    className: 'nowrap bg-white',
    render: (text, record) => (
      <>
        {(record.status === 'Preview' || record.status === 'Unpublish') && (
          <span style={{ marginRight: 12 }}>
            <Popconfirm title="Confirm Publish this Ad?" onConfirm={() => onUpdateAdStatus(record.id, 'Publish')}>
              <Button style={{ minWidth: 90 }} className="ant-tag-green-inverse" size="small">
                Publish
              </Button>
            </Popconfirm>
          </span>
        )}
        {record.status === 'Publish' && (
          <Popconfirm title="Confirm Unpublish this Ad?" onConfirm={() => onUpdateAdStatus(record.id, 'Unpublish')}>
            <span style={{ marginRight: 12 }}>
              <Button style={{ minWidth: 90 }} className="ant-tag-gold-inverse" size="small">
                Unpublish
              </Button>
            </span>
          </Popconfirm>
        )}
        {record.status === 'Wait' && (
          <span style={{ marginRight: 12 }}>
            <Popconfirm
              title="Confirm Approve this Ad?"
              style={{ width: 300, minWidth: 300 }}
              onConfirm={() => onUpdateAdStatus(record.id, 'Preview')}
            >
              <Button style={{ minWidth: 90 }} className="ant-tag-pink-inverse" size="small">
                Approve
              </Button>
            </Popconfirm>
          </span>
        )}
        <span style={{ marginRight: 12 }}>
          <Link className="ant-btn ant-btn-circle ant-btn-primary ant-btn-sm" to={`/ads/${record.id}/edit`}>
            <EditFilled />
          </Link>
        </span>
        <span style={{ marginRight: 12 }}>
          <Popconfirm
            placement="top"
            title="Confirm Delete?"
            okButtonProps={{ size: 'middle' }}
            cancelButtonProps={{ size: 'middle' }}
            onConfirm={() => onDelete(record.id)}
            okText="Confirm Delete"
            cancelText="No"
          >
            <Button type="primary" size="small" shape="circle" danger>
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </span>
        {/* {record.status && record.status === "Wait" && (
          <span style={{ marginRight: 12 }}>
            <Button type="primary" size="small">
              Approve
            </Button>
          </span>
        )} */}
      </>
    ),
  },
];
