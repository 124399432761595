import React from 'react';

export const campaignSummaryTableColumns = [
  // {
  //   title: "Advertiser",
  //   dataIndex: "advertiser",
  //   key: "advertiser",
  // },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
  },
  {
    title: 'Ad Name',
    dataIndex: 'ad',
    key: 'ad',
  },
  {
    title: 'quota',
    dataIndex: 'quota',
    key: 'quota',
    render: (value) => <span>{value?.toLocaleString()}</span>,
  },
  {
    title: 'impression',
    dataIndex: 'totalImpression',
    key: 'impression',
    render: (value) => <span>{value.toLocaleString()}</span>,
  },
  {
    title: 'reach/target',
    dataIndex: 'totalReach',
    key: 'reach',
    render: (value, record) => (
      <span>
        {value?.toLocaleString()}/{Number(record.targetReach?.toFixed(1)).toLocaleString()} (
        {record.targetReach ? (Number(Number(value / record.targetReach).toFixed(4)) * 100).toFixed(2) : 0}%)
      </span>
    ),
  },
  {
    title: 'click/target',
    dataIndex: 'totalClick',
    key: 'click',
    render: (value, record) => (
      <span>
        {value.toLocaleString()}/{record.targetClick?.toLocaleString()} (
        {record.targetClick ? (Number(Number(value / record.targetClick).toFixed(4)) * 100).toFixed(2) : 0}
        %)
      </span>
    ),
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    key: 'ctr',
    render: (ctr, record) => <span>{isNaN(ctr) ? 0 : ctr.toLocaleString()}%</span>,
  },
];
