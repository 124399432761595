import React from 'react';
import { Select } from 'antd';
import { useStore } from '../../context/AppContext';
import { DEFAULT_DROPDOWN_WIDTH } from '../../config';
export const SelectAdvertiser = ({
  data = [],
  selectedValue,
  disabled = false,
  onChange,
  showAllOption = true,
  allowChange = true,
  controlId,
  width,
}) => {
  const [appState] = useStore();
  const advertiserList = appState.advertisers ?? data;

  return (
    <Select
      id={controlId}
      value={selectedValue}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width: width ?? DEFAULT_DROPDOWN_WIDTH }}
      disabled={!allowChange || disabled}
      placeholder={showAllOption ? 'Filter by Advertiser' : 'Select Advertiser'}
      onChange={onChange}
    >
      {showAllOption && (
        <Select.Option key={0} value={null}>
          All Advertisers
        </Select.Option>
      )}
      {advertiserList.map((adv) => (
        <Select.Option key={adv.id} value={adv.id}>
          {adv.name} ({adv.totalCampaign})
        </Select.Option>
      ))}
    </Select>
  );
};
