import React from 'react';
import { Card, Tag } from 'antd';

export const ShowDataField = ({ text, value }) => (
  <Card style={{ marginBottom: 8 }} bodyStyle={{ padding: 8, textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
    <div>{text}</div>
    {Array.isArray(value) ? (
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        {value.map((v) => (
          <Tag key={v}>{v}</Tag>
        ))}
      </div>
    ) : (
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>{value}</div>
    )}
  </Card>
);
