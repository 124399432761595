import React from 'react';

export const PreviewInterstitial = React.memo(({ optionals, mediaFiles }) => {
  const interstitialLogo = mediaFiles?.find((x) => x.position === 'Logo');
  const interstitialBackground = mediaFiles?.find((x) => x.position === 'Center' && x.assetType === 'Image')?.assetUrl ?? '';

  return (
    <>
      <h6>Preview Ads</h6>
      <div
        name="PreviewAds"
        style={{
          width: 380,
          height: 640,
          top: 10,
          position: 'sticky',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("${interstitialBackground}")`,
        }}
      >
        {optionals.template < 3 && (
          <div
            name="mainTextArea"
            style={{
              position: 'absolute',
              borderRadius: 15,
              bottom: 0,
              left: 0,
              right: 0,
              height: 300,
              padding: 40,
              fontSize: 20,
              backgroundColor: optionals?.backgroundColor ?? 'white',
              textAlign: optionals.template === 2 ? 'left' : 'center',
            }}
          >
            <div style={{ fontWeight: 'bold', color: optionals?.title?.color }}>{optionals?.title?.text}</div>
            <div style={{ color: optionals?.description?.color }}>{optionals?.description?.text}</div>
          </div>
        )}
        {interstitialLogo && optionals.template !== 2 && (
          <div
            name="Logo"
            style={{
              position: 'absolute',
              bottom: 240,
              left: 0,
              right: 0,
              padding: 40,
              fontSize: 15,
              zIndex: 400,
              textAlign: 'center',
            }}
          >
            <img src={interstitialLogo.assetUrl} alt="preview logo" style={{ width: 100, height: 'auto' }} />
          </div>
        )}
        {optionals.template < 3 && (
          <>
            {optionals.template === 1 && (
              <div
                name="Brand"
                style={{
                  position: 'absolute',
                  bottom: 220,
                  left: 0,
                  right: 0,
                  padding: 40,
                  fontSize: 15,
                  zIndex: 200,
                  textAlign: optionals.template === 2 ? 'left' : 'center',
                }}
              >
                <div style={{ fontWeight: 'bold', color: optionals?.brand?.color }}>{optionals?.brand?.text}</div>
              </div>
            )}
            <div
              name="Button"
              style={{
                position: 'absolute',
                zIndex: 300,
                bottom: 40,
                left: 30,
                borderRadius: 20,
                right: 30,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 50,
                paddingRight: 50,
                fontSize: 20,
                fontWeight: 'bolder',
                textAlign: 'center',
                color: optionals?.button?.color,
                backgroundColor: optionals?.button?.backgroundColor,
              }}
            >
              {optionals?.button?.text}
            </div>
          </>
        )}
      </div>
    </>
  );
});
