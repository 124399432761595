import React from 'react';
import { Select } from 'antd';
import { useStore } from '../../../context/AppContext';
import { DEFAULT_DROPDOWN_WIDTH } from '../../../config';

export const SelectCreator = React.memo(({ style, value, onChange, showAllOption = false, allowChange = true }) => {
  const [appState] = useStore();
  const { creators } = appState;

  console.debug('SelectCreator');
  return (
    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width: DEFAULT_DROPDOWN_WIDTH, ...style }}
      disabled={!allowChange}
      placeholder={showAllOption ? 'Filter by Creator' : 'Select Creator'}
      onChange={onChange}
    >
      {showAllOption && (
        <Select.Option key={0} value={null}>
          All Creators
        </Select.Option>
      )}
      {creators.map((creator) => (
        <Select.Option key={creator.id} value={creator.id}>
          {creator.name}
        </Select.Option>
      ))}
    </Select>
  );
});
