import React from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../context/AppContext';
import axios from '../api/axios';

import { CMS_ACTION } from '../context/actionsType';
import { getIdentityApiUrl, AUTH, ApiEndpoint } from '../config';
import { getData } from '../api/helpers';
import { useWhyDidYouUpdate } from '../hooks/useWhyDidYouUpdate';
import { DisplayError } from '../components/DisplayError';

const SignIn = (props) => {
  const [appState, dispatch] = useStore();
  const navigate = useNavigate();

  useWhyDidYouUpdate('SignIn', props);

  const onFinishFailed = (errorInfo) => {
    console.log('onFinishFailed', errorInfo);
  };

  const onFinish = async (formValues) => {
    const { email, password } = formValues;
    const { data: usersData } = await getData(ApiEndpoint.User.List, null);

    dispatch({ type: CMS_ACTION.FETCH_START });
    axios
      .post(getIdentityApiUrl() + '/auth/ookbee', {
        ookbeeId: email,
        password: password,
        reauthenticate: true,
        platform: AUTH.Platform,
        userAgent: AUTH.UserAgent,
        appCode: AUTH.AppCode,
        deviceId: AUTH.DeviceId,
        additionalAppCodes: ['string'],
      })
      .then(({ data: authData }) => {
        if (authData.data) {
          // Check if users is in Ads System
          let userData = usersData.items.find((u) => u.id === authData.data.ookbeeNumericId);
          if (true) {
            console.debug('Found user in Ads System');
            dispatch({
              type: CMS_ACTION.AUTH.SET_AUTH_DATA,
              payload: { ...authData.data, ...userData },
            });
            console.debug('Redirect to homepage');
            navigate('/');
          } else {
            dispatch({
              type: CMS_ACTION.FETCH_ERROR,
              payload: 'User not found in Ads System!',
            });
          }
        } else {
          dispatch({
            type: CMS_ACTION.FETCH_ERROR,
            payload: authData.error,
          });
        }
      })
      .catch(function (error) {
        const _error = error.response?.data?.error?.message ?? error.message;
        dispatch({
          type: CMS_ACTION.FETCH_ERROR,
          payload: _error,
        });
        console.log('Error Login:', error);
      });
  };

  //   useEffect(() => {
  //     if (user.accessToken !== null) {
  //       console.debug("Redirect to homepage");
  //       history.push("/");
  //     }
  //   }, [user.accessToken, history]);

  return (
    <div className="gx-app-login-wrap" style={{ padding: 20 }}>
      <div className="gx-app-login-container" style={{ marginTop: 100 }}>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">{/* <img src="https://via.placeholder.com/272x395" alt="Neature" /> */}</div>
            <div className="gx-app-logo-wid">
              <h1>Sign In</h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="https://buffetgang.ookbee.com/images/ookbee_logo.png" style={{ width: 140, height: 'auto' }} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                <Input placeholder="Email" type="email" />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input.Password style={{ backgroundColor: '#f5f5f5' }} placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Checkbox checked={true}>Accept</Checkbox>
                <span className="gx-signup-form-forgot gx-link">Term and Conditions</span>
              </Form.Item>
              <Form.Item>
                <Button loading={appState.loading} type="primary" className="gx-mb-0" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
              <DisplayError error={appState.error} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

SignIn.whyDidYouRender = true;

export default React.memo(SignIn);
