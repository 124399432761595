export const initialAppState = {
  advertisers: [],
  publishers: [],
  creators: [
    {
      id: '777',
      name: 'Ookbee1 (777)',
    },
    {
      id: '1123',
      name: 'Ookbee2 (1123)',
    },
    {
      id: '123',
      name: 'Test (123)',
    },
    {
      id: '111',
      name: 'Test1 (111)',
    },
    {
      id: '222',
      name: 'Test2 (222)',
    },
    {
      id: '333',
      name: 'Test3 (333)',
    },
    { id: 'c7a9a1c8-65ea-4b4b-99a5-16e34f3c5925', name: 'flashfly.net' },
    { id: '1f6e261b-a3ed-48fe-be01-8de767a05a3a', name: 'ประชาชาติ' },
    { id: '33cde3d6-a551-4416-aa9d-421922ad63ce', name: 'Blognone' },
    { id: 'be5b93b9-393a-4828-b133-3b3e415206b0', name: 'DaraDaily' },
    { id: '904ec165-1b2b-472b-8c26-900c43c74c00', name: 'PostToday' },
    { id: '54c9a80c-dba4-4abf-94ee-8c6c814048d1', name: 'ไทยรัฐ' },
    { id: '8b39d017-b81f-4be2-a765-92fa494b6765', name: 'กรุงเทพธุรกิจ' },
  ],
  pricingModels: ['CPM', 'IMP'],
  groupTypes: [],
  message: '',
  error: null,
  loading: false,
  user: {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    ookbeeNumericId: localStorage.getItem('ookbeeNumericId'),
    roleName: localStorage.getItem('roleName'),
    displayName: localStorage.getItem('displayName'),
    firstName: null,
    lastName: null,
    emailAddress: null,
  },
};
