import rules from '../rules';

// This component takes the rules that you defined and decides whether or not users can perform the desired action or see some part of the UI. If the answer is yes, then the Can component renders the yes prop. Otherwise, the component renders the no prop.
// Ref: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

export const checkRolePermission = (role, action, data) => {
  const permissions = rules[role];
  // console.debug(action, permissions);
  if (!permissions) {
    console.debug('There is no setting for role:', role);
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    // console.debug("have static permission for", action);
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      // console.debug("no dynamic permission for ", action);
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = (props) => (checkRolePermission(props.role, props.perform, props.data) ? props.yes(props) : props.no());

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
