export const lookupAdvertiser = (appState, advertiserId) => {
  if (appState && appState.advertisers) {
    return appState.advertisers.find((a) => a.id === advertiserId) ?? {}; // if not found, return empty object instead of undefined
  }
  return {};
};

export const lookupPricingModel = (appState, pricingModelId) => {
  if (appState && appState.pricingModels) {
    return appState.pricingModels.find((a) => a.id === pricingModelId) ?? {}; // if not found, return empty object instead of undefined
  }
  return {};
};

export const lookupAdGroupType = (appState, adGroupTypeId) => {
  if (appState && appState.groupTypes) {
    return appState.groupTypes.find((a) => a.id === adGroupTypeId) ?? {}; // if not found, return empty object instead of undefined
  }
  return {};
};

export const lookupPublisher = (appState, publisherId) => {
  if (appState && appState.publishers) {
    return appState.publishers.find((a) => a.id === publisherId) ?? {}; // if not found, return empty object instead of undefined
  }
  return {};
};
