import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { ApiEndpoint } from '../../config';
import { postData, putData, getDataById } from '../../api/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';
import { DisplayError } from '../DisplayError';
import { useRouter } from '../../hooks/useRouter';

const { confirm } = Modal;

export const PublisherForm = () => {
  const [error, setError] = useState(null);
  const { navigate, query } = useRouter();
  const [form] = Form.useForm();
  const { publisherId } = query;
  const isEdit = publisherId !== undefined;

  useEffect(() => {
    const loadPublisher = async (publisherId) => {
      const { data } = await getDataById(ApiEndpoint.Publisher.Get, publisherId, setError);
      form.setFieldsValue(data);
    };

    if (publisherId) {
      loadPublisher(publisherId);
    }
  }, [form, publisherId]);

  const onFinish = async (formValues) => {
    if (isEdit) {
      const { data } = await putData(ApiEndpoint.Publisher.Update, publisherId, formValues, setError, 'Update Publisher Successful');
      if (data) {
        console.debug('Update success...');
        navigate(`/setting/publisher`);
      }
    } else {
      // Create New Publisher
      const { data } = await postData(ApiEndpoint.Publisher.Create, formValues, setError, 'Create New Publisher Successful');
      if (data) {
        console.debug('Create Publisher Successful');
        navigate(`/setting/publisher`);
      }
    }
  };

  const onUpdateButtonClick = (formValues) => {
    confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onFinish(formValues);
      },
      onCancel() {},
    });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New Publisher</h3>}
      {isEdit && <h3>Edit Publisher</h3>}
      <DisplayError error={error} />
      <Form {...extendedFormLayout} form={form} name="publisher-from" onFinish={onUpdateButtonClick}>
        <Form.Item
          name="name"
          label="Publisher Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="countryCode"
          label="Country Code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input maxLength={5} />
        </Form.Item>
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit">
            {isEdit ? 'Update' : 'Create Publisher'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
