export const developmentToken =
  '+GcITPot0NTmbTEz0de7wOp9CTid0BjWifWUu5MyzMom8aSI4W5eBHRuQNKpf5flxluqFrfD3sp0JMEFnKU2nw0gm5ddjZNU6xKF7U5nmG8p7zUkeLX4eyEOTgH2E40XJosgGlsa2B/WMJDGZOY4DxbOf+QnYY+1OGcfraT1dnzENE1SN2aXddvc/rsbwN4z1K22gajLLjsTe0DSq+erXZyW0l7qfIYeUzv19WvDjOAN/pR3FQwANdmw8s8Oxi0xCvOB2zam252sKQ/NxymI3kJzLam10mrdDVk7/KiReyLskEMg8yjtm4kfUgsvSDvAk9U1GL9zGiWJzI4RwK8KMRyzr7hPtaCdHuX74Z+NfO7VirPW3VyALQEcs73sL3NqGcXvT5jrIfTLHDquAarkGp2iBahx9ESaBv9RheYcGD892JwF+ENLq4YEZczqjiLClphNJMefVhVfgVaSR7e1X3bt24mrSk18G9vJEjuGNS2MIPGIxGziP5vbxuHI3pu8Dn4rQVtShfWFzwmHVnm7aMGLPrUV2DpZDwQBgMn3cxsgk9CDSwbNMjLk1LfRNyxAhjCxGi61SPHkN88nTqYCcSGVLRd3MFkSZUL/i749dyxQG5hpZFT9/NbAHfX7wFaYgn59qOjVN66dRfJzhcaepaE4o7CYAOGZ4omhx8CMfOIjvBHBSV3++RY78jmUpY65rdnoSS46GhGEW5G6KB22/tp/3oXqMXFy8RzigR/vC9RndIBJmIAyNF7S/RNJnIb1BTMu7yqMNuLsicI3md1sbuG7IbFzQ34jp11VSafTzC+gq/XxlUXNhxcQijC9bzXteG9Inw2EtBuD/bJVpIB/8WeFkPdtC3KOU5MhC4G7zVsj8d62geBbVYjjwtnOJy8h';

// Default API endpoint url
// const API_BASE_URL_DEV = "https://localhost:8121/api";
// const API_BASE_URL_PROD = "https://localhost:8121/api";
// const API_BASE_URL_DEV = "https://manager.dev.ads.ookbee.net/api";
// const API_BASE_URL_PROD = "https://manager.dev.ads.ookbee.net/api";
// export const PROD_SITE_URL = "https://ookbee-ads.netlify.app";
// export const BANNER_API_URL = "https://banner.dev.ads.ookbee.net";
export const IS_PRODUCTION = process.env && process.env.NODE_ENV === 'production' ? true : false;
export const USE_MOCK_DATA = false;
export const DEFAULT_DROPDOWN_WIDTH = 230;
export const AUTH = {
  Platform: 'Website',
  AppCode: 'BEEBER_003',
  DeviceId: 'website/anna-dev.ookbee.net:5634',
  UserAgent: '',
};

export const ApiEndpoint = {
  AdAsset: {
    Create: '/ad-assets',
    List: '/ad-assets?start=0&length=50',
    Delete: '/ad-assets',
    Update: '/ad-assets',
  },
  Advertiser: {
    List: '/advertisers?start=0&length=100',
    Get: '/advertisers',
    Create: '/advertisers',
    Update: '/advertisers',
  },
  Publisher: {
    List: '/publishers?start=0&length=100',
    Get: '/publishers',
    Create: '/publishers',
    Update: '/publishers',
  },
  PricingModel: {
    List: '/pricing-models?start=0&length=25',
    Get: '/pricing-models',
  },
  Campaign: {
    List: '/campaigns?start=0&length=100',
    Get: '/campaigns',
    Update: '/campaigns',
    Create: '/campaigns',
    UpdateImpression: '/campaigns',
    Delete: '/campaigns',
  },
  NotificationEmails: {
    List: '/notification-emails?start=0&length=100',
    Get: '/notification-emails',
    Update: '/notification-emails',
    Create: '/notification-emails',
    Delete: '/notification-emails',
  },
  Ads: {
    List: '/ads?start=0&length=100',
    ListCustomPaging: '/ads?',
    Get: '/ads',
    // Create: "/ads",
    CreateBannerAds: '/banner-ads',
    CreateInterAds: '/interstitial-ads',
    CreateRewardAds: '/rewarded-ads',
    UpdateStatus: '/ads/status',
    Delete: '/ads',
  },
  AdUnit: {
    List: '/ad-units?start=0&length=100',
    Get: '/ad-units',
    Create: '/ad-units',
    Update: '/ad-units',
    Delete: '/ad-units',
  },
  AdNetworkUnit: {
    List: '/ad-networks?start=0&length=100',
    Get: '/ad-networks',
    Create: '/ad-networks',
    Update: '/ad-networks',
    Delete: '/ad-networks',
  },
  AdGroup: {
    List: '/ad-groups?start=0&length=100',
    Get: '/ad-groups',
    Create: '/ad-groups',
    Update: '/ad-groups',
    Delete: '/ad-groups',
  },
  AdGroupItem: {
    List: '/ad-group-items?start=0&length=100',
    Get: '/ad-group-items',
    Create: '/ad-group-items',
    Update: '/ad-group-items',
    Delete: '/ad-group-items',
  },
  AdGroupType: {
    List: '/ad-group-types/?start=0&length=100',
    Get: '/ad-group-types',
    Create: '/ad-group-types',
    Update: '/ad-group-types',
  },
  Report: {
    Publisher: '/report/publisher',
    AdUnitReport: '/report/adunit',
    Ad: '/report/ad',
  },
  User: {
    List: '/users/?start=0&length=100',
    Get: '/users',
    Create: '/users',
    Update: '/users',
    Delete: '/users',
  },
  UserRole: {
    List: '/user-roles/?start=0&length=50',
    Get: '/user-roles',
    Create: '/user-roles',
    Update: '/user-roles',
  },
  UserRoleMapping: {
    List: '/user-role-mappings/?start=0&length=50',
    Get: '/user-role-mappings',
    Create: '/user-role-mappings',
    Update: '/user-role-mappings',
  },
  UserRolePermission: {
    List: '/user-role-permissions/?start=0&length=50',
    Get: '/user-role-permissions',
    Create: '/user-role-permissions',
    Update: '/user-role-permissions',
  },
  Redis: {
    KeysCount: '/redis/keys/count?database=0',
  },
};

export const platformList = ['iOS', 'Android', 'Web', 'Huawei'];
export const adNetworkList = ['AdMob', 'Appodeal', 'Huawei', 'POKKT'];
export const placements = [
  'BETWEEN_CHAPTER',
  'BOTTOM_CHAT',
  'BOTTOM_NOVEL',
  'BOTTOM_COMIC',
  'CHAPTER_LIST',
  'ENTER_CHAPTER',
  'HOME',
  'NEWS',
  'RECOMMEND_INTERSTITIAL',
  'RECOMMEND',
  'REWARDVIDEO',
  'REWARDVIDEO_HOME',
  'REWARDVIDEO_AFTERWHEEL',
  'TAB_CHAT',
  'TOP_CHAT_INTERSTITIAL',
  'TOP_CHAT',
  'UNLOCK_CHAPTER_KEY',
  'UNLOCK_CHAPTER_INTERSTITIAL',
];

export const getUserAudienceManagementApiUrl = () => {
  // console.debug("process.env.NODE_ENV=", process.env.NODE_ENV);
  if (process.env && process.env.REACT_APP_USER_AUDIENCE_MANAGEMENT_API_URL) {
    return process.env.REACT_APP_USER_AUDIENCE_MANAGEMENT_API_URL;
  } else {
    throw Error('No REACT_APP_USER_AUDIENCE_MANAGEMENT_API_URL in environment setting');
  }
};

export const getApiUrl = () => {
  // console.debug("process.env.NODE_ENV=", process.env.NODE_ENV);
  if (process.env && process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  } else {
    throw Error('No API_BASE_URL in environment setting');
  }
};

// export const getAdUnitApiUrl = () => {
//   if (process.env && process.env.REACT_APP_PUBLISH_API_URL) {
//     return process.env.REACT_APP_PUBLISH_API_URL;
//   } else {
//     throw Error("No Publishing API url in environment setting");
//   }
// };

export const getIdentityApiUrl = () => {
  if (process.env && process.env.REACT_APP_IDENTITY_API_URL) {
    return process.env.REACT_APP_IDENTITY_API_URL;
  } else {
    throw Error('No Identity API url in environment setting');
  }
};

export const getMenuBackgrounColor = () => {
  if (process.env && process.env.REACT_APP_MENU_BACKGROUND_COLOR) {
    return process.env.REACT_APP_MENU_BACKGROUND_COLOR;
  } else {
    throw Error('No REACT_APP_MENU_BACKGROUND_COLOR in environment setting');
  }
};

export const getBackgrounColor = () => {
  if (process.env && process.env.REACT_APP_BACKGROUND_COLOR) {
    return process.env.REACT_APP_BACKGROUND_COLOR;
  } else {
    throw Error('No REACT_APP_BACKGROUND_COLOR in environment setting');
  }
};

export const getRedisKeysThreshold = () => {
  if (process.env && process.env.REACT_APP_REDIS_KEYS_THRESHOLD) {
    return process.env.REACT_APP_REDIS_KEYS_THRESHOLD;
  } else {
    throw Error('No REACT_APP_REDIS_KEYS_THRESHOLD in environment setting');
  }
};
