import React from 'react';
import { CartesianGrid, ResponsiveContainer, Tooltip, BarChart, Bar, Legend, XAxis, YAxis } from 'recharts';
import { chartColor } from '../../../config/chartColor';
import moment from 'moment';
import { Spin } from 'antd';

export const DailyChartGroupRequestLog = ({ reportData, chartName = 'Request/Day', isLoading = false }) => {
  if (isLoading)
    return (
      <div
        style={{
          minWidth: 400,
          width: '100%',
          height: 300,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  return (
    <ResponsiveContainer width="100%" style={{ backgroundColor: 'white' }} minWidth={400} height={300}>
      <BarChart
        style={{ backgroundColor: 'white' }}
        width={500}
        height={300}
        data={reportData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="day" tickFormatter={(value) => moment(value).format('YY-MM-DD')} />
        <YAxis />
        <Legend />
        {/* {showReach && (
          <Tooltip
            formatter={(value) => value.toLocaleString()}
            content={(xxx) => {
              const payload = xxx.payload;
              const reach = payload?.find((x) => x.dataKey === "reach");

              return (
                <div
                  className="bg-white rounded border"
                  style={{
                    padding: 6,
                    borderRadius: 4,
                    borderColor: "grey",
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                >
                  <div>{moment(reach?.payload?.day).format("ddd, DD MMM YYYY")}</div>
                  <div>Reach: {reach?.payload?.reach.toLocaleString()}</div>
                  <div>Imp: {reach?.payload?.total.toLocaleString()}</div>
                </div>
              );
            }}
          />
        )}
        {!showReach && <Tooltip formatter={(value) => value.toLocaleString()} />} */}
        <Tooltip formatter={(value) => value.toLocaleString()} />

        <Bar
          dataKey="totalRequest"
          stackId="1"
          name={chartName}
          fill={chartColor.Advertiser}
          animationBegin={0}
          animationDuration={400}
          animationEasing="ease-out"
        />
        <Bar
          dataKey="impOokbee"
          stackId="2"
          name="Imp/Ookbee"
          fill={chartColor.LightGreen}
          animationBegin={0}
          animationDuration={400}
          animationEasing="ease-out"
        />
        <Bar
          dataKey="impAdmob"
          stackId="2"
          name="Imp/Admob"
          fill={chartColor.LightPink}
          animationBegin={0}
          animationDuration={400}
          animationEasing="ease-out"
        />
        <Bar
          dataKey="impAppOdeal"
          stackId="2"
          name="Imp/AppOdeal"
          fill={chartColor.LightBlue}
          animationBegin={0}
          animationDuration={400}
          animationEasing="ease-out"
        />
        <Bar
          dataKey="impHuaweiDay"
          stackId="2"
          name="Imp/Huawei"
          fill={chartColor.DarkPink}
          animationBegin={0}
          animationDuration={400}
          animationEasing="ease-out"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
