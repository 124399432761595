import React from 'react';
import { ResponsiveContainer, Tooltip, BarChart, Bar, Legend, XAxis } from 'recharts';
import { Spin } from 'antd';
import { chartColor } from '../../../config/chartColor';
import moment from 'moment';

const PublisherReport = ({ chartData, isLoading = false }) => {
  console.debug({ chartData });
  if (isLoading)
    return (
      <div
        style={{
          minWidth: 400,
          width: '100%',
          height: 180,
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Impression Stat</h6>
      <ResponsiveContainer width="100%" height={180}>
        <BarChart data={chartData} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
          <Tooltip formatter={(value) => value.toLocaleString()} />
          {/* <CartesianGrid horizontal={false} strokeDasharray="3 3" /> */}
          <XAxis dataKey="day" tickFormatter={(value) => moment(value).format('YY-MM-DD')} />
          <Legend />
          <Bar
            type="monotone"
            dataKey="joylada.th"
            label="Joylada"
            legendType="circle"
            fillOpacity={1}
            stroke={chartColor.DarkPink}
            fill={chartColor.DarkPink}
          />
          <Bar
            type="monotone"
            dataKey="joylada.en"
            label="Joylada"
            legendType="circle"
            fillOpacity={1}
            stroke={chartColor.Advertiser}
            fill={chartColor.Advertiser}
          />
          <Bar
            type="monotone"
            dataKey="joylada.my"
            label="Joylada"
            legendType="circle"
            fillOpacity={1}
            stroke={chartColor.Available}
            fill={chartColor.Available}
          />
          <Bar
            type="monotone"
            dataKey="joylada.id"
            fillOpacity={1}
            legendType="circle"
            stroke={chartColor.LightBlue}
            fill={chartColor.LightBlue}
          />
          <Bar
            type="monotone"
            dataKey="joylada.vn"
            fillOpacity={1}
            legendType="circle"
            stroke={chartColor.LightGreen}
            fill={chartColor.LightGreen}
          />
          <Bar
            type="monotone"
            dataKey="joylada.test"
            fillOpacity={1}
            legendType="circle"
            stroke={chartColor.LightPink}
            fill={chartColor.LightPink}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PublisherReport;
