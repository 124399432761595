import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint, DEFAULT_DROPDOWN_WIDTH } from '../../config';
import { getData } from '../../api/helpers';
// import { keys } from "lodash";

const columns = [
  {
    title: 'Permission Name',
    dataIndex: 'extensionName',
    key: 'extensionName',
    render: (text, record) => <Link to={`/permission/${record.id}`}>{text}</Link>,
  },
  {
    title: 'Role Name',
    dataIndex: 'roleName',
    key: 'roleName',
    render: (text, record) => <Link to={`/role/${record.id}`}>{text}</Link>,
  },
  // {
  //   title: "Create",
  //   dataIndex: "isCreate",
  //   key: "isCreate",
  //   render: (text) =>
  //     text ? (
  //       <CheckCircleFilled style={{ color: "green" }} />
  //     ) : (
  //       <CloseCircleOutlined style={{ color: "red" }} />
  //     ),
  // },
  // {
  //   title: "Read",
  //   dataIndex: "isRead",
  //   key: "isRead",
  //   render: (text) =>
  //     text ? (
  //       <CheckCircleFilled style={{ color: "green" }} />
  //     ) : (
  //       <CloseCircleOutlined style={{ color: "red" }} />
  //     ),
  // },
  // {
  //   title: "Update",
  //   dataIndex: "isUpdate",
  //   key: "isUpdate",
  //   render: (text) =>
  //     text ? (
  //       <CheckCircleFilled style={{ color: "green" }} />
  //     ) : (
  //       <CloseCircleOutlined style={{ color: "red" }} />
  //     ),
  // },
  // {
  //   title: "Delete",
  //   dataIndex: "isDelete",
  //   key: "isDelete",
  //   render: (text) =>
  //     text ? (
  //       <CheckCircleFilled style={{ color: "green" }} />
  //     ) : (
  //       <CloseCircleOutlined style={{ color: "red" }} />
  //     ),
  // },
];

export const RolePermission = () => {
  const [error, setError] = useState(null);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const loadRolePermissionList = async () => {
      const { data } = await getData(ApiEndpoint.UserRolePermission.List, setError);
      setRolePermissions(data.items);
    };

    const loadRoleList = async () => {
      const { data } = await getData(ApiEndpoint.UserRole.List, setError);
      if (data && data.items) {
        setRoles(data.items);
      }
    };

    loadRolePermissionList();
    loadRoleList();
  }, []);

  const onSelectRoleChange = async (roleId) => {
    const { data } = await getData(ApiEndpoint.UserRolePermission.List + '&roleId=' + roleId, setError);
    setRolePermissions(data.items);
  };

  if (error) {
    return (
      <div className="container">
        <Alert type="error">{error}</Alert>
      </div>
    );
  }
  return (
    <div className="container">
      <Row>
        <Col xs={24}>
          <h3>Role Permissions</h3>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={18}>
          <Select placeholder="filter by Role" style={{ width: DEFAULT_DROPDOWN_WIDTH }} onChange={onSelectRoleChange}>
            {roles.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={6}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to="/permission/create">
              <PlusOutlined style={{ marginRight: 5 }} />
              New Role Permission
            </Link>
          </div>
        </Col>
      </Row>
      <Table rowKey="id" columns={columns} dataSource={rolePermissions} pagination={false} />
    </div>
  );
};
