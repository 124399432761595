import React from 'react';
import { Form } from 'antd';
import { AdGroupType, AdPosition } from '../../config/ad';

export const UploadLogoImage = React.memo(({ adGroupType, template, mediaFiles, setFieldValue }) => {
  if (template !== 1) return null;
  if (!Array.isArray(mediaFiles)) return null;
  if (mediaFiles.findIndex((media) => media.position.toLowerCase() === AdPosition.Logo.toLowerCase()) >= 0) return null;

  return (
    (adGroupType === AdGroupType.inter || adGroupType === AdGroupType.reward) && (
      <Form.Item label="Logo Image">
        <input
          id={AdPosition.Logo}
          name={AdPosition.Logo}
          type="file"
          accept="image/*"
          onChange={(event) => {
            setFieldValue(AdPosition.Logo, event.currentTarget.files[0]);
          }}
        />
      </Form.Item>
    )
  );
});
