import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const Widget = ({ title, titleBold, children, styleName, cover, extra, actions }) => {
  return (
    <Card
      // TODO: headStyle={{ fontWeight: titleBold ? "bolder" : "bolnod" }}
      title={title}
      actions={actions}
      cover={cover}
      className={`gx-card-widget ${styleName}`}
      extra={extra}
    >
      {children}
    </Card>
  );
};

export default Widget;
Widget.defaultProps = {
  styleName: '',
};

Widget.propTypes = {
  title: PropTypes.node,
  titleBold: PropTypes.bool,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
};
