import { message } from 'antd';

export function combineClickImpression(impressionData, clickData = [], keyName = 'contentId') {
  if (!Array.isArray(impressionData)) {
    message.error('Impression data is invalid!');
    return [];
  }

  const result = [];

  for (let i = 0; i < impressionData.length; i++) {
    const _imp = impressionData[i];
    const _clickData = clickData.find((click) => click[keyName] === _imp[keyName]);
    const totalClick = _clickData ? _clickData.total : 0;

    result.push({
      [keyName]: _imp[keyName],
      impression: _imp?.total,
      click: totalClick,
    });
  }

  return result;
}
