import React from 'react';
import { Alert } from 'antd';

export const DisplayError = React.memo(({ error }) => {
  if (!error) return null;
  if (typeof error === 'object' && !Array.isArray(error) && !error.message) return null; // error may be just empty object
  if (typeof error === 'string') return <Alert type="error" message={`Error: ${error}`}></Alert>;
  if (typeof error === 'object' && error.message) return <Alert type="error" message={`Error: ${error.message}`}></Alert>;
  if (Array.isArray(error)) {
    const result = error.map((err, index) => <Alert key={index} type="error" message={`Error: ${err}`}></Alert>);
    return result;
  }

  // There's error in other types
  return <Alert type="error" message="Something wrong!!"></Alert>;
});
