import React, { useState, useEffect, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { Row, Col, Table, Card, Select, Button, message } from 'antd';
import { getData, postData, getDataById, transformToValue } from '../../api/helpers';
import { SelectAdvertiser } from '../Forms/SelectAdvertiser';
import { PlatformDistributionChart } from '../Dashboard/CRM/TaskByStatus';
import { ApiEndpoint, IS_PRODUCTION } from '../../config';
import { campaignSummaryTableColumns } from './tableColumns/campaignSummaryTableColumns';
import { getTotalImpressionAndClick } from './helpers/getTotalImpressionAndClick';
import { DailyChart } from './components/DailyChart';
import { generateDayChartData } from './helpers/generateDayChartData';
import { ReloadOutlined } from '@ant-design/icons';
import ReactExport from 'react-data-export';
import moment from 'moment';
import Widget from '../Widget';
import { DateRangePicker } from './components/DateRangePicker';
import { ShowDataField } from './components/ShowDataField';
import { useRouter } from '../../hooks/useRouter';
import { isEmpty, sumBy } from 'lodash';

function toNumberOrNull(value) {
  if (!value) return null;
  if (!isNaN) return null;
  return Number(value);
}

function toNumberOrAsString(value) {
  if (value === null || value === undefined) return null;
  if (value === '') return null;
  if (!isNaN(value)) return Number(value);
  return value;
}

export const initialVideoViewSummary = {
  impressions: null,
  averageWatchTime: null,
  percentageViewed: null,
  totalViewTimeMinutes: null,
  over25Percent: null,
  over50Percent: null,
  over75Percent: null,
  completions: null,
  viewed: null,
  views: null,
};

export const CampaignReport = () => {
  const { query, location, navigate } = useRouter();
  const [filter, setFilter] = useState({
    advertiserId: toNumberOrNull(query.advertiserId),
    campaignId: toNumberOrNull(query.campaignId),
    adId: toNumberOrAsString(query.adId),
    periods: [query.start, query.end],
    reload: 0,
  });
  const [summaryData, setSummaryData] = useState({
    campaign: null,
    adId: null,
    ad: null,
    totalImpression: 0,
    quota: 0,
    totalClick: 0,
    targetClick: 0,
    totalReach: 0,
    targetReach: 0,
    ctr: 0,
  });
  const [selectedAdInfo, setSelectedAdInfo] = useState({
    campaignName: null,
    adName: null,
  });
  const [videoViewSummary, setVideoViewSummary] = useState(initialVideoViewSummary);
  const [dailyChartData, setDailyChartData] = useState([]);
  const [platformDistributionData, setPlatformDistributionData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [isLoadingCampaignSummaryTable, setIsLoadingCampaignSummaryTable] = useState(false);
  const [isLoadingPlatformData, setIsLoadingPlatformData] = useState(false);
  const [ads, setAds] = useState([]);

  // const query = useQuery();
  // const history = useHistory();

  const SELECT_WIDTH = '100%'; // DEFAULT DROPDOWN WIDTH

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const loadCampaignByAdvertiser = useCallback(async (advertiserId) => {
    const data = await getData(ApiEndpoint.Campaign.List + '&advertiserId=' + advertiserId, null);
    if (data && data.data) {
      setCampaigns(data.data.items);
      return data.data.items;
    }
  }, []);

  const loadAdsByCampaign = useCallback(async (campaignId) => {
    const data = await getData(ApiEndpoint.Ads.List + '&campaignId=' + campaignId, null);
    if (data && data.data) {
      setAds(data.data.items);
      return data.data.items;
    }
  }, []);

  const loadAdReportByAd = useCallback(async (adId, periods) => {
    setIsLoadingChartData(true);
    const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');

    const selectedAd = await loadAdInfo(adId);

    const { data: impressionData } = await getData('/report/ad/' + adId + '?start=' + periods[0] + '&end=' + endPeriods);
    const { data: clickData } = await getData(`/report/ad/${adId}/click?start=${periods[0]}&end=${endPeriods}`);
    const { data: reachData } = await getData(`/report/ad/${adId}/reach?start=${periods[0]}&end=${endPeriods}`);

    let videoSummaryData = [];
    if (selectedAd && selectedAd.format?.media === 'video') {
      console.debug('this is video ads');
      const { data: videoSummaryData } = await getData(`/report/ads/${adId}/video-summary?start=${periods[0]}&end=${endPeriods}`);
      console.debug(videoSummaryData);

      const averageWatchTime = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.averageWatchTime ?? 0);
      }, 0);

      const totalViewTime = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.viewed ?? 0);
      }, 0);

      const percentageViewed = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.averagePercentageViews ?? 0);
      }, 0);

      const over25Percent = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.over25Percent ?? 0);
      }, 0);
      const over50Percent = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.over50Percent ?? 0);
      }, 0);
      const over75Percent = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.over75Percent ?? 0);
      }, 0);

      const views = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.views ?? 0);
      }, 0);

      const impressions = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.impressions ?? 0);
      }, 0);

      const completions = videoSummaryData.items.reduce((acc, cur) => {
        return acc + (cur.completions ?? 0);
      }, 0);

      console.debug(averageWatchTime);
      console.debug('totalViewTime', totalViewTime);
      console.debug('percentageViewed', percentageViewed);
      console.debug('views', views);
      console.debug('impressions', impressions);
      console.debug('completions', completions);

      setVideoViewSummary({
        averageWatchTime: videoSummaryData.items.length === 0 ? 0 : averageWatchTime / videoSummaryData.items.length / 1000,
        totalViewTimeMinutes: totalViewTime / 60000,
        percentageViewed: videoSummaryData.items.length === 0 ? 0 : percentageViewed / videoSummaryData.items.length,
        over25Percent: over25Percent,
        over50Percent: over50Percent,
        over75Percent: over75Percent,
        impressions: impressions,
        completions: completions,
        views: views,
      });
    } else {
      setVideoViewSummary(initialVideoViewSummary);
    }

    const chartData = generateDayChartData(periods, impressionData.items, reachData.items, clickData.items, videoSummaryData.items);

    if (selectedAd) {
      console.debug(selectedAd);
      // ใช้เวลา Export To Excel Sheet
      setSelectedAdInfo({
        adName: selectedAd.name,
        campaignName: selectedAd.campaign.name,
        target: selectedAd.target,
      });
    }

    if (impressionData.items) {
      setDailyChartData(chartData);
      setSummaryData({
        ...summaryData,
        ...getTotalImpressionAndClick(impressionData.items, clickData.items, reachData.items),
        adId: selectedAd.id,
        ad: selectedAd.name,
        campaign: selectedAd.campaign.name,
        quota: selectedAd.quota,
        targetClick: selectedAd.target?.click ?? 0,
        targetReach: selectedAd.target?.reach ?? 0,
      });
    }

    setIsLoadingChartData(false);
    setIsLoadingCampaignSummaryTable(false);
  }, []);

  const loadAdPlatformReport = useCallback(async (adId, periods) => {
    setPlatformDistributionData([]);
    setIsLoadingPlatformData(true);
    const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');
    const { data } = await getData(`/report/ad/${adId}/platform?start=${periods[0]}&end=${endPeriods}`);
    if (data && data.items) {
      const items = transformToValue(data.items, 'total');
      setPlatformDistributionData(items);
    }
    setIsLoadingPlatformData(false);
  }, []);

  const loadAdPlatformReportByCampaign = useCallback(async (campaignId, periods) => {
    setPlatformDistributionData([]);
    setIsLoadingPlatformData(true);
    const { data } = await getData('/report/campaign/' + campaignId + '/platform' + '?start=' + periods[0] + '&end=' + periods[1]);
    if (data && data.items) {
      const items = transformToValue(data.items, 'total');
      setPlatformDistributionData(items);
    }
    setIsLoadingPlatformData(false);
  }, []);

  const loadAdInfo = useCallback(async (adId) => {
    const { data } = await getDataById(ApiEndpoint.Ads.Get, adId, null);
    return data;
  }, []);

  const loadAdReportByCampaign = useCallback(async (campaignId, periods, filterAds) => {
    setIsLoadingChartData(true);
    setIsLoadingCampaignSummaryTable(true);

    const { data: chartSummaryData } = await getData('/report/campaign/' + campaignId + '?start=' + periods[0] + '&end=' + periods[1]);
    if (chartSummaryData && chartSummaryData.items) {
      const dailyChartData = generateDayChartData(periods, chartSummaryData.items);
      setDailyChartData(dailyChartData);
      // Reset video view
      setVideoViewSummary(initialVideoViewSummary);
      setSummaryData({
        ...summaryData,
        ...getTotalImpressionAndClick(dailyChartData.items),
      });
    }
    setIsLoadingChartData(false);

    // Get Currently Active Ads (not yet finished)
    let isActiveQuery = '';
    if (filterAds === 'active') {
      isActiveQuery = '&isActive=true';
    }
    const { data: adList } = await getData(`${ApiEndpoint.Ads.List}&campaignId=${campaignId}${isActiveQuery}`, null);

    if (adList && adList.items) {
      const adReportData = [];
      for await (const ad of adList.items) {
        const targetClick = ad.target?.click ?? 0;
        const targetReach = ad.target?.reach ?? 0;
        const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');

        // Load Ad Stat By Each Unit
        const { data: adImpressionData } = await getDataById(ApiEndpoint.Report.Ad, ad.id, null, `?start=${periods[0]}&end=${endPeriods}`);
        const { data: adClickData } = await getDataById(ApiEndpoint.Report.Ad, ad.id, null, `/click?start=${periods[0]}&end=${endPeriods}`);

        const { data: adReachData } = await getDataById(ApiEndpoint.Report.Ad, ad.id, null, `/reach?start=${periods[0]}&end=${endPeriods}`);

        const imp = sumBy(adImpressionData.items, function (day) {
          return day.total;
        });
        const click = sumBy(adClickData.items, function (day) {
          return day.total;
        });
        const reach = sumBy(adReachData.items, function (day) {
          return day.total;
        });

        const summaryData = {
          campaign: ad.campaign.name,
          adId: ad.id,
          ad: ad.name,
          quota: ad.quota,
          totalImpression: imp,
          totalClick: click,
          totalReach: reach,
          targetClick: targetClick,
          targetReach: targetReach,
          ctr: (click / imp) * 100,
        };
        adReportData.push(summaryData);
      }
      setSummaryData(adReportData);
      setIsLoadingCampaignSummaryTable(false);
    }
  }, []);

  const { advertiserId, campaignId, adId, start, end, token } = query;
  const isAgency = !!token;

  useEffect(() => {
    // Check token with server
    if (token) {
      postData('/tokens/validate', { token: token }, null, '-').then((result) => {
        console.debug(result.data);
        const isValidToken = result.data?.data?.isValid;
        if (!isValidToken) {
          message.error('Agency token is invalid or expired!');
        }
        if (isValidToken) {
          // parse jwt data
          const { obj } = jwt_decode(token);
          const parsedData = JSON.parse(obj);
          console.debug(obj);
          console.debug(parsedData);

          setFilter({
            advertiserId: parsedData.advertiserId,
            campaignId: parsedData.campaignId,
            adId: parsedData.adId || 'all',
            periods: [parsedData.startAt, parsedData.endAt],
          });

          if (!parsedData.adId) {
            console.debug('JWT data has no AdId, set selectedAdInfo to All Ads...');
            loadCampaignByAdvertiser(parsedData.advertiserId).then((campaignsResult) => {
              console.debug(campaignsResult);
              setSelectedAdInfo({
                campaignName: campaignsResult.find((c) => c.id === parsedData.campaignId).name,
                adName: 'All Ads',
              });
            });
          }
        }
      });
    }
  }, [token, loadCampaignByAdvertiser]);

  useEffect(() => {
    const getCampaignsByAdvertiser = async (advertiserId) => {
      const data = await getData(ApiEndpoint.Campaign.List + '&advertiserId=' + advertiserId, null);
      return data.data.items;
    };

    console.debug('useEffect() query params changed');
    // มีการคลิ้กลิงค์ Report มาจากหน้า Ads List โดยจะมี query string ของ advertiserId, campaignId และ adId ส่งมาให้เลย
    if (advertiserId && campaignId && adId && ads.length === 0) {
      getCampaignsByAdvertiser(advertiserId).then((campaigns) => {
        setCampaigns(campaigns);
        loadAdsByCampaign(campaignId);
      });
    }
  }, [adId, advertiserId, campaignId, ads.length, loadAdsByCampaign]);

  useEffect(() => {
    if (filter.advertiserId && !isAgency) {
      if (filter.advertiserId != advertiserId) {
        console.debug('filter.advertiserId != query.advertiserId', filter.advertiserId, advertiserId);
        // ถ้ามีการเปลี่ยน advertiserId ให้เคลียร์ query string อื่นๆออกเลย
        // const searchParams = new URLSearchParams(window.location.search);
        const searchParams = new URLSearchParams();
        searchParams.set('advertiserId', filter.advertiserId);
        console.debug(searchParams.toString());
        navigate({
          // pathname: '/client',
          search: '?' + searchParams.toString(),
        });

        loadCampaignByAdvertiser(filter.advertiserId);
        setFilter({
          advertiserId: filter.advertiserId,
          campaignId: null,
          adId: null,
          periods: filter.periods,
          reload: 0,
        });
        setAds([]);
      }
    }
  }, [navigate, filter.advertiserId, isAgency, loadCampaignByAdvertiser]);

  useEffect(() => {
    if (filter.campaignId && !isAgency) {
      if (filter.campaignId != campaignId) {
        console.debug('reset selectedAdInfo');
        setSelectedAdInfo({ adName: null, campaignName: null });

        console.debug('filter.campaignId != query.campaignId', filter.campaignId, campaignId);
        // ถ้ามีการเปลี่ยน campaignId ให้เคลียร์ query string ของ adId ออก
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('campaignId', filter.campaignId);
        searchParams.delete('adId');
        navigate({
          // pathname: '/client',
          search: '?' + searchParams.toString(),
        });

        loadAdsByCampaign(filter.campaignId);
        let _adId = campaignId && adId && filter.campaignId === Number(campaignId) ? toNumberOrAsString(adId) : null;
        // console.debug(_adId);
        setFilter({ ...filter, adId: _adId });
        console.debug('Set selectedAdInfo to All Ads...');
        setSelectedAdInfo({
          campaignName: campaigns.find((c) => c.id === filter.campaignId).name,
          adName: 'All Ads',
        });
      }
    }
  }, [navigate, filter.campaignId, isAgency, loadAdsByCampaign]);

  useEffect(() => {
    if (filter.periods[0] && filter.periods[0] !== start) {
      console.debug('useEffect()... periods startAt changed, triggering query string changing');
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('start', filter.periods[0]);
      navigate({
        search: '?' + searchParams.toString(),
      });
    }

    if (filter.periods[1] && filter.periods[1] !== end) {
      console.debug('useEffect()... periods endAt changed, triggering query string changing');
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('end', filter.periods[1]);
      navigate({
        search: '?' + searchParams.toString(),
      });
    }
  }, [filter.periods, start, end, navigate]);

  useEffect(() => {
    if (filter.adId != adId) {
      // ถ้ามีการเปลี่ยน adId
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('adId', filter.adId);
      navigate({
        search: '?' + searchParams.toString(),
      });

      // ถ้าไม่ได้เลือก periods เอาไว้ ให้ auto fill periods จาก startAt กับ endAt ของ Ad ตัวนั้นอัตโนมัติ
      if (filter.adId !== 'all' && !filter.periods[0] && !filter.periods[1]) {
        console.debug('Auto fill periods from Ad Start and EndAt.');
        loadAdsByCampaign(filter.campaignId).then((_ads) => {
          let _ad = _ads.find((a) => a.id === Number(filter.adId));
          console.debug(_ad);
          let _startAt = _ad ? moment(_ad.startAt).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ') : null;
          let _endAt = _ad ? moment(_ad.endAt).set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ') : null;
          setFilter({
            adId: filter.adId,
            campaignId: filter.campaignId,
            advertiserId: filter.advertiserId,
            periods: [_startAt, _endAt],
          });
          setSelectedAdInfo({
            adName: _ad.name,
            campaignName: _ad.campaign.name,
            target: _ad.target,
          });
        });
      }
    }

    // ถ้ามีการเลือก filter ครบทุกอััน โดยไม่ใช่ all ads in campaign
    if (filter.adId && filter.adId !== 'all' && filter.adId !== 'active' && !isNaN(filter.adId) && filter.periods[0] && filter.periods[1]) {
      console.debug('มีการเลือก filter ครบทุกอััน โดยไม่ใช่ all ads in campaign');

      loadAdReportByAd(filter.adId, filter.periods);
      loadAdPlatformReport(filter.adId, filter.periods);
    }

    // ถ้ามีการเลือก filter ครบทุกอััน แต่เป็น all ads in campaign
    if (
      filter.campaignId &&
      filter.adId !== null &&
      (filter.adId === 'all' || filter.adId === 'active') &&
      filter.periods[0] &&
      filter.periods[1]
    ) {
      loadAdReportByCampaign(filter.campaignId, filter.periods, filter.adId);
      loadAdPlatformReportByCampaign(filter.campaignId, filter.periods);
    }
  }, [
    // filter.campaignId,
    navigate,
    adId,
    filter.advertiserId,
    filter.campaignId,
    filter.adId,
    filter.reload,
    filter.periods,
    loadAdsByCampaign,
    loadAdReportByAd,
    loadAdReportByCampaign,
    loadAdPlatformReport,
    loadAdPlatformReportByCampaign,
  ]);

  if (location?.pathname.toLowerCase().indexOf('agency') >= 0 && !token) {
    return (
      <div className="container">
        <h1>No Token specify</h1>
      </div>
    );
  }

  console.debug(selectedAdInfo);

  return (
    <div className="container">
      <Row style={{ marginBottom: 8 }}>
        <Col xs={24} lg={24}>
          <h2>Campaign/Ad Report</h2>
          {selectedAdInfo && (
            <h2 className="h4">
              {selectedAdInfo.campaignName} - {selectedAdInfo.adName}
            </h2>
          )}
          {!IS_PRODUCTION && <pre>{JSON.stringify(filter, null, 2)}</pre>}

          <Row gutter={24}>
            <Col xs={24} md={14} lg={16}>
              <Card hoverable className="gx-card-widget" title="Filter" type="inner">
                {!isAgency && (
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={8}>
                      <SelectAdvertiser
                        width="100%"
                        disabled={isLoadingPlatformData || isLoadingChartData}
                        showAllOption={false}
                        selectedValue={filter.advertiserId}
                        onChange={(value) => setFilter({ ...filter, advertiserId: value })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Select
                        style={{ width: SELECT_WIDTH }}
                        disabled={isLoadingPlatformData || isLoadingChartData}
                        dropdownMatchSelectWidth={false}
                        placeholder="Select campaign..."
                        value={filter.campaignId}
                        onChange={(value) => setFilter({ ...filter, campaignId: value, adId: null })}
                      >
                        {campaigns.map((campaign) => (
                          <Select.Option key={campaign.id} value={campaign.id}>
                            {campaign.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Select
                        style={{ width: SELECT_WIDTH }}
                        dropdownMatchSelectWidth={false}
                        placeholder="Select Ad..."
                        value={filter.adId}
                        disabled={isLoadingChartData || isLoadingPlatformData}
                        onChange={(value) => setFilter({ ...filter, adId: value })}
                      >
                        <Select.Option value="all">All Ads in Campaign</Select.Option>
                        {/* <Select.Option value="active">Only Active Ads in Campaign</Select.Option> */}
                        {ads.map((ad) => (
                          <Select.Option key={ad.id} value={ad.id}>
                            {ad.name} {!isEmpty(ad.target?.audience) && 'x'}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col xs={22}>
                    <DateRangePicker
                      filter={filter}
                      setFilter={setFilter}
                      dateValueFormat="YYYY-MM-DDT00:00:00Z"
                      isLoadingChartData={isLoadingChartData}
                    />
                  </Col>
                  <Col xs={2}>
                    <Button
                      type="default"
                      disabled={isLoadingChartData || isLoadingPlatformData}
                      style={{ width: '100%' }}
                      onClick={() =>
                        setFilter({
                          ...filter,
                          reload: !isNaN(filter.reload) ? filter.reload + 1 : 0,
                        })
                      }
                    >
                      <ReloadOutlined spin={isLoadingPlatformData || isLoadingPlatformData} />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={10} lg={8}>
              <PlatformDistributionChart
                title="Platform distribution"
                isLoading={isLoadingPlatformData}
                height={140}
                innerRadius={20}
                outerRadius={60}
                data={platformDistributionData}
              />
            </Col>
          </Row>
          {!isEmpty(selectedAdInfo.target?.audience) && (
            <Widget title="Target Audience" titleBold styleName="gx-text-center">
              <Row gutter={8}>
                {selectedAdInfo.target?.audience.gender && (
                  <Col xs={24} sm={12} md={12}>
                    <ShowDataField text="Gender" value={selectedAdInfo.target?.audience.gender} />
                  </Col>
                )}
                {selectedAdInfo.target?.audience.age && (
                  <Col xs={24} sm={12} md={12}>
                    <ShowDataField
                      text="Age"
                      value={`${selectedAdInfo.target?.audience.age.min} - ${selectedAdInfo.target?.audience.age.max}`}
                    />
                  </Col>
                )}
                {selectedAdInfo.target?.audience.regions && (
                  <Col xs={24} sm={12} md={12}>
                    <ShowDataField text="Regions" value={selectedAdInfo.target?.audience.regions} />
                  </Col>
                )}
                {selectedAdInfo.target?.audience.mobileBrands && (
                  <Col xs={24} sm={12} md={12}>
                    <ShowDataField text="Mobile Brands" value={selectedAdInfo.target?.audience.mobileBrands} />
                  </Col>
                )}
                {selectedAdInfo.target?.audience.mobileNetworks && (
                  <Col xs={24} sm={12} md={12}>
                    <ShowDataField text="Mobile Network" value={selectedAdInfo.target?.audience.mobileNetworks} />
                  </Col>
                )}
              </Row>
            </Widget>
          )}
          {videoViewSummary.views !== null && (
            <Widget title="Video View Summary" titleBold styleName="gx-text-center">
              <Row gutter={8}>
                {/* 
                <Col xs={12} md={6}>
                  Total View Time (minutes): {Number(videoViewSummary.totalViewTimeMinutes.toFixed(0)).toLocaleString()} minutes{" "}
                </Col> */}

                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Video Impressions" value={videoViewSummary.impressions?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Video Views" value={videoViewSummary.views?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Avg Percent View" value={videoViewSummary.percentageViewed?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField
                    text="Avg Watch Time (sec)"
                    value={Number(videoViewSummary.averageWatchTime?.toFixed(0)).toLocaleString()}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Over 25 Percent Views" value={videoViewSummary.over25Percent?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Over 50 Percent Views" value={videoViewSummary.over50Percent?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Over 75 Percent Views" value={videoViewSummary.over75Percent?.toLocaleString()} />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <ShowDataField text="Completions views" value={videoViewSummary.completions?.toLocaleString()} />
                </Col>
              </Row>
              {!IS_PRODUCTION && <pre>{JSON.stringify(videoViewSummary, null, 2)}</pre>}
            </Widget>
          )}
          <DailyChart
            showReach={filter.adId === 'all' || filter.adId === 'active' ? false : true}
            reportData={dailyChartData}
            chartName="Impression/Day"
            isLoading={isLoadingChartData}
          />
          {filter.adId && filter.periods[0] && filter.periods[1] && (
            <ExcelFile
              filename={
                filter.adId === 'all' || filter.adId === 'active'
                  ? selectedAdInfo.campaignName + ' Campaign Report'
                  : ads.find((ad) => ad.id === filter.adId)?.name + ' Ad Report'
              }
              element={
                <Button type="primary" style={{ marginTop: 8 }}>
                  Export to Excel
                </Button>
              }
            >
              <ExcelSheet
                data={dailyChartData}
                name={
                  filter.adId === 'all' || filter.adId === 'active'
                    ? selectedAdInfo?.campaignName?.replace('&', ' ')
                    : ads.find((ad) => ad.id === filter.adId)?.name.replace('&', ' ')
                }
              >
                <ExcelColumn label="Day" value="day" width={{ wpx: 50 }} style={{ width: 150 }} />
                <ExcelColumn label="Impressions" value="total" width={150} />
                <ExcelColumn label="Click" value="click" width={150} />
              </ExcelSheet>
            </ExcelFile>
          )}
          <Table
            style={{ marginTop: 20 }}
            loading={isLoadingCampaignSummaryTable}
            dataSource={Array.isArray(summaryData) ? summaryData : [summaryData]}
            rowKey="adId"
            columns={campaignSummaryTableColumns}
            pagination={false}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col flex>
          <Button type="primary" onClick={() => setShowChart(true)}>
            <BarChartOutlined /> View Chart
          </Button>
          <Button type="primary">
            <ExportOutlined />
            Export to Excel
          </Button>
        </Col>
      </Row> */}

      {!IS_PRODUCTION && <pre>{JSON.stringify(summaryData, null, 2)}</pre>}
    </div>
  );
};
