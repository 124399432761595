import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { IS_PRODUCTION } from '../../config';

export const publisherTableColumns = ({ onDelete }) => [
  {
    title: 'Publisher Id',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Country Code',
    dataIndex: 'countryCode',
    key: 'countryCode',
    sorter: (a, b) => a.countryCode.length - b.countryCode.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description) => description,
  },

  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <div>
        <Link to={`/setting/publisher/${record.id}`} className="ant-btn ant-btn-primary ant-btn-circle">
          <EditOutlined />
        </Link>
        <Popconfirm
          placement="top"
          title="Confirm Delete Publisher?"
          okButtonProps={{ size: 'middle' }}
          cancelButtonProps={{ size: 'middle' }}
          onConfirm={() => onDelete(record.id)}
          okText="Confirm Delete"
          cancelText="No"
        >
          <Button disabled={!IS_PRODUCTION} type="ghost" shape="circle" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    ),
  },
];
