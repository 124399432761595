import { CMS_ACTION } from '../actionsType';

export const fetchStart = () => {
  return {
    type: CMS_ACTION.FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: CMS_ACTION.FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: CMS_ACTION.FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: CMS_ACTION.SET_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: CMS_ACTION.CLEAR_MESSAGE,
  };
};

export const clearError = () => {
  return {
    type: CMS_ACTION.CLEAR_ERROR,
  };
};
