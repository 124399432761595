import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col, Select, Button, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getData, deleteData, putData } from '../../api/helpers';
import { ApiEndpoint, DEFAULT_DROPDOWN_WIDTH } from '../../config';
import { adListTableColumns } from './AdListTableColumn';
import { SelectPublisher } from '../Forms/SelectPublisher';
import { SelectAdvertiser } from '../Forms/SelectAdvertiser';
import moment from 'moment';

export const PAGESIZE = 100;

export const AdsList = () => {
  const [adList, setAdList] = useState([]);
  const [filteredAdList, setFilteredAdList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(2);
  // const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [filter, setFilter] = useState(
    sessionStorage.getItem('filter')
      ? JSON.parse(sessionStorage.getItem('filter'))
      : {
          campaignId: null,
          advertiserId: null,
          publisherId: null,
          adUnitId: null,
          isActive: null,
        },
  );
  const [campaignList, setCampaignList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadCampaignList = async () => {
      const { data } = await getData(`${ApiEndpoint.Campaign.List}`, setError);
      if (data && data.items && Array.isArray(data.items)) {
        setCampaignList(data.items.sort((a, b) => (a.advertiser.name < b.advertiser.name ? -1 : 1)));
      }
      // setIsLoading(false);
    };

    // const loadAllAdsList = async () => {
    //   const { data } = await getData(`${ApiEndpoint.Ads.List}`, setError);
    //   if (data && data.items && Array.isArray(data.items)) {
    //     console.debug(data.items);
    //     setAdList(data.items);
    //     setFilteredAdList(data.items);
    //   }
    //   setIsLoading(false);
    // };

    loadCampaignList();
  }, []);

  useEffect(() => {
    console.debug(filter);
    // store filter setting into session storage (for history navigation)
    sessionStorage.setItem('filter', JSON.stringify(filter));
    loadAdsList(filter);
    setPage(2);
  }, [filter]);

  const loadAdsList = async (filter) => {
    console.debug('loadAdList...', page);
    setIsLoading(true);
    let queryParams = '';
    Object.entries(filter).forEach((element) => {
      if (element[1] !== null) {
        queryParams += `&${element[0]}=${element[1]}`;
      }
    });

    const { data } = await getData(`${ApiEndpoint.Ads.ListCustomPaging}start=0&length=${PAGESIZE}${queryParams}`, setError);
    let result = [];
    if (data && data.items && Array.isArray(data.items)) {
      setAdList(data.items);

      if (filter.isActive === true) {
        console.debug('show only active ads');
        // setFilteredAdList(data.items);
        result = data.items.filter((ad) => moment().diff(moment(ad.endAt)) < 0);
      } else {
        result = data.items;
      }
      console.debug(data.items);
      if (data.items.length < PAGESIZE) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setFilteredAdList(result);
      setIsLoading(false);
      return result;
    }
  };

  const loadMoreAdsList = async (filter) => {
    console.debug('loadMoreAdsList...');
    let queryParams = '';
    Object.entries(filter).forEach((element) => {
      if (element[1] !== null) {
        queryParams += `&${element[0]}=${element[1]}`;
      }
    });
    const start = (page - 1) * PAGESIZE; // 0
    const pageQuery = `start=${start}&length=${PAGESIZE}`;
    const { data } = await getData(`${ApiEndpoint.Ads.ListCustomPaging}${pageQuery}${queryParams}`, setError);
    let result = [];
    if (data && data.items && Array.isArray(data.items)) {
      setAdList(data.items);

      if (filter.isActive === true) {
        console.debug('show only active ads');
        result = data.items.filter((ad) => moment().diff(moment(ad.endAt)) < 0);
      } else {
        result = data.items;
      }
      return result;
    }
  };

  const onCampaignChange = async (campaignId) => {
    setFilter({
      campaignId: campaignId,
      advertiserId: filter.advertiserId,
      adUnitId: filter.adUnitId,
      publisherId: filter.publisherId,
      isActive: filter.isActive,
    });
  };

  const onPublisherChange = async (publisherId) => {
    setFilter({
      campaignId: filter.campaignId,
      advertiserId: filter.advertiserId,
      adUnitId: filter.adUnitId,
      publisherId: publisherId,
      isActive: filter.isActive,
    });
  };

  const onDelete = async (id) => {
    deleteData(ApiEndpoint.Ads.Delete, id, null);
    // remove item from AdList state
    setAdList(adList.filter((item) => item.id !== id));
  };

  const fetchMoreData = async () => {
    console.debug('fetchMoreData');
    const moreData = await loadMoreAdsList(filter);
    setFilteredAdList([...filteredAdList, ...moreData]);

    if (moreData.length < PAGESIZE) {
      console.debug('no more data');
      setHasMore(false);
    }
    setPage(page + 1);
  };

  console.debug({
    isLoading,
    page,
    hasMore,
    length: filteredAdList.length,
  });

  const onUpdateAdStatus = async (id, status) => {
    const { data, error } = await putData(ApiEndpoint.Ads.UpdateStatus, id, { status: status }, null, 'Update Ad Status Successful');
    if (data && !error) {
      const updatedIndex = adList.findIndex((item) => item.id === id);
      adList[updatedIndex].status = status;
      setAdList([...adList]);
    }
  };

  return (
    <div className="container">
      <h3>Manage Ads</h3>
      {error && <Alert type="error" message={error} />}
      <Row style={{ marginBottom: 12 }}>
        <Col xs={24} lg={20}>
          <div style={{ marginLeft: 0, display: 'inline-block' }}>
            <SelectPublisher showAllOption={true} onChange={onPublisherChange} value={filter.publisherId} />
          </div>
          <div style={{ marginLeft: 10, display: 'inline-block' }}>
            <SelectAdvertiser
              onChange={(value) =>
                setFilter({
                  ...filter,
                  advertiserId: value,
                })
              }
              selectedValue={filter.advertiserId}
            />
          </div>
          <div style={{ marginLeft: 10, display: 'inline-block' }}>
            <Select
              placeholder="Select campaign..."
              style={{ width: DEFAULT_DROPDOWN_WIDTH }}
              defaultValue={null}
              dropdownMatchSelectWidth={false}
              allowClear={false}
              showSearch={true}
              value={filter.campaignId}
              onChange={(value) => onCampaignChange(value)}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Select.Option key={0} value={null}>
                All Campaigns
              </Select.Option>
              {campaignList.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {`${c.advertiser.name} - ${c.name}`}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div style={{ marginLeft: 10, display: 'inline-block' }}>
            <Select
              style={{ minWidth: DEFAULT_DROPDOWN_WIDTH }}
              allowClear={false}
              showSearch={false}
              value={filter.isActive}
              dropdownMatchSelectWidth={false}
              onChange={(value) =>
                setFilter({
                  ...filter,
                  isActive: value,
                })
              }
            >
              <Select.Option value={null}>Show all ads</Select.Option>
              <Select.Option value={true}>Show only active ads</Select.Option>
              <Select.Option value={false}>Show only expired ads</Select.Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24}>
          <Button type="primary" onClick={() => navigate('/ads/create')}>
            <PlusOutlined />
            Create New Ad
          </Button>
        </Col>
      </Row>

      <InfiniteScroll
        dataLength={filteredAdList.length} //This is important field to render the next data
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading more data...</h4>}
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>No more data</b>
        //   </p>
        // }
        // below props only if you need pull down functionality
        // refreshFunction={this.refresh}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
        // releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
      >
        <Table
          size="small"
          columns={adListTableColumns({
            onDelete: onDelete,
            onUpdateAdStatus: onUpdateAdStatus,
          })}
          loading={isLoading}
          rowKey="id"
          dataSource={filteredAdList}
          pagination={false}
          bordered
        />
      </InfiniteScroll>
    </div>
  );
};
