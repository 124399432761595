import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert, Modal } from 'antd';
import { ApiEndpoint } from '../../config';
import { postData, putData, getDataById, deleteData } from '../../api/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';

const { confirm } = Modal;

export const EditRoleForm = () => {
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const isEdit = roleId !== undefined;

  useEffect(() => {
    const loadRoleById = async (roleId) => {
      console.debug('Load role data');
      const { data } = await getDataById(ApiEndpoint.UserRole.Get, roleId, setError);
      if (data) {
        form.setFieldsValue(data);
      }
    };

    if (roleId) {
      loadRoleById(roleId);
    }
  }, [roleId, form]);

  const onFinish = async (formValues) => {
    console.log(formValues);
    if (isEdit) {
      const { data } = await putData(ApiEndpoint.UserRole.Update, roleId, formValues, setError, 'Update Role Successful');
      if (data) {
        console.debug('Update Role Successful');
        navigate(`/role`);
      }
    } else {
      const { data } = await postData(ApiEndpoint.UserRole.Create, formValues, setError, 'Create New User Successful');
      if (data) {
        console.debug('Create Role Successful');
        navigate(`/role`);
      }
    }
  };

  const onDeleteRole = async () => {
    const { data } = await deleteData(ApiEndpoint.UserRole.Create, roleId, null);
    if (data) {
      console.debug('Deleted Role Successful');
      navigate(`/role`);
    }
  };

  const onUpdateButtonClick = (formValues) => {
    confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onFinish(formValues);
      },
      onCancel() {},
    });
  };

  const onDeleteButtonClick = () => {
    confirm({
      title: 'Confirm Delete?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onDeleteRole();
      },
      onCancel() {},
    });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New Role</h3>}
      {isEdit && <h3>Edit Role</h3>}
      {error && (
        <div>
          <Alert type="error" message={error}></Alert>
        </div>
      )}

      <Form {...extendedFormLayout} form={form} name="role-from" onFinish={onUpdateButtonClick}>
        <Form.Item
          name="name"
          label="Role Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit">
            {isEdit ? 'Update' : 'Create Role'}
          </Button>
          {isEdit && (
            <Button type="ghost" disabled danger htmlType="button" onClick={onDeleteButtonClick}>
              Delete Role
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
