import React from 'react';
import { AppContextProvider } from './context/AppContext';
import { initialAppState } from './context/initialAppState';
import ModalProvider from './context/ModalContext';
import { reducer } from './context/reducer';

const Providers = ({ children }) => {
  return (
    <AppContextProvider initialState={initialAppState} reducer={reducer}>
      <ModalProvider>{children}</ModalProvider>
    </AppContextProvider>
  );
};

Providers.whyDidYouRender = false; // For debugging re-render

export default Providers;
