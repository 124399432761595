export const getTotalImpressionAndClick = (impressionData = [], clickData = [], reachData = []) => {
  const totalImpression = impressionData.reduce((acc, cur) => {
    return acc + cur.total;
  }, 0);

  const totalClick = clickData.reduce((acc, cur) => {
    return acc + (cur.total ?? 0);
  }, 0);

  const totalReach = reachData.reduce((acc, cur) => {
    return acc + (cur.total ?? 0);
  }, 0);

  const ctr = (totalClick / totalImpression) * 100;

  return { totalImpression, totalClick, totalReach, ctr: ctr.toFixed(2) };
};
