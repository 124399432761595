const rules = {
  Report: {
    static: ['report:campaign'],
  },
  Editor: {
    static: ['advertiser:manage', 'campaign:manage', 'ad:edit', 'ad:approve', 'report:adgroup', 'report:campaign'],
  },
  Manager: {
    static: [
      'advertiser:manage',
      'advertiser:create',
      'campaign:manage',
      'campaign:create',
      'ad:create',
      'ad:edit',
      'ad:delete',
      'ad:approve',
      'report:adgroup',
      'report:campaign',
    ],
    dynamic: {
      'ad:edit': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },
  Administrator: {
    static: [
      'menu:users',
      'menu:settings',
      'advertiser:manage',
      'advertiser:create',
      'campaign:manage',
      'campaign:create',
      'ad:create',
      'ad:edit',
      'ad:delete',
      'ad:approve',
      'report:adgroup',
      'report:campaign',
    ],
  },
};

export const rulesDescription = {
  'menu:users': 'Can manage, add/edit Users',
  'menu:settings': 'Can manage, add/edit AdGroup, AdUnit and Publisher',
  'advertiser:manage': 'Can edit Advertiser',
  'advertiser:create': 'Can create new Advertiser',
};

export default rules;
