import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Button, Alert, Modal, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { postData, putData, getDataById, deleteData } from '../../api/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';
import { SelectAdGroup } from '../Forms/SelectAdGroup';
import { useRouter } from '../../hooks/useRouter';

const ModalForm = ({ visible, onCancel, onFinish }) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal title="Add Network Unit Id" visible={visible} okText="Add" onOk={onOk} onCancel={onCancel}>
      <Form form={form} onFinish={onFinish} layout="vertical" name="networkUnitForm">
        <Form.Item
          name="platform"
          label="Platform"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="android">Android</Select.Option>
            <Select.Option value="ios">iOS</Select.Option>
            <Select.Option value="Huawei">Huawei</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="adNetworkUnitId"
          label="Unit Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const AdUnitForm = () => {
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [adUnit, setAdUnit] = useState({});
  const [form] = Form.useForm();
  const { navigate, query } = useRouter();

  const { adgroupId, unitId } = query;
  const isEdit = unitId !== undefined;

  const loadAdUnit = useCallback(
    async (unitId) => {
      const { data } = await getDataById(ApiEndpoint.AdUnit.Get, unitId, setError);
      setAdUnit(data);
      form.setFieldsValue({
        ...data,
        adGroupId: data.adGroup.id,
        adNetworkName: data.adNetwork.name,
        frequencyCapImpression: data.frequencyCap || data.frequencyCap?.impression === null ? data.frequencyCap.impression : null,
        frequencyCapTimePeriod: data.frequencyCap || data.frequencyCap?.impression === null ? data.frequencyCap.timePeriod : 'Day',
      });
    },
    [form],
  );

  useEffect(() => {
    if (unitId) {
      loadAdUnit(unitId);
    }
  }, [unitId, loadAdUnit]);

  useEffect(() => {
    if (adgroupId) {
      form.setFieldsValue({
        adGroupId: Number(adgroupId),
      });
    }
  }, [adgroupId, form]);

  const onFinish = async (formValues) => {
    if (isEdit) {
      // Update Ad Unit
      const response = await putData(
        ApiEndpoint.AdUnit.Update,
        unitId,
        {
          ...formValues,
          adNetwork: formValues.adNetworkName,
          frequencyCap:
            formValues.frequencyCapImpression === null || formValues.frequencyCapImpression === undefined
              ? null
              : {
                  impression: formValues.frequencyCapImpression,
                  timePeriod: formValues.frequencyCapTimePeriod,
                },
        },
        setError,
        'Update Ad Network Unit Successful',
      );
      if (response && response.data) {
        navigate(`/setting/adgroup/${form.getFieldValue('adGroupId')}`);
      }
    } else {
      // Create New Ad Unit
      const response = await postData(
        ApiEndpoint.AdUnit.Create,
        {
          ...formValues,
          adNetwork: formValues.adNetworkName,
          frequencyCap:
            formValues.frequencyCapImpression === null || formValues.frequencyCapImpression === undefined
              ? null
              : {
                  impression: formValues.frequencyCapImpression,
                  timePeriod: formValues.frequencyCapTimePeriod,
                },
        },
        setError,
        'Create New Ad Unit Successful',
      );
      if (response && response.data) {
        navigate(`/setting/adgroup/${formValues.adGroupId}`);
      }
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddAdNetwork = async (formValues) => {
    await postData(
      ApiEndpoint.AdNetworkUnit.Create,
      {
        ...formValues,
        adUnitId: unitId,
      },
      setError,
      'Create New Ad Network Unit Successful',
    );
    // Reload Data from Service
    loadAdUnit(unitId);
    setModalVisible(false);
  };

  const onDeleteAdNetwork = async (id) => {
    await deleteData(ApiEndpoint.AdNetworkUnit.Delete, id, setError, 'Create New Ad Network Unit Successful');

    // Reload Data from Service
    loadAdUnit(unitId);
  };

  const frequencyCapingValidator = (rule, value) => {
    if (adUnit?.adGroup?.adGroupType?.name.toLowerCase() === 'rewarded') {
      console.debug('REWARD!!!');
      if (!value) {
        alert('Reward AdGroup Must set Frequency Capping');
        return Promise.reject(new Error('Reward AdGroup Must set Frequency Capping'));
      }
    }
    return Promise.resolve();
    // return value ? Promise.resolve() : Promise.reject(new Error("Should accept agreement"));
  };

  const showConfirm = (formValues) => {
    Modal.confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onFinish(formValues);
      },
      onCancel() {},
    });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New Ad Network Unit</h3>}
      {isEdit && <h3>Edit Ad Network Unit</h3>}
      {error && (
        <div>
          <Alert type="error" message={error}></Alert>
        </div>
      )}

      <Form {...extendedFormLayout} form={form} name="control-hooks" onFinish={showConfirm}>
        <Form.Item
          name="adGroupId"
          label="Ad Group"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectAdGroup width={300} disabled={isEdit} filteredAdGroupId={adgroupId ? Number(adgroupId) : null} />
        </Form.Item>
        <Form.Item
          name="adNetworkName"
          label="Network Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select disabled={isEdit}>
            <Select.Option value="ookbee">Ookbee</Select.Option>
            <Select.Option value="admob">Admob</Select.Option>
            <Select.Option value="huawei">Huawei</Select.Option>
            <Select.Option value="appodeal">AppOdeal</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="sortSeq"
          label="Sort Sequence"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Frequency Capping"
          rules={[
            {
              validator: frequencyCapingValidator,
            },
          ]}
          name="frequencyCapImpression"
        >
          <Select style={{ width: 200 }}>
            <Select.Option value={null}>Unlimit</Select.Option>
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={6}>6</Select.Option>
            <Select.Option value={7}>7</Select.Option>
            <Select.Option value={8}>8</Select.Option>
            <Select.Option value={9}>9</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={11}>11</Select.Option>
            <Select.Option value={12}>12</Select.Option>
            <Select.Option value={13}>13</Select.Option>
            <Select.Option value={14}>14</Select.Option>
            <Select.Option value={15}>15</Select.Option>
            <Select.Option value={16}>16</Select.Option>
            <Select.Option value={17}>17</Select.Option>
            <Select.Option value={18}>18</Select.Option>
            <Select.Option value={19}>19</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={21}>21</Select.Option>
            <Select.Option value={22}>22</Select.Option>
            <Select.Option value={23}>23</Select.Option>
            <Select.Option value={24}>24</Select.Option>
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={26}>26</Select.Option>
            <Select.Option value={27}>27</Select.Option>
            <Select.Option value={28}>28</Select.Option>
            <Select.Option value={29}>29</Select.Option>
            <Select.Option value={30}>30</Select.Option>
            <Select.Option value={31}>31</Select.Option>
            <Select.Option value={32}>32</Select.Option>
            <Select.Option value={33}>33</Select.Option>
            <Select.Option value={34}>34</Select.Option>
            <Select.Option value={35}>35</Select.Option>
            <Select.Option value={36}>36</Select.Option>
            <Select.Option value={37}>37</Select.Option>
            <Select.Option value={38}>38</Select.Option>
            <Select.Option value={39}>39</Select.Option>
            <Select.Option value={40}>40</Select.Option>
            <Select.Option value={41}>41</Select.Option>
            <Select.Option value={42}>42</Select.Option>
            <Select.Option value={43}>43</Select.Option>
            <Select.Option value={44}>44</Select.Option>
            <Select.Option value={45}>45</Select.Option>
            <Select.Option value={46}>46</Select.Option>
            <Select.Option value={47}>47</Select.Option>
            <Select.Option value={48}>48</Select.Option>
            <Select.Option value={49}>49</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={51}>51</Select.Option>
            <Select.Option value={52}>52</Select.Option>
            <Select.Option value={53}>53</Select.Option>
            <Select.Option value={54}>54</Select.Option>
            <Select.Option value={55}>55</Select.Option>
            <Select.Option value={56}>56</Select.Option>
            <Select.Option value={57}>57</Select.Option>
            <Select.Option value={58}>58</Select.Option>
            <Select.Option value={59}>59</Select.Option>
            <Select.Option value={60}>60</Select.Option>
            <Select.Option value={61}>61</Select.Option>
            <Select.Option value={62}>62</Select.Option>
            <Select.Option value={63}>63</Select.Option>
            <Select.Option value={64}>64</Select.Option>
            <Select.Option value={65}>65</Select.Option>
            <Select.Option value={66}>66</Select.Option>
            <Select.Option value={67}>67</Select.Option>
            <Select.Option value={68}>68</Select.Option>
            <Select.Option value={69}>69</Select.Option>
            <Select.Option value={70}>70</Select.Option>
            <Select.Option value={71}>71</Select.Option>
            <Select.Option value={72}>72</Select.Option>
            <Select.Option value={73}>73</Select.Option>
            <Select.Option value={74}>74</Select.Option>
            <Select.Option value={75}>75</Select.Option>
            <Select.Option value={76}>76</Select.Option>
            <Select.Option value={77}>77</Select.Option>
            <Select.Option value={78}>78</Select.Option>
            <Select.Option value={79}>79</Select.Option>
            <Select.Option value={80}>80</Select.Option>
            <Select.Option value={81}>81</Select.Option>
            <Select.Option value={82}>82</Select.Option>
            <Select.Option value={83}>83</Select.Option>
            <Select.Option value={84}>84</Select.Option>
            <Select.Option value={85}>85</Select.Option>
            <Select.Option value={86}>86</Select.Option>
            <Select.Option value={87}>87</Select.Option>
            <Select.Option value={88}>88</Select.Option>
            <Select.Option value={89}>89</Select.Option>
            <Select.Option value={90}>90</Select.Option>
            <Select.Option value={91}>91</Select.Option>
            <Select.Option value={92}>92</Select.Option>
            <Select.Option value={93}>93</Select.Option>
            <Select.Option value={94}>94</Select.Option>
            <Select.Option value={95}>95</Select.Option>
            <Select.Option value={96}>96</Select.Option>
            <Select.Option value={97}>97</Select.Option>
            <Select.Option value={98}>98</Select.Option>
            <Select.Option value={99}>99</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={200}>200</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Frequency Capping Period" name="frequencyCapTimePeriod">
          <Select style={{ width: 200 }}>
            <Select.Option value="Day">Day</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="formUrl"
          tooltip="for GroupType 'form' only"
          label="Form Url"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="formStartAt"
          tooltip="for GroupType 'form' only"
          label="Form Start Date"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="datetime" />
        </Form.Item>
        <Form.Item
          name="formEndAt"
          tooltip="for GroupType 'form' only"
          label="Form End Date"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="datetime" />
        </Form.Item>
        <Form.Item label="UnitId by Platform" shouldUpdate={(prevValues, curValues) => prevValues.adNetwork !== curValues.adNetwork}>
          {({ getFieldValue }) => {
            const adNetwork = getFieldValue('adNetwork') || {};
            if (!adNetwork || !adNetwork.adNetworkUnits) return null;
            return adNetwork.adNetworkUnits.length ? (
              <>
                {adNetwork.adNetworkUnits.map((unit, index) => (
                  <div key={index} className="user">
                    {unit.platform}: {unit.adNetworkUnitId}{' '}
                    <Button size="small" onClick={() => onDeleteAdNetwork(unit.id)}>
                      <DeleteOutlined style={{ color: 'red' }} />
                    </Button>
                  </div>
                ))}
              </>
            ) : (
              <div>None</div>
            );
          }}
        </Form.Item>
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit">
            {isEdit ? 'Update' : 'Create Ad Network Unit'}
          </Button>
          {isEdit && (
            <Button htmlType="button" onClick={showModal}>
              Add Network Unit Id
            </Button>
          )}
        </Form.Item>
      </Form>
      <ModalForm visible={modalVisible} onFinish={onAddAdNetwork} onCancel={hideModal} />
    </div>
  );
};
