import React from 'react';
import { Select } from 'antd';
import { useStore } from '../../context/AppContext';
import { DEFAULT_DROPDOWN_WIDTH } from '../../config';

export const SelectPublisher = ({ style, value, onChange, showAllOption = false, allowChange = true }) => {
  const [appState] = useStore();
  const { publishers } = appState;

  return (
    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width: DEFAULT_DROPDOWN_WIDTH, ...style }}
      disabled={!allowChange}
      placeholder={showAllOption ? 'Filter by Publisher' : 'Select Publisher'}
      onChange={onChange}
    >
      {showAllOption && (
        <Select.Option key={0} value={null}>
          All Publisher
        </Select.Option>
      )}
      {publishers.map((pub) => (
        <Select.Option key={pub.id} value={pub.id}>
          {pub.name} - {pub.description}
        </Select.Option>
      ))}
    </Select>
  );
};
