import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { getData, deleteData } from '../../api/helpers';
import { useStore } from '../../context/AppContext';
import { SelectAdGroup } from '../Forms/SelectAdGroup';
import { adUnitTableColumns } from './adUnitTableColumns';
import { SelectPublisher } from '../Forms/SelectPublisher';

export const AdUnitList = () => {
  const [adUnit, setAdUnit] = useState([]);
  const [adGroups, setAdGroups] = useState([]);
  const [error, setError] = useState(null);
  const [appState] = useStore();

  useEffect(() => {
    const loadAllAdUnitData = async () => {
      const { data } = await getData(`${ApiEndpoint.AdUnit.List}`, setError);
      if (data) {
        const result = data.items;
        setAdUnit(result);
      }
    };

    if (appState && appState.publishers && appState.groupTypes) {
      loadAllAdUnitData();
    }
  }, [appState]);

  const onDeleteAdUnit = async (id) => {
    deleteData(ApiEndpoint.AdUnit.Delete, id, null);
    // remove item from AdList state
    setAdUnit(adUnit.filter((item) => item.id !== id));
  };

  const onAdGroupChange = async (id) => {
    const { data } = await getData(`${ApiEndpoint.AdUnit.List}&adGroupId=${id}`, setError);
    if (data) {
      const result = data.items.sort(function (a, b) {
        return a.sortSeq - b.sortSeq;
      });
      setAdUnit(result);
    }
  };

  const onPublisherChange = async (id) => {
    const { data } = await getData(`${ApiEndpoint.AdGroup.List}&publisherId=${id}`, null);
    if (data) {
      const result = data.items;
      setAdGroups(result);
    }
  };

  return (
    <div className="container">
      <h3>Ad Network Units</h3>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={18}>
          <div>
            <SelectPublisher onChange={onPublisherChange} />
          </div>
          <div>
            <SelectAdGroup data={adGroups} onChange={onAdGroupChange} />
          </div>
        </Col>
        <Col xs={6}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to="/setting/adunit/create">
              <PlusOutlined style={{ marginRight: 5 }} />
              New Ad Network Unit
            </Link>
          </div>
        </Col>
      </Row>
      {error && (
        <div>
          <Alert type="error" message={error} />
        </div>
      )}
      <Table
        rowKey="id"
        dataSource={adUnit}
        columns={adUnitTableColumns({
          onDelete: onDeleteAdUnit,
          publisherData: [],
        })}
        pagination={false}
        bordered
      />
    </div>
  );
};
