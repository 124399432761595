import React, { useState } from 'react';
import Page from '../../containers/Page';
import { Button, Input, Card, Modal } from 'antd';
import useModalContext from '../../hooks/useModalContext';

const MyModal = ({ onDismiss, searchText, onSearchTextChange }) => {
  return (
    <Modal title="My Modal" onOk={onDismiss} onCancel={onDismiss} destroyOnClose={false}>
      <Card>
        <Input onChange={onSearchTextChange} />
        <Button>Haha</Button>
        {searchText}
        <Button onClick={onDismiss}>close</Button>
      </Card>
    </Modal>
  );
};

const TestModalContext = () => {
  const [searchText, setSearchText] = useState();

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  // Cannot use {searchText} value as a prop to <MyModal> because it will re-create using React.cloneElement(...) in ModalContext
  // Solutions is to use ContextApi or Redux for global state
  const [onPresentModal] = useModalContext(<MyModal onSearchTextChange={onSearchTextChange} />);

  return (
    <Page title="Test Modal Context (useModelContext)">
      <h1>{searchText}</h1>
      <Button onClick={onPresentModal}>Open Modal</Button>
    </Page>
  );
};

export default TestModalContext;
