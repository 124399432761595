import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Modal, Select } from 'antd';
import { ApiEndpoint } from '../../config';
import { postData, putData, getDataById, getData, deleteData } from '../../api/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';
import { useRouter } from '../../hooks/useRouter';

const { confirm } = Modal;

export const EditRolePermissionForm = () => {
  const [error, setError] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [form] = Form.useForm();
  const { navigate, query } = useRouter();
  const { permissionId } = query;
  const isEdit = permissionId !== undefined;

  useEffect(() => {
    const loadUserRoles = async () => {
      console.debug('Load permission data');
      const { data } = await getData(ApiEndpoint.UserRole.List, setError);
      if (data && data.items) {
        setUserRoles(data.items);
      }
    };

    loadUserRoles();
  }, []);

  useEffect(() => {
    const loadPermissionById = async (permissionId) => {
      console.debug('Load permission data');
      const { data } = await getDataById(ApiEndpoint.UserRolePermission.Get, permissionId, setError);
      if (data) {
        form.setFieldsValue(data);
      }
    };

    if (permissionId) {
      loadPermissionById(permissionId);
    }
  }, [permissionId, form]);

  const onUpdateRolePermission = async (formValues) => {
    console.log(formValues);
    if (isEdit) {
      const { data } = await putData(
        ApiEndpoint.UserRolePermission.Update,
        permissionId,
        formValues,
        setError,
        'Update Permission Successful',
      );
      if (data) {
        console.debug('Update Permission Successful');
        navigate(`/permission`);
      }
    } else {
      const { data } = await postData(ApiEndpoint.UserRolePermission.Create, formValues, setError, 'Create Role Permission Successful');
      if (data) {
        console.debug('Create role permission Successful');
        navigate(`/permission`);
      }
    }
  };

  const onDeleteRolePermission = async () => {
    const { data } = await deleteData(ApiEndpoint.UserRolePermission.Create, permissionId, null);
    if (data) {
      console.debug('Deleted Permission Successful');
      navigate(`/permission`);
    }
  };

  const onUpdateButtonClick = (formValues) => {
    confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onUpdateRolePermission(formValues);
      },
      onCancel() {},
    });
  };

  const onDeleteButtonClick = () => {
    confirm({
      title: 'Confirm Delete?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onDeleteRolePermission();
      },
      onCancel() {},
    });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New Role-Permission</h3>}
      {isEdit && <h3>Edit Role-Permission</h3>}
      {error && (
        <div>
          <Alert type="error" message={error}></Alert>
        </div>
      )}

      <Form {...extendedFormLayout} form={form} name="role-permission-form" onFinish={onUpdateButtonClick}>
        <Form.Item
          name="extensionName"
          label="Extension Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="roleId"
          label="Role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {userRoles.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item name="isCreate" label="Can Create" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="isRead" label="Can Read" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="isUpdate" label="Can Update" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="isDelete" label="Can Delete" valuePropName="checked">
          <Checkbox />
        </Form.Item> */}
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit">
            {isEdit ? 'Update' : 'Create Role Permission'}
          </Button>
          {isEdit && (
            <Button type="ghost" danger htmlType="button" onClick={onDeleteButtonClick}>
              Delete Permission
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
