import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

export const campaignListTableColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (id, record) => <span>{id}</span>,
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    title: 'Campaign Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <>
        <Link to={`/campaign/${record.id}`}>{text}</Link>
        <br />
        <Typography>{record.description}</Typography>
      </>
    ),
  },
  {
    title: 'Advertiser',
    dataIndex: 'advertiser',
    key: 'advertiser',
    render: (advertiser, record) => {
      return (
        <Link className="text-bold" to={`/advertiser/${advertiser.id}`}>
          {advertiser.name}
        </Link>
      );
    },
    sorter: (a, b) => a.advertiser.name.length - b.advertiser.name.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Active Ads',
    dataIndex: 'totalAds',
    key: 'totalAds',
    className: 'text-bold',
    render: (totalAds) => <span>{totalAds}</span>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
        <Link to={`/campaign/${record.id}/edit`}>Edit</Link>
      </span>
    ),
  },
];
