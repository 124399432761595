import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useStore } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

import IconWithTextCard from './CRM/IconWithTextCard';
import Summary from './CRM/WelComeCard';
import PublisherReport from './CRM/PublisherReport';
import { PlatformDistributionChart } from './CRM/TaskByStatus';

import { ApiEndpoint } from '../../config';
import { getData } from '../../api/helpers';
import { generateDayChartData } from '../Report/helpers/generateDayChartData';
import moment from 'moment';

const Dashboard = () => {
  const [appState] = useStore();

  const [totalAdvertiser, setTotalAdvertiser] = useState(0);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const [pendingApproval, setPendingApproval] = useState(0);
  const [pendingPreview, setPendingPreview] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState(true);

  // const getTotalAdvertiser = useCallback(async () => {
  //   const { data } = await getData(ApiEndpoint.Advertiser.List);
  //   if (data && data.items) {
  //     return data.items.length;
  //   }
  // }, []);

  useEffect(() => {
    async function getTotalAdvertiser() {
      const { data } = await getData(ApiEndpoint.Advertiser.List);
      if (data && data.items) {
        setTotalAdvertiser(data.items.length);
      }
    }

    async function getTotalCampaign() {
      const { data } = await getData(ApiEndpoint.Campaign.List);
      if (data && data.items) {
        setTotalCampaigns(data.items.length);
      }
    }

    async function getTotalAds() {
      const { data } = await getData(ApiEndpoint.Ads.List);
      if (data && data.items) {
        setTotalAds(data.items.length);
        setPendingApproval(data.items.filter((i) => i.status.toLowerCase() === 'waiting').length);
        setPendingPreview(data.items.filter((i) => i.status.toLowerCase() === 'preview').length);
      }
    }

    async function getRecent30DaysImpressionReport() {
      const startDate = moment().add(-30, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      const startDateQuery = moment().add(-30, 'days').set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ');
      const endDateQuery = moment().set({ h: 0, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ssZ');

      console.debug(appState.publishers);
      const joyladaPubs = appState.publishers.filter((x) => x.name.toLowerCase().indexOf('joylada') === 0);

      const dashboardData = [];
      const joyladaImpressionData = [];

      await Promise.all(
        joyladaPubs.map(async (joylada) => {
          const { data } = await getData(
            `${ApiEndpoint.Report.Publisher}/${joylada.id}/impression?start=${startDateQuery}&end=${endDateQuery}`,
          );
          const _data = {
            id: joylada.id,
            name: joylada.name,
            countryCode: joylada.countryCode,
            impression: data && data.items ? generateDayChartData([startDate, endDate], data.items) : [],
          };
          joyladaImpressionData.push(_data);
        }),
      );
      // if (joyladaThaiImpressionData && joyladaThaiImpressionData.items) {
      //   const joyladaThaiDayChartImpression = generateDayChartData(
      //     [startDate, endDate],
      //     joyladaThaiImpressionData.items
      //   );

      console.debug(joyladaImpressionData);

      if (joyladaImpressionData.length > 0) {
        for (let i = 0; i < joyladaImpressionData[0].impression.length; i++) {
          dashboardData.push({
            day: joyladaImpressionData[0].impression[i].day,
            joylada: {
              th: joyladaImpressionData.find((x) => x.countryCode === 'th' && x.name.indexOf('test') < 0)?.impression[i].total,
              kr: joyladaImpressionData.find((x) => x.countryCode === 'kr')?.impression[i].total,
              la: joyladaImpressionData.find((x) => x.countryCode === 'la')?.impression[i].total,
              my: joyladaImpressionData.find((x) => x.countryCode === 'my')?.impression[i].total,
              id: joyladaImpressionData.find((x) => x.countryCode === 'id')?.impression[i].total,
              vn: joyladaImpressionData.find((x) => x.countryCode === 'vn')?.impression[i].total,
              en: joyladaImpressionData.find((x) => x.countryCode === 'en')?.impression[i].total,

              test: joyladaImpressionData.find((x) => x.name.toLowerCase() === 'joylada_test')?.impression[i].total,
            },
          });
        }
      }
      console.debug(dashboardData);
      if (dashboardData.length > 0) {
        setIsLoadingChartData(false);
        setChartData(dashboardData);
      }
      // }
    }

    // if (!appState.user || !appState.user.accessToken) {
    //   console.log("not authorized, redirect to login...");
    //   navigate("/login");
    // }

    getTotalAdvertiser();
    getTotalCampaign();
    getTotalAds();
    getRecent30DaysImpressionReport();
  }, [appState.publishers]);

  return (
    <div className="container">
      <h1>Dashboard</h1>
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                  <Summary
                    totalAdvertiser={totalAdvertiser}
                    totalCampaigns={totalCampaigns}
                    totalAds={totalAds}
                    pendingApproval={pendingApproval}
                    pendingPreview={pendingPreview}
                  />
                </Col>
                <Col lg={24} xs={24} className="gx-visit-col">
                  {/* <p className="gx-fs-sm gx-text-uppercase">All Ad Networks Summary</p> */}
                  <PublisherReport chartData={chartData} isLoading={isLoadingChartData} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
          <PlatformDistributionChart
            title="Ad served by Type [Joylada]"
            data={[
              { name: 'In-house', value: 30 },
              { name: 'Network Ads', value: 60 },
              { name: 'No fill', value: 10 },
            ]}
          />
        </Col>
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
          <PlatformDistributionChart
            title="Ad served by Type [Tunwalai]"
            data={[
              { name: 'Advertiser', value: 25 },
              { name: 'In-house', value: 35 },
              { name: 'Network Ads', value: 40 },
              { name: 'No fill', value: 10 },
            ]}
          />
        </Col>
        <Col xl={8} lg={8} md={12} sm={24} xs={24}></Col>
        <Col xs={24} className="gx-order-sm-1">
          <Row gutter={[16, 16]}>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <IconWithTextCard cardColor="cyan" linkTo="/advertiser" icon="diamond" title={totalAdvertiser} subTitle="Advertiser" />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <IconWithTextCard cardColor="orange" linkTo="/campaign" icon="tasks" title={totalCampaigns} subTitle="Campaign" />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
              <IconWithTextCard cardColor="teal" linkTo="/ads" icon="team" title={totalAds} subTitle="Ads" />
            </Col>

            {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Overview />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
