import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Table, Statistic, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { getData } from '../../api/helpers';
import { ApiEndpoint } from '../../config';
import { useStore } from '../../context/AppContext';
import Can from '../Can';

const AdvertiserListTableColumn = [
  {
    title: '',
    dataIndex: 'imagePath',
    key: 'imagePath',
    render: (url, record) => (
      <Link to={`/advertiser/${record.id}/campaign`}>
        <Avatar src={url} size="large" />
      </Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Link to={`/advertiser/${record.id}/campaign`}>{text}</Link>,
  },
  // {
  //   title: "Description",
  //   dataIndex: "description",
  //   key: "description",
  // },
  {
    title: 'Total Campaign',
    dataIndex: 'totalCampaign',
    key: 'totalCampaign',
    render: (total) => <Statistic title={null} value={total} />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, advertiser) => (
      <div>
        <Link to={`/advertiser/${advertiser.id}`} key="list-loadmore-edit" style={{ display: 'block', marginBottom: 5 }}>
          <EditOutlined /> Edit Info
        </Link>
        <Link to={`/advertiser/${advertiser.id}/campaign`} key="list-view-campaign">
          <EyeOutlined /> View Campaign
        </Link>
      </div>
    ),
  },
];

export const AdvertiserList = () => {
  const [advertiserList, setAdvertiserList] = useState([]);
  const [appState] = useStore();
  const { roleName } = appState.user;

  useEffect(() => {
    async function fetchAdvertiser() {
      const response = await getData(ApiEndpoint.Advertiser.List);
      const { items } = await response.data;
      setAdvertiserList(items);
    }

    fetchAdvertiser();
  }, []);

  return (
    <div className="container">
      <Can
        role={roleName}
        perform="advertiser:create"
        yes={() => (
          <Row style={{ marginBottom: 8 }}>
            <Col xs={24} flex className="align-right">
              <Link to="/advertiser/create" className="ant-btn ant-btn-primary">
                <PlusOutlined style={{ marginRight: 4 }} />
                New Advertiser
              </Link>
            </Col>
          </Row>
        )}
      />

      <Table rowKey="id" dataSource={advertiserList} columns={AdvertiserListTableColumn} pagination={false} />
    </div>
  );
};
