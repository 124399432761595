import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button, Table, Typography, Input } from 'antd';
import { getData } from '../../api/helpers';
import { IS_PRODUCTION } from '../../config';
import { DailyChart } from './components/DailyChart';
import { generateDayChartData } from './helpers/generateDayChartData';
import { combineClickImpression } from './helpers/combineClickImpression';
import { ReloadOutlined } from '@ant-design/icons';
import { useStore } from '../../context/AppContext';
import ReactExport from 'react-data-export';
import { creatorRevenueTableColumns } from './tableColumns/creatorRevenueTableColumns';
import { creatorImpressionBreakdown } from './tableColumns/creatorImpressionBreakdown';
import { SelectCreatorPresetModal } from './components/SelectCreatorPresetModal';
import { DateRangePicker } from './components/DateRangePicker';
import Page from '../../containers/Page';
import { SetLastMonthButton } from './components/SetLastMonthButton';
import { SetThisMonthButton } from './components/SetThisMonthButton';

const { Text } = Typography;

export const CreatorRevenue = () => {
  const [filter, setFilter] = useState({
    creatorId: null,
    periods: [null, null],
    reload: 0,
  });

  const [selectedCreator, setSelectedCreator] = useState(null);
  const [dailyChartData, setDailyChartData] = useState([]);
  const [creatorImpressionBreakdownByContentData, setImpressionBreakdownByContentData] = useState([]);
  const [revenueSummaryData, setRevenueSummaryData] = useState([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [appState] = useStore();
  const [creatorModalVisible, setCreatorModalVisible] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getAdImpressionReportByCreatorId = useCallback(async (creatorId, periods) => {
    setIsLoadingChartData(true);
    const { data: impSummaryData } = await getData(`/report/creator/${creatorId}/impression?start=${periods[0]}&end=${periods[1]}`);
    const { data: clickSummaryData } = await getData(`/report/creator/${creatorId}/click?start=${periods[0]}&end=${periods[1]}`);

    // console.debug(impSummaryData);
    if (impSummaryData && impSummaryData.items) {
      const summaryByContentId = combineClickImpression(impSummaryData.items, clickSummaryData.items, 'contentId');
      setImpressionBreakdownByContentData(summaryByContentId);
    }

    setIsLoadingChartData(false);
  }, []);

  const getRevenueSharingReportByCreatorId = useCallback(async (creatorId, periods) => {
    setIsLoadingChartData(true);
    const { data: chartSummaryData } = await getData('/report/revenuesharing/' + creatorId + '?start=' + periods[0] + '&end=' + periods[1]);

    if (chartSummaryData && chartSummaryData.items) {
      const dailyChartData = generateDayChartData(periods, chartSummaryData.items);
      setDailyChartData(dailyChartData);
      setRevenueSummaryData(chartSummaryData.items);
    }

    setIsLoadingChartData(false);
  }, []);

  const onSelectCreatorIdChange = useCallback(
    (value) => {
      setFilter({ ...filter, creatorId: value });
      setCreatorModalVisible(false); // Auto close modal after selected
    },
    [filter],
  );

  // When selected creator from dropdown changed
  useEffect(() => {
    if (filter.creatorId) {
      setSelectedCreator(appState.creators.find((creator) => creator.id === filter.creatorId));
    }
  }, [appState.creators, filter.creatorId]);

  // When all filter has value
  useEffect(() => {
    if (filter.creatorId && filter.periods[0] && filter.periods[1]) {
      getRevenueSharingReportByCreatorId(filter.creatorId, filter.periods);
      getAdImpressionReportByCreatorId(filter.creatorId, filter.periods);
    }
  }, [filter.creatorId, filter.periods, filter.reload, getRevenueSharingReportByCreatorId, getAdImpressionReportByCreatorId]);

  return (
    <Page title="Ookbee Revenue Report by Content Creators">
      <Row gutter={24}>
        <Col xs={24} md={20} lg={20}>
          <Card hoverable className="gx-card-widget" title="Select Creator and DateTime" type="inner">
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24}>
                <Input.Search
                  style={{ lineHeight: 1.4 }}
                  placeholder="Type the creator guid eg. c7a9a1c8-65ea-4b4b-99a5-16e34f3c5925"
                  type="text"
                  disabled={isLoadingChartData}
                  value={filter.creatorId}
                  maxLength={36}
                  enterButton="Preset"
                  onChange={(e) => setFilter({ ...filter, creatorId: e.target.value })}
                  onSearch={() => setCreatorModalVisible(true)}
                />
                <SelectCreatorPresetModal
                  creatorModalVisible={creatorModalVisible}
                  creatorId={filter.creatorId}
                  setCreatorModalVisible={setCreatorModalVisible}
                  onChangeCreatorId={onSelectCreatorIdChange}
                  isLoadingChartData={isLoadingChartData}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={21}>
                <DateRangePicker filter={filter} setFilter={setFilter} isLoadingChartData={isLoadingChartData} />
                <SetLastMonthButton filter={filter} setFilter={setFilter} />
                <SetThisMonthButton filter={filter} setFilter={setFilter} />
              </Col>
              <Col xs={3}>
                <Button
                  type="default"
                  disabled={isLoadingChartData}
                  style={{ width: '100%' }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      reload: !isNaN(filter.reload) ? filter.reload + 1 : 0,
                    })
                  }
                >
                  <ReloadOutlined spin={isLoadingChartData} />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <DailyChart reportData={dailyChartData} chartName="Impression/Day" isLoading={isLoadingChartData} />
      {/* <ExcelFile
            filename={selectedCreator?.name}
            element={
              <Button
                type="primary"
                disabled={isLoadingChartData || dailyChartData.length <= 0 || selectedCreator === null}
                style={{ marginTop: 8 }}
              >
                Export to Excel
              </Button>
            }
          >
            <ExcelSheet data={dailyChartData} name={selectedCreator?.name}>
              <ExcelColumn label="Day" value="day" width={{ wpx: 50 }} style={{ width: 150 }} />
              <ExcelColumn label="Impressions" value="total" width={150} />
            </ExcelSheet>
          </ExcelFile> */}
      <Row style={{ marginTop: 10 }}>
        <Col xs={24}>
          <Table
            style={{ marginTop: 20 }}
            loading={false}
            dataSource={revenueSummaryData}
            rowKey="day"
            columns={creatorRevenueTableColumns}
            pagination={false}
            summary={(pageData) => {
              let totalImpression = 0;
              let totalClick = 0;
              let totalRevenue = 0;

              pageData.forEach(({ impression, click, revenue }) => {
                totalImpression += impression;
                totalClick += click;
                totalRevenue += revenue;
              });

              return (
                <>
                  <Table.Summary.Row style={{ backgroundColor: 'gray', color: 'white' }}>
                    <Table.Summary.Cell>
                      <Text strong style={{ color: 'white' }}>
                        Total
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ color: 'white' }}>
                        {totalImpression.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ color: 'white' }}>
                        {totalClick.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ color: 'white' }}>
                        {totalRevenue.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <ExcelFile
            filename={selectedCreator?.name}
            element={
              <Button
                type="primary"
                disabled={isLoadingChartData || dailyChartData.length <= 0 || selectedCreator === null}
                style={{ marginTop: 8 }}
              >
                Export to Excel
              </Button>
            }
          >
            <ExcelSheet data={revenueSummaryData} name={selectedCreator?.name}>
              <ExcelColumn label="Day" value="day" width={{ wpx: 50 }} style={{ width: 150 }} />
              <ExcelColumn label="Impressions" value="impression" width={150} />
              <ExcelColumn label="Click" value="click" width={150} />
              <ExcelColumn label="Revenue" value="revenue" width={150} />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col xs={24}>
          <Table
            style={{ marginTop: 20 }}
            loading={false}
            dataSource={creatorImpressionBreakdownByContentData}
            rowKey="contentId"
            columns={creatorImpressionBreakdown}
            pagination={false}
          />
          <ExcelFile
            filename={selectedCreator?.name}
            element={
              <Button
                type="primary"
                disabled={isLoadingChartData || dailyChartData.length <= 0 || selectedCreator === null}
                style={{ marginTop: 8 }}
              >
                Export to Excel
              </Button>
            }
          >
            <ExcelSheet data={creatorImpressionBreakdownByContentData} name={selectedCreator?.name}>
              <ExcelColumn label="Content Id" value="contentId" width={{ wpx: 50 }} style={{ width: 150 }} />
              <ExcelColumn label="Impressions" value="impression" width={150} />
              <ExcelColumn label="Click" value="click" width={150} />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      {!IS_PRODUCTION && <pre>{JSON.stringify(filter, null, 2)}</pre>}
      {!IS_PRODUCTION && <pre>{JSON.stringify(selectedCreator, null, 2)}</pre>}
    </Page>
  );
};
