import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { getData, deleteData } from '../../api/helpers';
import { useStore } from '../../context/AppContext';
import { publisherTableColumns } from './publisherTableColumns';

export const PublisherList = () => {
  const [publishers, setPublishers] = useState([]);
  const [error, setError] = useState(null);
  const [appState] = useStore();

  useEffect(() => {
    const loadPublishers = async () => {
      const { data } = await getData(`${ApiEndpoint.Publisher.List}`, setError);
      if (data) {
        const result = data.items;
        setPublishers(result);
      }
    };

    loadPublishers();
  }, []);

  const onDeleteAdGroup = async (id) => {
    deleteData(ApiEndpoint.AdGroup.Delete, id, null);
    // remove item from state
    setPublishers(publishers.filter((item) => item.id !== id));
  };

  const distinctPublisherNames = (publishers) => [...new Set(publishers.map((pub) => pub.name))];

  return (
    <div className="container">
      <h3>Publishers</h3>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={18}></Col>
        <Col xs={6}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to="/setting/publisher/create">
              <PlusOutlined style={{ marginRight: 5 }} />
              New Publisher
            </Link>
          </div>
        </Col>
      </Row>
      {error && (
        <div>
          <Alert type="error" message={error} />
        </div>
      )}
      <Table
        rowKey="id"
        dataSource={publishers}
        columns={publisherTableColumns({
          onDelete: onDeleteAdGroup,
          publisherData: distinctPublisherNames(appState.publishers),
          groupTypeData: appState.groupTypes,
        })}
        pagination={true}
        bordered
      />
    </div>
  );
};
