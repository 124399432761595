import React from 'react';
import { Form, Input, Row, Col, Select, Card } from 'antd';
import { AntFormInputItem } from '../../Forms/AntFormInputItem';
import { SelectAdTemplateFormItem } from './SelectAdTemplateFormItem';
import { SelectAdMinimumDisplayFormItem } from './SelectAdMinimumDisplay';
import { InputColorPickerFormItem } from './InputColorPickerFormItem';

export const AdPreferences = ({ setFieldValue, formValues, onFieldValueChange, isEdit }) => (
  <Card>
    <h4>Ad Preferences</h4>
    <Row gutter={8} style={{ flexDirection: 'row' }}>
      <Col xs={24} sm={12}>
        <Form.Item label="Ad Type">
          <Select
            name="format.type"
            id="format.type"
            onChange={(value) => setFieldValue('format.type', value)}
            value={formValues.format.type}
            defaultValue="ad"
            disabled
            style={{ width: '100%' }}
          >
            <Select.Option value="ad">ad</Select.Option>
            <Select.Option value="announcement">announcement</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <SelectAdMinimumDisplayFormItem
          value={formValues.format?.minimumDisplay}
          adType={formValues.format?.media}
          onChange={(value) => setFieldValue('format.minimumDisplay', value)}
        />
      </Col>
    </Row>
    <Row gutter={8} style={{ flexDirection: 'row' }}>
      <Col xs={24} sm={18}>
        <SelectAdTemplateFormItem
          disabled={isEdit}
          value={formValues.format?.template}
          onChange={(value) => setFieldValue('format.template', value)}
        />
      </Col>
      <Col xs={24} sm={6}>
        <InputColorPickerFormItem
          name="format.backgroundColor"
          label="Background Color"
          onChange={onFieldValueChange}
          value={formValues.format?.backgroundColor ?? formValues.backgroundColor}
        />
      </Col>
    </Row>
    {formValues.format?.template === 1 && (
      <Row gutter={8} style={{ flexDirection: 'row' }}>
        <Col xs={24} sm={18}>
          <Form.Item label="Brand Text">
            <Input
              name="format.brand.text"
              id="brandText"
              onChange={(e) => setFieldValue('format.brand.text', e.target.value)}
              value={formValues.format?.brand?.text}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <InputColorPickerFormItem
            label="Brand Color"
            name="format.brand.color"
            onChange={onFieldValueChange}
            value={formValues.format?.brand?.color}
          />
        </Col>
      </Row>
    )}
    {formValues.format?.template <= 2 && (
      <>
        <Row gutter={8} style={{ flexDirection: 'row' }}>
          <Col xs={24} sm={18}>
            <Form.Item label="Title Text">
              <Input
                name="format.title.text"
                id="titleText"
                onChange={(e) => setFieldValue('format.title.text', e.target.value)}
                value={formValues.format?.title?.text}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <InputColorPickerFormItem
              label="Title Color"
              name="format.title.color"
              onChange={onFieldValueChange}
              value={formValues.format?.title?.color}
            />
          </Col>
        </Row>
        <Row gutter={8} style={{ flexDirection: 'row' }}>
          <Col xs={24} sm={18}>
            <Form.Item label="Description Text">
              <Input.TextArea
                rows={3}
                name="format.description.text"
                id="descriptionText"
                onChange={(e) => setFieldValue('format.description.text', e.target.value)}
                value={formValues.format?.description?.text}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <InputColorPickerFormItem
              label="Description Color"
              name="format.description.color"
              onChange={onFieldValueChange}
              value={formValues.format?.description?.color}
            />
          </Col>
        </Row>
        <Row gutter={8} style={{ flexDirection: 'row' }}>
          <Col xs={24} sm={12}>
            <Form.Item label="Button Text">
              <Input
                name="format.button.text"
                id="buttonText"
                onChange={onFieldValueChange}
                value={formValues.format?.button?.text}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <InputColorPickerFormItem
              label="Button Color"
              name="format.button.color"
              onChange={onFieldValueChange}
              value={formValues.format?.button?.color}
            />
          </Col>
          <Col xs={24} sm={6}>
            <InputColorPickerFormItem
              label="Button Background Color"
              name="format.button.backgroundColor"
              onChange={onFieldValueChange}
              value={formValues.format?.button?.backgroundColor}
            />
          </Col>
        </Row>
      </>
    )}
    <Row gutter={8} style={{ flexDirection: 'row' }}>
      <Col xs={24} sm={12}>
        <AntFormInputItem
          label="Link Url"
          name="format.linkUrl"
          value={formValues.format?.linkUrl}
          onChange={onFieldValueChange}
          maxLength={500}
        />
      </Col>
      {formValues.format?.template <= 2 && (
        <Col xs={24} sm={12}>
          <Form.Item label="Link Click">
            <Select
              name="format.linkAction"
              id="format.linkAction"
              onChange={(value) => setFieldValue('format.linkAction', value)}
              value={formValues.format.linkAction}
              style={{ width: '100%' }}
            >
              <Select.Option value="parent">Any</Select.Option>
              <Select.Option value="button">Button</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>
  </Card>
);
