import React from 'react';
import { Form, Row, Col } from 'antd';
import { ApiEndpoint } from '../../config';
import { putData, deleteData } from '../../api/helpers';
import { PreviewMedia } from '../Forms/PreviewMedia';
import { useWhyDidYouUpdate } from '../../hooks/useWhyDidYouUpdate';

export const CurrentMedia = React.memo(({ mediaFiles, setFieldValue }) => {
  useWhyDidYouUpdate('CurrentMedia', { mediaFiles, setFieldValue });
  return (
    <Form.Item label="Current Media">
      <Row style={{ margin: 0 }}>
        {mediaFiles.map((media) => (
          <Col xs={24} key={media.id} label="Current Image">
            <PreviewMedia
              onDeleteMedia={() => {
                deleteData(ApiEndpoint.AdAsset.Delete, media.id);
                const updatedMediaFiles = mediaFiles.filter((m) => m.id !== media.id);
                setFieldValue('mediaFiles', updatedMediaFiles);
              }}
              onChangeOrientation={(newValue) => {
                const assetBaseUrl = media.assetUrl.substring(0, media.assetUrl.indexOf('/', 10));
                putData(ApiEndpoint.AdAsset.Delete, media.id, {
                  ...media,
                  orientation: newValue,
                  assetPath: media.assetUrl.replace(assetBaseUrl, ''),
                });
                //
                const updatedMediaFiles = mediaFiles.map((el) => (el.id === media.id ? { ...el, orientation: newValue } : el));
                setFieldValue('mediaFiles', updatedMediaFiles);
              }}
              media={media}
            />
          </Col>
        ))}
      </Row>
    </Form.Item>
  );
});
