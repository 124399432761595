import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, CheckCircleFilled, DashOutlined } from '@ant-design/icons';

export const adGroupTableColumns = ({ onDelete, publisherData = [], groupTypeData = [] }) => [
  {
    title: 'Group Id',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Group Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: 'Publisher',
    dataIndex: 'publisher',
    key: 'publisher',
    filters: publisherData.map((publisherName) => ({
      text: publisherName,
      value: publisherName,
    })),
    onFilter: (value, record) => record.publisher.name === value,
    render: (publisher) => (
      <span>
        {publisher.name} ({publisher.countryCode})
      </span>
    ),
    sorter: (a, b) => a.publisher.name.length - b.publisher.name.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Placement',
    dataIndex: 'placement',
    key: 'placement',
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Group Type',
    dataIndex: 'adGroupType',
    key: 'adGroupType',
    filters: groupTypeData.map((groupType) => ({
      text: groupType.name,
      value: groupType.name,
    })),
    render: (text, record) => <span>{text && text.name}</span>,
    onFilter: (value, record) => record.adGroupType.name === value,
    sorter: (a, b) => {
      if (a.adGroupType.name > b.adGroupType.name) {
        return -1;
      }
      if (b.adGroupType.name > a.adGroupType.name) {
        return 1;
      }
      return 0;
    },
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Total Units',
    dataIndex: 'totalAdUnit',
    key: 'totalAdUnit',
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
    render: (value) => {
      if (value) return <CheckCircleFilled style={{ color: 'darkgreen' }} />;
      return <DashOutlined style={{ color: 'red' }} />;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <div>
        <Link to={`/setting/adgroup/${record.id}`} className="ant-btn ant-btn-primary ant-btn-circle">
          <EditOutlined />
        </Link>
        {/* <Popconfirm
          placement="top"
          title="Confirm Delete Group?"
          okButtonProps={{ size: "middle" }}
          cancelButtonProps={{ size: "middle" }}
          onConfirm={() => onDelete(record.id)}
          okText="Confirm Delete"
          cancelText="No"
        >
          <Button disabled={!IS_PRODUCTION} type="ghost" shape="circle" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm> */}
      </div>
    ),
  },
];
