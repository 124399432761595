import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PageHeader, Button, Descriptions, Table, Row, Col, Alert, Spin, Modal } from 'antd';
import { CloseOutlined, PlusOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getData, deleteData, putData } from '../../api/helpers';
import { ApiEndpoint } from '../../config';
import { useStore } from '../../context/AppContext';
import { lookupAdvertiser } from '../../context/lookup';
import { adListTableColumns } from '../Ads/AdListTableColumn';
import { DisplayError } from '../DisplayError';
import _ from 'lodash';

export const CampaignInfo = () => {
  const { advertiserId, campaignId } = useParams();
  const [adList, setAdList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [appState] = useStore();
  const navigate = useNavigate();

  const loadAdsListByCampaign = useCallback(async (campaignId) => {
    setIsLoading(true);
    const { data } = await getData(`${ApiEndpoint.Ads.List}&campaignId=${campaignId}`, setError);
    if (data && data.items && Array.isArray(data.items)) {
      console.debug(data.items);
      const result = [];
      data.items.forEach((ad) => {
        result.push({
          ...ad,
        });
      });
      setAdList(result);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const loadCampaignData = async (campaignId) => {
      setIsLoading(true);
      const { data } = await getData(`${ApiEndpoint.Campaign.Get}/${campaignId}`, setError);
      setCampaign(data);
      setIsLoading(false);
    };

    loadCampaignData(campaignId);
    loadAdsListByCampaign(campaignId);
  }, [advertiserId, campaignId, loadAdsListByCampaign]);

  if (isLoading) {
    return (
      <div className="container center">
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (_.isEmpty(campaign)) {
    return (
      <div className="container">
        <Alert type="error" message="Campaign not found!"></Alert>
      </div>
    );
  }

  // console.debug(campaign);

  const onDeleteAd = async (id) => {
    console.debug('Deleting Ad Id', id);
    deleteData(ApiEndpoint.Ads.Delete, id, null);
    // reload ads list
    setAdList(adList.filter((item) => item.id !== id));
  };

  // const onPublishAd = async (id) => {
  //   const { data, error } = await putData(
  //     ApiEndpoint.Ads.UpdateStatus,
  //     id,
  //     { status: "Publish" },
  //     null,
  //     "Update Ad Status Successful"
  //   );
  //   if (data && !error) {
  //     const updatedIndex = adList.findIndex((item) => item.id === id);
  //     adList[updatedIndex].status = "Publish";
  //     setAdList([...adList]);
  //   }
  // };

  const onUpdateAdStatus = async (id, status) => {
    const { data, error } = await putData(ApiEndpoint.Ads.UpdateStatus, id, { status: status }, null, 'Update Ad Status Successful');
    if (data && !error) {
      const updatedIndex = adList.findIndex((item) => item.id === id);
      adList[updatedIndex].status = status;
      setAdList([...adList]);
    }
  };

  // const getCampaignStatus = (startDate, endDate) => {
  //  if (moment(startDate) >= moment.now()) return <Tag color="processing">Upcoming</Tag>;
  //
  //  if (moment(endDate) <= moment.now()) return <Tag color="blue">Finished</Tag>;
  //
  //  if (moment(campaign.startDate) <= moment.now() && moment(campaign.endDate) >= moment.now()) return <Tag color="green">Active</Tag>;
  // };

  return (
    <div className="container">
      <PageHeader
        ghost={false}
        title={campaign.name}
        subTitle={campaign.type}
        extra={[
          <Button key="action:edit" type="primary" style={{ marginRight: 8 }} onClick={() => navigate(`/campaign/${campaign.id}/edit`)}>
            <EditOutlined />
            Edit Campaign
          </Button>,
          <Button
            key="action:delete"
            type="ghost"
            danger
            style={{ marginRight: -12 }}
            onClick={() =>
              Modal.confirm({
                title: 'Confirm delete this campaign?',
                icon: <ExclamationCircleFilled />,
                content: campaign.name,
                onOk() {
                  deleteData(ApiEndpoint.Campaign.Delete, campaign.id, null);
                },
                onCancel() {
                  return;
                },
              })
            }
          >
            <CloseOutlined />
            Delete
          </Button>,
        ]}
      >
        <Descriptions size="small" column={1}>
          <Descriptions.Item label="Description">{campaign.description}</Descriptions.Item>
          <Descriptions.Item label="Advertiser">
            <Link to={`/advertiser/${campaign.advertiser.id}/campaign`}>
              {!_.isEmpty(campaign.advertiser) ? campaign.advertiser.name : lookupAdvertiser(appState, campaign.advertiserId).name}
            </Link>
          </Descriptions.Item>
          {/* <Descriptions.Item label="Campaign Type">
            <Text strong>{campaign.pricingModel}</Text>
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Status">
            {getCampaignStatus(campaign.startAt, campaign.endAt)}
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Start Date">
            {moment(campaign.startDate).format("LLLL")}
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            {moment(campaign.endDate).format("LLLL")}
          </Descriptions.Item> */}
          {/* {campaign.pricingModel.trimEnd() === "IMP" && (
            <Descriptions.Item label="Impression Quota">
              <Text strong>{campaign.quota?.toLocaleString()}</Text>
            </Descriptions.Item>
          )}
          {campaign.pricingModel.trimEnd() === "CPM" && (
            <Descriptions.Item label="Campaign Budget">
              <Text strong>{campaign.budget?.toLocaleString()}</Text>
            </Descriptions.Item>
          )} */}
          {/* <Descriptions.Item label="Limit Campaign Impression">
            <Text strong>{campaign.limitViewTotal?.toLocaleString()}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Limit Campaign View per User">
            <Text strong>{campaign.limitViewPerPerson?.toLocaleString()}</Text>
          </Descriptions.Item> */}
        </Descriptions>
      </PageHeader>
      <Row style={{ marginBottom: 12, marginTop: 12 }}>
        <Col flex="auto" className="align-right">
          <Button
            style={{ marginRight: 12 }}
            key="action:addbanner"
            onClick={() => navigate('/ads/create?campaignId=' + campaign.id)}
            type="primary"
          >
            <PlusOutlined /> Create New Ad
          </Button>
        </Col>
      </Row>
      <DisplayError error={error} />
      <Table
        dataSource={adList}
        size="small"
        rowKey="id"
        emptyText="No Ad created for this campaign"
        columns={adListTableColumns({
          onDelete: onDeleteAd,
          onUpdateAdStatus: onUpdateAdStatus,
        })}
        pagination={false}
        bordered
      />
    </div>
  );
};
