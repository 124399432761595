import React, { useReducer } from 'react';

// export const AppContext = React.createContext(initialAppState); // Create a context object
export const AppContext = React.createContext(); // Create an application context object
AppContext.displayName = 'Application Context';

export const useStore = () => React.useContext(AppContext);

export const AppContextProvider = ({ children, initialState, reducer }) => {
  const [appState, dispatch] = useReducer(reducer, initialState);
  //   const store = React.useMemo(() => ({ ...appState, dispatch }), [appState]);
  //   console.debug(store);
  return <AppContext.Provider value={[appState, dispatch]}>{children}</AppContext.Provider>;
};
