import React from 'react';
import { Button } from 'antd';
import moment from 'moment';

export const SetLastMonthButton = React.memo(({ setFilter, filter }) => (
  <Button
    type="link"
    size="small"
    onClick={() =>
      setFilter({
        ...filter,
        periods: [
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        ],
      })
    }
  >
    Last Month
  </Button>
));
