import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { getData } from '../../api/helpers';

const columns = [
  {
    title: 'Role Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Link to={`/role/${record.id}`}>{text}</Link>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  // {
  //   title: "Permission",
  //   dataIndex: "role",
  //   key: "role",
  //   render: (text, record) => {
  //     const role = sampleRolesData.find((role) => role.name === record.name);
  //     console.debug(record.name);
  //     console.debug(role);
  //     return (
  //       <div>
  //         <span>
  //           {keys(role)
  //             .filter((r) => r !== "name")
  //             .map((k) => (
  //               <div>
  //                 {k}:
  //                 <span>
  //                   {keys(role[k]).map((k2) => (
  //                     <Tag color="green">{k2}</Tag>
  //                   ))}
  //                 </span>
  //               </div>
  //             ))}
  //         </span>
  //       </div>
  //     );
  //   },
  // },
];

export const Roles = () => {
  const [error, setError] = useState(null);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const loadRoleList = async () => {
      const { data } = await getData(ApiEndpoint.UserRole.List, setError);
      setUserRoles(data.items);
    };

    loadRoleList();
  }, []);

  if (error) {
    return (
      <div className="container">
        <Alert type="error">{error}</Alert>
      </div>
    );
  }
  return (
    <div className="container">
      <Row>
        <Col xs={24}>
          <h3>Roles</h3>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={24}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to="/role/create">
              <PlusOutlined style={{ marginRight: 5 }} />
              New Role
            </Link>
          </div>
        </Col>
      </Row>
      <Table rowKey="id" columns={columns} dataSource={userRoles} pagination={false} />
    </div>
  );
};
