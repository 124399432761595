import { ApiEndpoint } from '../../config';
import { AdPosition } from '../../config/ad';
import { postData, putData, fileReader, getData } from '../../api/helpers';
import Axios from 'axios';
import moment from 'moment';

async function uploadMedia({
  file,
  mediaName,
  orientation,
  // fileExtension = ".jpeg",
  position = AdPosition.Default,
  adId,
  size,
  width,
  height,
}) {
  console.debug('Uploading...', position, file);
  // upload file
  if (file === null || file === undefined || file.type === undefined || file.type === null) return;
  // Step1. Create media file (asset)
  const { data } = await postData(
    ApiEndpoint.AdAsset.Create,
    {
      adId: adId,
      name: 'ad banner ' + moment().format('hh:mm:ss'),
      mimeType: file.type,
      assetType: file.type.indexOf('image') >= 0 ? 'Image' : 'Video',
      description: 'media file for ad: ' + mediaName,
      position,
      orientation,
      size,
      width,
      height,
    },
    null,
    '-',
  );
  const { data: mediaFileId } = data;
  const fileExtension = '.' + file.name.split('.').pop();

  // Step2. Request for a signed-url
  const { data: signedUrl } = await getData(`${ApiEndpoint.AdAsset.Create}/${mediaFileId}/upload/url?extension=${fileExtension}`, {
    fileExtension: fileExtension,
  });
  // console.debug(signedUrl);

  // Step3. เอา signedUrl ไปอัพโหลดไฟล์
  const fileContent = await fileReader(file);
  // console.debug("signedUrl", signedUrl);
  const uploadResponse = await Axios.put(signedUrl, fileContent, {
    headers: {
      'Content-Type': file.type,
    },
  });
  // console.debug(uploadResponse);
  // Step4. เมื่ออัพโหลดไฟล์สำเร็จแล้ว ให้ยิงไปบอก api ให้ map mediaFileId กับ uploadUrlId
  if (uploadResponse.status === 200) {
    // Create mapped signedId with mediaId
    await putData(`${ApiEndpoint.AdAsset.Create}/${mediaFileId}/upload/commit`, '', {}, null, 'Uploaded Media File Successfuly');
    return mediaFileId;
  }
}

export default uploadMedia;
