import React from 'react';
import { Form, Select } from 'antd';
import { AdPosition } from '../../config/ad';
import { useWhyDidYouUpdate } from '../../hooks/useWhyDidYouUpdate';

export const UploadVideo = React.memo(({ mediaType, mediaFiles, handleFileChange, setFieldValue }) => {
  useWhyDidYouUpdate('UploadVideo', { mediaType, mediaFiles, handleFileChange, setFieldValue });

  if (mediaType !== 'video') return null;
  if (!Array.isArray(mediaFiles)) return null;
  if (
    mediaFiles.findIndex(
      (media) => media.assetType.toLowerCase() === 'video' && media.position.toLowerCase() === AdPosition.Center.toLowerCase(),
    ) >= 0
  )
    return null;

  return (
    <Form.Item label="Upload Video">
      <input
        id={AdPosition.Video}
        name={AdPosition.Video}
        style={{ display: 'inline-block' }}
        type="file"
        accept="video/*"
        onChange={(event) => {
          handleFileChange(event, AdPosition.Video, 'video');
        }}
      />
      <Select style={{ width: 150 }} size="small" onChange={(value) => setFieldValue('videoOrientation', value)} defaultValue="portrait">
        <Select.Option value="portrait">Portrait</Select.Option>
        <Select.Option value="landscape">Landscape</Select.Option>
      </Select>
    </Form.Item>
  );
});
