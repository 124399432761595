/* eslint-disable no-case-declarations */
import { CMS_ACTION } from './actionsType';

export function reducer(currentState, action) {
  switch (action.type) {
    case CMS_ACTION.ADVERTISER.SET:
      return {
        ...currentState,
        advertisers: action.payload,
      };
    case CMS_ACTION.PUBLISHER.SET:
      return {
        ...currentState,
        publishers: action.payload,
      };
    case CMS_ACTION.PRICINGMODEL.SET:
      return {
        ...currentState,
        pricingModel: action.payload,
      };
    case CMS_ACTION.GROUPTYPE.SET:
      return {
        ...currentState,
        groupTypes: action.payload,
      };

    case CMS_ACTION.AUTH.SET_ACCESS_TOKEN:
      localStorage.setItem('accessToken', action.payload);
      return {
        ...currentState,
        user: {
          ...currentState.user,
          accessToken: action.payload,
        },
      };

    case CMS_ACTION.AUTH.SET_AUTH_DATA:
      const { accessToken, accessTokenExpiresDate, refreshToken, displayName, ookbeeNumericId, roleName, ...additionalData } =
        action.payload;

      // Persist into localStorage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiresDate', accessTokenExpiresDate);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('ookbeeNumericId', ookbeeNumericId);
      localStorage.setItem('roleName', roleName);
      localStorage.setItem('displayName', displayName); // Display Name is configure by Ads CMS

      return {
        ...currentState,
        user: {
          accessToken: accessToken,
          accessTokenExpiresDate: accessTokenExpiresDate,
          refreshToken: refreshToken,
          displayName: displayName,
          ookbeeNumericId: ookbeeNumericId,
          roleName: roleName,
          ...additionalData,
        },
        error: null,
        loading: false,
      };

    case CMS_ACTION.AUTH.SET_USER_PROFILE:
      const { emailAddress, firstName, lastName, ...additionalProfile } = action.payload;
      return {
        ...currentState,
        user: {
          ...currentState.user,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          ...additionalProfile,
        },
        error: null,
        loading: false,
      };
    case CMS_ACTION.AUTH.LOGOUT:
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenExpiresDate');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('ookbeeNumericId');
      localStorage.removeItem('roleName');
      return {
        ...currentState,
        user: {
          accessToken: null,
          accessTokenExpiresDate: null,
          refreshToken: null,
          ookbeeNumericId: null,
          emailAddress: null,
          firstName: null,
          lastName: null,
          roleName: null,
          roleId: null,
          displayName: null,
        },
      };

    case CMS_ACTION.FETCH_START:
      return {
        ...currentState,
        error: null,
        loading: true,
      };

    case CMS_ACTION.FETCH_SUCCESS:
      return {
        ...currentState,
        error: null,
        loading: false,
      };

    case CMS_ACTION.FETCH_ERROR:
      return {
        ...currentState,
        error: action.payload,
        loading: false,
      };

    case CMS_ACTION.SET_MESSAGE: {
      return {
        ...currentState,
        message: action.payload,
      };
    }

    case CMS_ACTION.CLEAR_MESSAGE: {
      return {
        ...currentState,
        message: null,
      };
    }

    case CMS_ACTION.CLEAR_ERROR: {
      return {
        ...currentState,
        error: null,
      };
    }

    default:
      throw new Error('No matching action type!', action.type);
  }
}
