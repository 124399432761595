import React, { useCallback, useEffect, useState } from 'react';
import { withFormik } from 'formik';
import { Form, Input, Button, Checkbox, Row, Col, Select, InputNumber, DatePicker, Card, message } from 'antd';
import { IS_PRODUCTION, ApiEndpoint, platformList } from '../../config';
import { AdGroupType, AdPosition, BannerSize } from '../../config/ad';
import { getData, getUserSummaryData, postData, putData } from '../../api/helpers';
import { AdPreferences } from './components/AdPreferences';
import { AntFormInputItem } from '../Forms/AntFormInputItem';
import { AntFormMultipleInputItem } from '../Forms/AntFormMultipleInputItem';
import { CurrentMedia } from './CurrentMedia';
import { DisplayError } from '../DisplayError';
import { InputColorPickerFormItem } from './components/InputColorPickerFormItem';
import { PreviewInterstitial } from './PreviewInterstitial';
import { SelectAdUnitFormItem } from '../Forms/SelectAdUnit';
import { SelectAdvertiser } from '../Forms/SelectAdvertiser';
import { SelectCampaignFormItem } from '../Forms/SelectCampaign';
import { SelectPublisher } from '../Forms/SelectPublisher';
import { sortAdUnitByCountryCode } from './utility/sortAdUnitByCountryCode';
import { UploadLogoImage } from './UploadLogoImage';
import { UploadVideo } from './UploadVideo';
import { useRouter } from '../../hooks/useRouter';
import { useStore } from '../../context/AppContext';
import { validateAdForm } from './validateAdForm';
import uploadMedia from './uploadMedia';
import moment from 'moment';
import _ from 'lodash';

// const ageMarkers = {
//   15: "15",
//   20: "20",
//   25: "25",
//   30: "30",
//   35: "35",
//   40: "40",
//   45: "45",
// };

export const AdForm = ({
  values: formValues,
  touched,
  dirty,
  errors,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  setSubmitting,
  setValues,
  isSubmitting,
  percentCompleted,
  updateSuccess,
}) => {
  const [campaigns, setCampaigns] = useState([]);
  const [previewImages, setPreviewsImages] = useState([]);
  const [adUnit, setAdUnit] = useState([]);
  const [userAge, setUserAge] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [mobileBrandData, setMobileBrandData] = useState([]);
  const [mobileNetworkData, setMobileNetworkData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [totalAgeSummary, setTotalAgeSummary] = useState(0);
  const [filteredAdUnit, setFilteredAdUnit] = useState([]);
  const [error, setError] = useState(null);
  const [showUserTarget, setShowUserTarget] = useState(false);
  const [showUserTargetSmallAmount, setShowUserTargetSmallAmount] = useState(false);
  const [appState] = useStore();
  const { query } = useRouter();
  const { adId, campaignId } = query;
  const isEdit = adId !== undefined; // formValues.formType === "edit"; // adsId !== undefined;

  const loadCampaignList = useCallback(
    async (advertiserId = 0) => {
      console.debug('Load campaign list...');
      const { data } = advertiserId
        ? await getData(ApiEndpoint.Campaign.List + '&advertiserId=' + advertiserId, setError)
        : await getData(ApiEndpoint.Campaign.List, setError);
      if (data && data.items) {
        setCampaigns(data.items);

        if (advertiserId) setFieldValue('advertiserId', advertiserId);
        if (campaignId) {
          const _campaign = data.items.find((campaign) => campaign.id === Number(campaignId));

          if (_campaign) {
            setFieldValue('campaignId', Number(campaignId));
            setFieldValue('advertiserId', _campaign.advertiser.id);
          } else {
            setFieldValue('campaignId', null);
          }
        }
      }
    },
    [campaignId, setFieldValue],
  );

  useEffect(() => {
    async function loadAdUnit() {
      console.debug('loadAdUnit inside useEffect');
      const { data } = await getData(ApiEndpoint.AdUnit.List, setError);
      if (data && data.items) {
        setAdUnit(data.items);

        const _filteredOokbeeAdUnit = data.items
          .filter((adUnit) => adUnit.adNetwork.name === 'ookbee')
          .sort((a, b) => sortAdUnitByCountryCode(a, b));
        setFilteredAdUnit(_filteredOokbeeAdUnit);
        return data.items;
      }
    }

    loadAdUnit();
  }, []);

  useEffect(() => {
    async function loadUserAge() {
      console.debug('loadUserAge inside useEffect');
      const data = await getUserSummaryData('/age', setError);
      console.debug(data);
      if (data) {
        setUserAge(data);
        return data;
      }
    }

    async function loadUserGender() {
      console.debug('loadUserGender inside useEffect');
      const data = await getUserSummaryData('/gender', setError);
      console.debug(data);
      if (data) {
        setGenderData(data);
        return data;
      }
    }

    async function loadMobileBrand() {
      console.debug('loadMobileBrand inside useEffect');
      const mobileBrandData = await getUserSummaryData('/mobile-brand', setError);
      const sortedData = _.orderBy(mobileBrandData, ['total'], ['desc']);
      console.debug(sortedData);
      if (mobileBrandData) {
        setMobileBrandData(showUserTargetSmallAmount ? sortedData : sortedData.filter((i) => i.total >= 5));
        return mobileBrandData;
      }
    }

    async function loadMobileNetwork() {
      console.debug('loadMobileNetwork inside useEffect');
      const mobileNetworkData = await getUserSummaryData('/mobile-network', setError);
      console.debug(mobileNetworkData);
      if (mobileNetworkData) {
        setMobileNetworkData(showUserTargetSmallAmount ? mobileNetworkData : mobileNetworkData.filter((i) => i.total >= 5));
        return mobileNetworkData;
      }
    }

    async function loadRegion() {
      console.debug('loadRegion inside useEffect');
      const regionData = await getUserSummaryData('/region', setError);
      console.debug({ regionData });
      if (regionData) {
        setRegionData(showUserTargetSmallAmount ? regionData : regionData.filter((i) => i.total >= 5));
        return regionData;
      }
    }

    console.debug('useEffect() [showUserTargetSmallAmount]');
    loadUserAge();
    loadUserGender();
    loadMobileBrand();
    loadMobileNetwork();
    loadRegion();
  }, [showUserTargetSmallAmount]);

  useEffect(() => {
    async function loadAds(adsId) {
      const { data: adsValues } = await getData(ApiEndpoint.Ads.Get + '/' + adsId, setError);
      setValues(
        {
          // optionals: {
          //   countdown: 5,
          //   targetClick: adsValues.target?.click,
          //   targetReach: adsValues.target?.reach,
          //   ...adsValues.format,
          // },
          ...adsValues,
          format: {
            ...adsValues.format,
            button: _.isEmpty(adsValues.format?.button) ? null : adsValues.format.button,
            brand: _.isEmpty(adsValues.format?.brand) ? null : adsValues.format.brand,
            title: _.isEmpty(adsValues.format?.title) ? null : adsValues.format.title,
            description: _.isEmpty(adsValues.format?.description) ? null : adsValues.format.description,
          },
          // frequencyCapImpression:
          //   adsValues.frequencyCap || adsValues.frequencyCap?.impression === null ? adsValues.frequencyCap.impression : null,
          // frequencyCapTimePeriod:
          //   adsValues.frequencyCap || adsValues.frequencyCap?.impression === null ? adsValues.frequencyCap.timePeriod : "Day",
          mediaFiles: [],
        },
        false,
      ); // Load values into formik state

      if (!adsValues.adUnit.id) {
        setError('No AdUnit property in Ad data!!');
        throw new Error('No AdUnitId property in Ad data!!');
      }
      if (!adsValues.campaign.id) {
        setError('No campaign property in Ad data!!');
        throw new Error('No campaign property in Ad data!!');
      }

      // เอาค่าที่อ่านได้ ไปเซ็นใน selected value (พวก dropdown select)
      await loadCampaignList(adsValues.campaign.advertiser.id);
      setFieldValue('adUnitId', adsValues.adUnit.id);
      setFieldValue('advertiserId', adsValues.campaign.advertiser.id);
      setFieldValue('campaignId', adsValues.campaign.id);

      // Get media file list
      const { data: mediaFileList } = await getData(ApiEndpoint.AdAsset.List + '&adId=' + adsId, setError);
      if (mediaFileList && mediaFileList.items) {
        setFieldValue('mediaFiles', mediaFileList.items);
      }

      setShowUserTarget(adsValues.target.audience);
    }

    if (isEdit && appState.advertisers.length > 0) {
      loadAds(adId);
    }
  }, [adId, isEdit, appState.advertisers, loadCampaignList, setValues, setFieldValue]);

  useEffect(() => {
    if (formValues.format?.template === 2) {
      setValues({
        ...formValues,
        format: {
          ...formValues.format,
          brand: null,
        },
      });
    }

    if (formValues.format?.template === 3) {
      setValues({
        ...formValues,
        format: {
          ...formValues.format,
          brand: null,
          title: null,
          description: null,
        },
      });
    }
  }, [formValues.format?.template]);

  useEffect(() => {
    const total = userAge.reduce(function (previousValue, currentValue) {
      if (!formValues.target?.audience?.age) return previousValue + currentValue.total;
      if (currentValue.age >= formValues.target.audience.age.min && currentValue.age <= formValues.target.audience.age.max) {
        return previousValue + currentValue.total;
      } else return previousValue;
    }, 0);
    // console.debug("total age value=", total);
    setTotalAgeSummary(total);
  }, [formValues.target?.audience?.age, userAge]);

  const onFieldValueChange = useCallback(
    (event) => {
      // console.debug(event.target.value);
      // console.debug(event.target.name ?? event.target.id);
      // console.debug(typeof event);
      // General input change event handler. This will update the values[key] in the formik stage
      const isEmptyArray = Array.isArray(event.target.value) && _.isEmpty(event.target.value);
      const convertedValue = isEmptyArray ? undefined : event.target.value;
      console.debug(isEmptyArray, convertedValue);
      setFieldValue(event.target.name ? event.target.name : event.target.id, convertedValue);
    },
    [setFieldValue],
  );

  const onMediaTypeChange = useCallback(
    (value) => {
      if (value === 'image') {
        // check if current minimumDisplay value is -1? then set to 0
        if (formValues.format?.minimumDisplay === -1) {
          setFieldValue('format.minimumDisplay', 0);
        }
      }
      setFieldValue('format.media', value);
    },
    [setFieldValue, formValues.format?.minimumDisplay],
  );

  const onEnabledUserTargetChange = (e) => {
    console.debug(e.target.checked);
    setShowUserTarget(e.target.checked);
    if (!e.target.checked) {
      setFieldValue('target.audience', null);
    }
  };

  const onShowSmallAmount = (e) => {
    console.debug(e.target.checked);
    setShowUserTargetSmallAmount(e.target.checked);
  };

  const onCheckAIS = (e) => {
    if (e.target.checked) {
      const AISList = mobileNetworkData.filter((i) => i.mobileNetwork.toLowerCase().indexOf('ais') >= 0).map((i) => i.mobileNetwork);
      console.debug({ AISList });
      console.debug(formValues.target?.audience?.mobileNetworks);
      const mergedList = _.union(formValues.target?.audience?.mobileNetworks, AISList);
      onFieldValueChange({ target: { name: 'target.audience.mobileNetworks', value: mergedList } });
    } else {
      const removedList = formValues.target?.audience?.mobileNetworks.filter((i) => i.toLowerCase().indexOf('ais') < 0);
      onFieldValueChange({ target: { name: 'target.audience.mobileNetworks', value: removedList } });
    }
  };

  const onSelectPublisherChange = useCallback(
    async (publisherId) => {
      let filteredAdUnit = adUnit.filter((adUnit) => adUnit.adGroup.publisher.id === publisherId && adUnit.adNetwork.name === 'ookbee');
      setFilteredAdUnit(filteredAdUnit);
      setFieldValue('publisherId', publisherId);
      setFieldValue('adUnit', null);
      setFieldValue('adUnitId', null);
    },
    [setFieldValue, setFilteredAdUnit, adUnit],
  );

  const onAdUnitChange = useCallback(
    (value) => {
      const _selectedAdUnit = filteredAdUnit.find((adUnit) => adUnit.id === value);

      // console.debug("_selectedAdUnit", value, _selectedAdUnit);
      setValues({
        ...formValues,
        adUnit: _selectedAdUnit,
        adUnitId: value,
        Center: undefined,
        Left: undefined,
        Right: undefined,
      });
      // setFieldValue("adUnit", _selectedAdUnit); // Use for form
      // setFieldValue("adUnitId", value); // Use for sending value to API
    },
    [setValues, formValues, filteredAdUnit],
  );

  const handleFileChange = useCallback(
    (event, position, type = 'image', bannerSize = null) => {
      const selectedAdGroupType = formValues.adUnit?.adGroup?.adGroupType?.name?.toLowerCase();
      const fileContent = event.currentTarget.files[0];

      if (type === 'video') {
        setFieldValue(position, fileContent);
      }

      if (type === 'image') {
        // ทำ Preview Image (ไม่เกี่ยวกับการอัพโหลดรูป)
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        // previewImages.push(objectUrl);
        setPreviewsImages([...previewImages, objectUrl]);

        // event.target.value = null;
        // event.currentTarget.value = null;

        let positionName = position;
        if (position === AdPosition.Center || position === 'center') {
          const img = new Image();

          // Onload will be call as an Event, So it cannot reference to event object
          img.onload = function () {
            if (selectedAdGroupType === 'mRec' && this.width !== 600 && this.height !== 500) {
              alert("Banner should have dimension 600x500 pixel (or 300x250), but you've uploaded " + this.width + 'x' + this.height);
            }

            if (selectedAdGroupType === 'banner') {
              // Calculate uploaded ratio
              const uploadedRatio = Math.round((this.width / this.height) * 100) / 10;
              const standardRatio = BannerSize[bannerSize].ratio;

              // if (this.width !== BannerSize[bannerSize].width || this.width !== BannerSize[bannerSize].width * 2) {
              // Do something
              // }
              if (uploadedRatio !== standardRatio) {
                // alert("You've upload a wrong image dimension for this banner! Please select a new banner Image");
                message.error("You've upload a wrong image dimension for this banner! Please select a new banner Image");
                this.refEventTarget.value = null; // Clear input upload file via the ref event target
                return;
              } else {
                positionName = `${position}.${bannerSize}`;
                setFieldValue(positionName, {
                  size: bannerSize,
                  width: this.width,
                  height: this.height,
                  fileContent: fileContent,
                });
              }
            } else {
              setFieldValue(position, fileContent);
            }

            URL.revokeObjectURL(objectUrl); // Clear from memory
          };

          img.refEventTarget = event.target;
          img.src = objectUrl;
        } else {
          // Other position eg. Left, ActionImage, Right
          setFieldValue(position, fileContent);
        }
      }
    },
    [setFieldValue, previewImages, formValues.adUnit?.adGroup?.adGroupType?.name],
  );

  const mediaFiles = formValues.mediaFiles;
  return (
    <div className="container" style={{ maxWidth: 'unset' }}>
      {adId === undefined && <h3>Create New Ad</h3>}
      {adId !== undefined && <h3>Edit Ad</h3>}
      <Row>
        <Col xs={24} lg={16}>
          <div className="container" style={{ margin: 0, padding: 0, maxWidth: 'unset' }}>
            <Form
              layout="vertical"
              labelAlign="left"
              name="control-hooks"
              onFinish={handleSubmit}
              style={{ backgroundColor: 'unset', borderRadius: 0, padding: 0, boxShadow: 'none' }}
            >
              <Card>
                <h4>Ad Property</h4>
                <Form.Item help={errors.name} label="Ad Name" hasFeedback={false} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input
                    type="text"
                    name="name" // must specify id to work with formik
                    maxLength={40}
                    value={formValues.name}
                    onChange={onFieldValueChange}
                    onBlur={handleBlur}
                  />
                </Form.Item>
                <AntFormInputItem
                  required
                  label="Ad Description"
                  name="description"
                  maxLength={400}
                  help={errors.description}
                  error={!!errors.description}
                  value={formValues.description}
                  onChange={onFieldValueChange}
                />
                <Row gutter={8} style={{ flexDirection: 'row' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="advertiserId"
                      label="Advertiser"
                      hasFeedback={false}
                      help={errors.advertiserId}
                      validateStatus={touched.advertiserId && errors.advertiserId ? 'error' : 'success'}
                      shouldUpdate={false}
                    >
                      <SelectAdvertiser
                        controlId="advertiserId"
                        width="100%"
                        allowChange={true}
                        selectedValue={formValues.advertiserId}
                        showAllOption={false}
                        disabled={isEdit}
                        onChange={(e) => loadCampaignList(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <SelectCampaignFormItem
                      name="campaignId"
                      data={campaigns}
                      disabled={isEdit}
                      value={formValues.campaignId}
                      onChange={onFieldValueChange}
                      error={errors.campaignId}
                    />
                  </Col>
                </Row>
              </Card>
              <Card>
                <h4>Placement & Visibility</h4>
                <Row gutter={8} style={{ flexDirection: 'row' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Publisher">
                      <SelectPublisher
                        style={{ width: '100%' }}
                        allowChange={!isEdit}
                        value={formValues.adUnit?.adGroup?.publisher?.id ?? formValues.publisherId}
                        onChange={onSelectPublisherChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <SelectAdUnitFormItem
                      disabled={isEdit}
                      data={filteredAdUnit}
                      useAdGroupName={true}
                      value={formValues.adUnitId}
                      error={errors.adUnitId}
                      onChange={onAdUnitChange}
                    />
                  </Col>
                </Row>
                <Row gutter={8} style={{ flexDirection: 'row' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Start Date"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          message: 'Please specify start date and time!',
                        },
                      ]}
                      getValueProps={(value) => moment(value)}
                      help={touched.startAt && errors.startAt ? errors.startAt : null}
                      validateStatus={errors.startAt ? 'error' : 'success'}
                    >
                      <DatePicker
                        onChange={(value) => setFieldValue('startAt', value ? moment(value).utcOffset('+0700') : null)}
                        value={formValues.startAt ? moment(formValues.startAt) : null}
                        style={{ width: '100%' }}
                        mode="date"
                        disabled={isEdit}
                        showTime
                        allowClear={true}
                        disabledDate={(current) => {
                          return current && current < moment().startOf('day');
                        }}
                        format="YYYY-MM-DD HH:mm"
                      />
                      <Button type="link" size="small" onClick={() => setFieldValue('startAt', moment().startOf('day').utcOffset('+0700'))}>
                        Now
                      </Button>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => setFieldValue('startAt', moment().startOf('day').add(1, 'days').utcOffset('+0700'))}
                      >
                        Tomorrow
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="End Date"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          message: 'Please specify end date and time!',
                        },
                      ]}
                      help={touched.endAt && errors.endAt ? errors.endAt : null}
                      validateStatus={errors.endAt ? 'error' : 'success'}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        onChange={(value) => setFieldValue('endAt', value ? moment(value).utcOffset('+0700') : null)}
                        value={formValues.endAt ? moment(formValues.endAt) : null}
                        disabledDate={(current) => {
                          return (
                            (current && current < moment().startOf('day')) ||
                            (current && current < moment.utc(formValues.startAt).startOf('day'))
                          );
                        }}
                        mode="date"
                        allowClear={true}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                      />
                      <Button
                        type="link"
                        size="small"
                        onClick={() => setFieldValue('endAt', moment().startOf('day').add(7, 'days').utcOffset('+0700'))}
                      >
                        1 Week from Now
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Platforms" style={{ marginBottom: 4 }}>
                  <Checkbox.Group
                    name="platforms"
                    options={platformList}
                    value={formValues.platforms}
                    onChange={(value) => onFieldValueChange({ target: { name: 'platforms', value: value } })}
                  ></Checkbox.Group>
                </Form.Item>
              </Card>
              <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Checkbox onChange={onEnabledUserTargetChange} checked={showUserTarget}>
                    <h4>User Targeting</h4>
                  </Checkbox>
                  {showUserTarget && (
                    <Checkbox onChange={onShowSmallAmount} checked={showUserTargetSmallAmount}>
                      Incl. small amount
                    </Checkbox>
                  )}
                </div>
                {showUserTarget && (
                  <Row gutter={8} style={{ flexDirection: 'row' }}>
                    <Col xs={24} sm={24}>
                      {/* <Form.Item
                        label="Gender"
                        rules={[
                          {
                            required: true,
                            message: "Please specify gender",
                          },
                        ]}
                      >
                        <Select
                          value={formValues.target.audience?.gender ?? null}
                          dropdownMatchSelectWidth={false}
                          showSearch={false}
                          placeholder={"Select Gender"}
                          onChange={(v) => onFieldValueChange({ target: { name: "target.audience.gender", value: v ?? null } })}
                        >
                          <Select.Option key={0} value={null}>
                            All gender
                          </Select.Option>
                          {genderData.map((gender) => (
                            <Select.Option key={gender.gender} value={gender.gender}>
                              {gender.gender} ({gender.total})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                      {/* <Form.Item
                        label={`Age range (${totalAgeSummary})`}
                        rules={[
                          {
                            required: false,
                            message: "Please specify age range",
                          },
                        ]}
                      >
                        <Slider
                          range
                          defaultValue={[0, 60]}
                          min={0}
                          max={60}
                          step={1}
                          value={[formValues.target.audience?.age?.min ?? 0, formValues.target.audience?.age?.max ?? 60]}
                          onChange={(value) =>
                            onFieldValueChange({ target: { name: "target.audience.age", value: { min: value[0], max: value[1] } } })
                          }
                        />
                      </Form.Item> */}
                      <Form.Item
                        label="Mobile Network"
                        rules={[
                          {
                            required: false,
                            message: 'Please specify Mobile',
                          },
                        ]}
                      >
                        {/* <Checkbox.Group
                          name="target.audience.mobileNetworks"
                          options={mobileNetworkData.map((o) => ({ label: `${o.mobileNetwork} (${o.total})`, value: o.mobileNetwork }))}
                          value={formValues.target.audience?.mobileNetworks}
                          onChange={(value) => onFieldValueChange({ target: { name: "target.audience.mobileNetworks", value: value } })}
                        ></Checkbox.Group> */}
                        <Checkbox onChange={onCheckAIS}>AIS</Checkbox>
                        <Checkbox>TRUE</Checkbox>
                        <Checkbox>DTAC</Checkbox>
                        <Select
                          mode="tags"
                          placeholder="Please select mobile networks"
                          onChange={(value) => onFieldValueChange({ target: { name: 'target.audience.mobileNetworks', value: value } })}
                          value={formValues.target?.audience?.mobileNetworks}
                          style={{ width: '100%' }}
                        >
                          {mobileNetworkData.map((o) => (
                            <Select.Option key={o.mobileNetwork}>
                              {o.mobileNetwork} ({o.total})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Mobile Brand"
                        rules={[
                          {
                            required: false,
                            message: 'Please specify brands',
                          },
                        ]}
                        // help={touched.Gender && errors.quota ? errors.quota : null}
                        // validateStatus={errors.quota ? "error" : "success"}
                      >
                        {/* <Checkbox.Group
                          name="target.audience.mobileBrands"
                          options={mobileBrandData.map((o) => ({ label: `${o.mobileBrand} (${o.total})`, value: o.mobileBrand }))}
                          value={formValues.target.audience?.mobileBrands}
                          onChange={(value) => onFieldValueChange({ target: { name: "target.audience.mobileBrands", value: value } })}
                        ></Checkbox.Group> */}
                        <Select
                          mode="tags"
                          name="target.audience.mobileBrands"
                          placeholder="Please select mobile brands"
                          onChange={(value) => onFieldValueChange({ target: { name: 'target.audience.mobileBrands', value: value } })}
                          value={formValues.target?.audience?.mobileBrands}
                          style={{ width: '100%' }}
                        >
                          {mobileBrandData.map((o) => (
                            <Select.Option key={o.mobileBrand}>
                              {o.mobileBrand} ({o.total})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Regions"
                        rules={[
                          {
                            required: false,
                            message: 'Please specify region',
                          },
                        ]}
                      >
                        {/* <Checkbox.Group
                          name="target.audience.regions"
                          options={regionData.map((o) => ({ label: `${o.region} (${o.total})`, value: o.region }))}
                          value={formValues.target.audience?.regions}
                          onChange={(value) => onFieldValueChange({ target: { name: "target.audience.regions", value: value } })}
                        ></Checkbox.Group> */}
                        <Select
                          mode="tags"
                          name="target.audience.regions"
                          placeholder="Please select regions"
                          onChange={(value) => onFieldValueChange({ target: { name: 'target.audience.regions', value: value } })}
                          value={formValues.target?.audience?.regions}
                          style={{ width: '100%' }}
                        >
                          {regionData.map((o) => (
                            <Select.Option key={o.region}>
                              {o.region} ({o.total})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Card>
              <Card>
                <h4>Impression & Target KPI</h4>
                <Row gutter={8} style={{ flexDirection: 'row' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Limit Impression"
                      rules={[
                        {
                          required: true,
                          message: 'Please specify limit impression in numeric',
                        },
                      ]}
                      help={touched.quota && errors.quota ? errors.quota : null}
                      validateStatus={errors.quota ? 'error' : 'success'}
                    >
                      <InputNumber
                        name="quota"
                        id="quota"
                        disabled={isEdit}
                        onChange={(value) => setFieldValue('quota', value)}
                        value={formValues.quota}
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        step={1000}
                        min={0}
                        max={25000000}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} style={{ flexDirection: 'row' }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Target Click"
                      rules={[
                        {
                          required: false,
                          message: 'Please target click KPI',
                        },
                      ]}
                    >
                      <InputNumber
                        name="target.click"
                        id="target.click"
                        disabled={false && isEdit}
                        onChange={(value) => setFieldValue('target.click', value)}
                        value={formValues.target?.click}
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        step={100}
                        min={0}
                        max={formValues.quota ?? 100000}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Target Reach"
                      rules={[
                        {
                          required: false,
                          message: 'Please target click Reach',
                        },
                      ]}
                    >
                      <InputNumber
                        name="target.reach"
                        onChange={(val) => onFieldValueChange({ target: { name: 'target.reach', value: val } })}
                        value={formValues.target?.reach}
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        step={1000}
                        min={0}
                        max={formValues.quota ?? 1500000}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <AntFormMultipleInputItem
                  name="analytics"
                  label="3rd party impression tracking"
                  onChange={onFieldValueChange}
                  value={formValues.analytics}
                />
              </Card>
              {(formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.mRec.toLowerCase() ||
                formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.banner.toLowerCase()) && (
                <Card>
                  <h4>Banner Ad Preferences</h4>
                  <Row gutter={8} style={{ flexDirection: 'row' }}>
                    <Col xs={24} sm={24}>
                      <AntFormInputItem
                        label="Link Url"
                        name="format.linkUrl"
                        value={formValues.format?.linkUrl}
                        onChange={onFieldValueChange}
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8} style={{ flexDirection: 'row' }}>
                    <Col xs={24} sm={24}>
                      <AntFormInputItem
                        disabled={isEdit}
                        label="Cost"
                        name="cost"
                        value={formValues.cost}
                        onChange={onFieldValueChange}
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
              {formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.tabchat.toLowerCase() && (
                <Card>
                  <h4>Theme Chat Ad Preferences</h4>
                  <Row gutter={8} style={{ flexDirection: 'row' }}>
                    <Col xs={24} sm={12}>
                      <InputColorPickerFormItem
                        name="format.color"
                        label="Foreground Color"
                        onChange={onFieldValueChange}
                        onBlur={handleBlur}
                        value={formValues.format?.color ?? formValues.color}
                        error={errors.color}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <InputColorPickerFormItem
                        name="format.backgroundColor"
                        label="Background Color"
                        onChange={onFieldValueChange}
                        onBlur={handleBlur}
                        value={formValues.format?.backgroundColor ?? formValues.backgroundColor}
                        error={errors.backgroundColor}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8} style={{ flexDirection: 'row' }}>
                    <Col xs={24} sm={24}>
                      <AntFormInputItem
                        label="Link Url"
                        name="format.linkUrl"
                        value={formValues.format?.linkUrl}
                        onChange={onFieldValueChange}
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              {(formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.inter.toLowerCase() ||
                formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.reward.toLowerCase()) && (
                <>
                  <Card>
                    <h4>Capping per User</h4>
                    <Row gutter={8} style={{ flexDirection: 'row' }}>
                      <Col xs={24} sm={12}>
                        <Form.Item label="Limit impression">
                          <Select
                            onChange={(value) => setFieldValue('frequencyCap.impression', value)}
                            defaultValue={null}
                            value={formValues.frequencyCap?.impression}
                            style={{ width: 200 }}
                          >
                            <Select.Option value={null}>Unlimit</Select.Option>
                            <Select.Option value={1}>1</Select.Option>
                            <Select.Option value={2}>2</Select.Option>
                            <Select.Option value={3}>3</Select.Option>
                            <Select.Option value={4}>4</Select.Option>
                            <Select.Option value={5}>5</Select.Option>
                            <Select.Option value={6}>6</Select.Option>
                            <Select.Option value={7}>7</Select.Option>
                            <Select.Option value={8}>8</Select.Option>
                            <Select.Option value={9}>9</Select.Option>
                            <Select.Option value={10}>10</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item label="Limit Time Period">
                          <Select
                            onChange={(value) => setFieldValue('frequencyCap.timePeriod', value)}
                            value={formValues.frequencyCap?.timePeriod}
                            style={{ width: 200 }}
                          >
                            <Select.Option value="Day">Day</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <AdPreferences
                    isEdit={isEdit}
                    setFieldValue={setFieldValue}
                    onFieldValueChange={onFieldValueChange}
                    formValues={formValues}
                  />
                </>
              )}
              <Card>
                <h4>Media Files</h4>
                {(formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.inter ||
                  formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.reward) && (
                  <Form.Item label="Media Type">
                    <Select
                      name="format.media"
                      id="format.media"
                      onChange={onMediaTypeChange}
                      value={formValues.format?.media}
                      style={{ width: '100%' }}
                      defaultValue="image"
                    >
                      <Select.Option value="image">image</Select.Option>
                      <Select.Option value="video">video</Select.Option>
                    </Select>
                  </Form.Item>
                )}
                {!formValues.adUnit?.adGroup?.adGroupType.name && <div style={{ fontStyle: 'italic' }}>Please select Ad Group First!</div>}
                {isEdit && mediaFiles && mediaFiles.length > 0 && <CurrentMedia mediaFiles={mediaFiles} setFieldValue={setFieldValue} />}
                {formValues.adUnit?.adGroup?.adGroupType.name.toLowerCase() === AdGroupType.banner.toLowerCase() && (
                  <>
                    {Object.keys(BannerSize).map((bannerSize) => {
                      if (!mediaFiles.find((f) => f.size?.name === bannerSize))
                        return (
                          <Form.Item
                            label={`${BannerSize[bannerSize].description}`}
                            // tooltip={BannerSize[bannerSize].description}
                            // label={`${BannerSize[bannerSize].name} (${BannerSize[bannerSize].width}x${BannerSize[bannerSize].height})`}
                            key={bannerSize}
                          >
                            <input
                              id={AdPosition.Center}
                              name={AdPosition.Center}
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleFileChange(event, AdPosition.Center, 'image', bannerSize);
                              }}
                            />
                          </Form.Item>
                        );
                      return null;
                    })}
                  </>
                )}
                {(formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.mRec ||
                  formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.inter ||
                  formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.reward) && (
                  <>
                    {Array.isArray(mediaFiles) &&
                      mediaFiles.findIndex((media) => media.assetType.toLowerCase() === 'image' && media.position === AdPosition.Center) <
                        0 && (
                        <>
                          <Form.Item label="Upload Image">
                            <input
                              id={AdPosition.Center}
                              name={AdPosition.Center}
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                handleFileChange(event, AdPosition.Center);
                              }}
                            />

                            {previewImages.map((img) => {
                              if (img.assetType && img.assetType === 'Image')
                                return <img key={img} src={img} className="preview img-fluid" style={{ maxWidth: 150 }} alt="preview" />;
                              return null;
                            })}
                          </Form.Item>
                        </>
                      )}
                    <UploadVideo
                      mediaType={formValues.format?.media}
                      mediaFiles={mediaFiles}
                      handleFileChange={handleFileChange}
                      setFieldValue={setFieldValue}
                    />
                    <UploadLogoImage
                      adGroupType={formValues.adUnit?.adGroup?.adGroupType.name}
                      template={formValues.format?.template}
                      mediaFiles={mediaFiles}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {formValues.adUnit && formValues.adUnit.adGroup.adGroupType.name === AdGroupType.tabchat && (
                  <>
                    {mediaFiles && Array.isArray(mediaFiles) && mediaFiles.findIndex((media) => media.position === AdPosition.Left) < 0 && (
                      <Form.Item label="Left Image">
                        <input
                          id={AdPosition.Left}
                          name={AdPosition.Left}
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            setFieldValue(AdPosition.Left, event.currentTarget.files[0]);
                          }}
                        />
                      </Form.Item>
                    )}
                    {mediaFiles && Array.isArray(mediaFiles) && mediaFiles.findIndex((media) => media.position === AdPosition.Right) < 0 && (
                      <Form.Item label="Right Image">
                        <input
                          id={AdPosition.Right}
                          name={AdPosition.Right}
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            setFieldValue(AdPosition.Right, event.currentTarget.files[0]);
                          }}
                        />
                      </Form.Item>
                    )}
                    {mediaFiles && Array.isArray(mediaFiles) && mediaFiles.findIndex((media) => media.position === AdPosition.Center) < 0 && (
                      <Form.Item label="Center Image">
                        <input
                          id={AdPosition.Center}
                          name={AdPosition.Center}
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            handleFileChange(event, AdPosition.Center);
                          }}
                        />
                      </Form.Item>
                    )}
                    {mediaFiles &&
                      Array.isArray(mediaFiles) &&
                      mediaFiles.findIndex((media) => media.position === AdPosition.ActionImage) < 0 && (
                        <Form.Item label="Action Text Image">
                          <input
                            id={AdPosition.ActionImage}
                            name={AdPosition.ActionImage}
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              setFieldValue(AdPosition.ActionImage, event.currentTarget.files[0]);
                            }}
                          />
                        </Form.Item>
                      )}
                  </>
                )}
              </Card>
              <Form.Item>
                <Button type="primary" disabled={!_.isEmpty(errors)} htmlType="submit" loading={isSubmitting}>
                  {isEdit ? 'Update' : 'Create Ad'}
                </Button>
              </Form.Item>
              <Form.Item>
                <DisplayError error={error} />
                <DisplayError error={errors} />
                <DisplayError error={errors.images} />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          {(formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.inter ||
            formValues.adUnit?.adGroup?.adGroupType.name === AdGroupType.reward) && (
            <PreviewInterstitial optionals={formValues.format} mediaFiles={formValues.mediaFiles} />
          )}
        </Col>
      </Row>
      {!IS_PRODUCTION && (
        <div className="bg-light border rounded mt-4 d-none">
          <pre>
            formValues:
            {JSON.stringify(formValues, null, 2)}
          </pre>
          <pre>
            previewImages:
            {JSON.stringify(previewImages, null, 2)}
          </pre>
          {/* <pre>
            Touched:
            {JSON.stringify(touched, null, 2)}
          </pre> */}
          <pre>
            Errors:
            {JSON.stringify(errors, null, 2)}
          </pre>
        </div>
      )}
      {/* <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre> */}
    </div>
  );
};

export const CreateAdsForm = withFormik({
  // Initial Form Values
  mapPropsToValues: (props) => ({
    name: '',
    appLink: 'joylada://',
    webLink: '',
    analytics: [],
    mediaFiles: [],
    platforms: platformList,
    frequencyCap: null,
    target: {
      click: 0,
      reach: 0,
    },
    format: {
      type: 'ad',
      minimumDisplay: 5, // แสดงปุ่ม “ปิด | X” หลังแสดง ad N วินาที (0 แสดงปุ่ม “ปิด | X” ทันทีเมื่อเปิด Ad, -1 ตาม Video Length)
      template: 1,
      textEllipsis: 'ดูเพิ่มเติม',
      linkAction: 'button',
      media: 'image',
      countDown: 0,
    },
  }),

  // Custom sync validation
  // suggest using validationSchema and Yup for validation.
  // However, validate is a dependency-free, straightforward way to validate your forms.
  validate: validateAdForm(),

  handleSubmit: async (formValues, { setSubmitting, setErrors, setFieldValue, ...props }) => {
    console.debug(props);
    const { navigate } = props.props;

    const adGroupType = formValues.adUnit?.adGroup?.adGroupType.name;
    const adFormat = adGroupType === 'mRec' || adGroupType === 'tab' || adGroupType === 'banner' ? 'banner' : adGroupType.toLowerCase();

    let adId = formValues.id;
    let apiUrl = ApiEndpoint.Ads.CreateBannerAds;
    switch (adFormat) {
      case 'banner':
        apiUrl = ApiEndpoint.Ads.CreateBannerAds;
        break;
      case 'interstitial':
        apiUrl = ApiEndpoint.Ads.CreateInterAds;
        break;
      case 'rewarded':
        apiUrl = ApiEndpoint.Ads.CreateRewardAds;
        break;
      default:
        apiUrl = ApiEndpoint.Ads.CreateBannerAds;
        break;
    }

    if (formValues.id) {
      // update existing ads
      const {
        data: { data },
      } = await putData(
        apiUrl,
        formValues.id,
        {
          ...formValues,
          frequencyCap: !formValues.frequencyCap?.impression ? null : formValues.frequencyCap,
          status: 'Wait',
        },
        setErrors,
        'Update Ad Successful',
      );

      if (!data) {
        message.error('Error');
        return;
      }

      // Reload ad asset
      // const { data: mediaFileList } = await getData(ApiEndpoint.AdAsset.List + "&adId=" + formValues.id, null);
      // if (mediaFileList && mediaFileList.items) {
      //   // setMediaFiles(mediaFileList.items);
      //   setFieldValue("mediaFiles", mediaFileList.items);
      // }
    } else {
      const {
        data: { data },
      } = await postData(
        apiUrl,
        {
          ...formValues,
          frequencyCap: !formValues.frequencyCap?.impression ? null : formValues.frequencyCap,
          // optionals: {
          //   adFormat: adFormat,
          //   ...formValues.optionals,
          // },
          status: 'Wait',
        },
        setErrors,
      );

      adId = data;
    }

    // Upload multiple banner size
    if (_.isObject(formValues.Center)) {
      for await (const bannerSize of Object.keys(BannerSize)) {
        if (formValues.Center[bannerSize]) {
          // console.debug("uploading...", bannerSize);
          const media = {
            file: formValues.Center[bannerSize].fileContent,
            mediaName: formValues.name,
            position: 'Center',
            adId: adId,
            size: bannerSize,
            width: formValues.Center[bannerSize].width,
            height: formValues.Center[bannerSize].height,
            // orientation: formValues.videoOrientation,
          };
          await uploadMedia(media);

          const bannerPositionName = `Center.${bannerSize}`;
          console.debug('success upload...', formValues.Center[bannerSize]);

          console.debug('clear field value', bannerPositionName);
          setFieldValue(bannerPositionName, null);
        } else {
          console.debug('not found banner', bannerSize);
        }
      }
    }

    for await (const position of Object.keys(AdPosition)) {
      console.debug('checking image position', position);
      if (formValues[position]) {
        console.debug('....UPLOADING...', adId, formValues[position]);
        const media = {
          file: formValues[position],
          mediaName: formValues.name,
          position: position.toLowerCase() === 'video' ? 'Center' : position,
          adId: adId,
          orientation: formValues.format?.media === 'video' ? formValues.videoOrientation : null,
        };
        await uploadMedia(media);
        setFieldValue(position, null);
      }
    }

    console.debug(adId);
    if (adId) {
      window.location.replace(`/ads`);
    }
    setSubmitting(false);
  },

  displayName: 'Create Ads Form',
})(AdForm);
