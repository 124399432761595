import React from 'react';

export const groupReportTableColumn = [
  {
    title: 'Group Name',
    dataIndex: 'adGroupName',
    key: 'adGroupName',
  },
  {
    title: 'Ad Unit',
    dataIndex: 'adUnitName',
    key: 'adUnitName',
  },
  {
    title: 'Total Imp',
    dataIndex: 'totalImpression',
    key: 'totalImpression',
    render: (value) => <span>{value.toLocaleString()}</span>,
  },
  {
    title: 'Imp And',
    dataIndex: 'totalImpressionAndroid',
    key: 'totalImpressionAndroid',
    render: (value) => <span>{value.toLocaleString()}</span>,
  },
  {
    title: 'Imp ios',
    dataIndex: 'totalImpressionIos',
    key: 'totalImpressionIos',
    render: (value) => <span>{value.toLocaleString()}</span>,
  },
  {
    title: 'Imp Huawei',
    dataIndex: 'totalImpressionHuawei',
    key: 'totalImpressionHuawei',
    render: (value) => <span>{value.toLocaleString()}</span>,
  },
  // {
  //   title: "click",
  //   dataIndex: "totalClick",
  //   key: "totalClick",
  //   render: (value) => <span>{value.toLocaleString()}</span>,
  // },
  // {
  //   title: "CTR",
  //   key: "ctr",
  //   render: (click, record) => <span>{((record.totalClick * 100) / record.totalImpression).toFixed(2)}%</span>,
  // },
];
