import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { getData, deleteData } from '../../api/helpers';
import { useStore } from '../../context/AppContext';
import { adGroupTableColumns } from './adGroupTableColumns';
import { SelectPublisher } from '../Forms/SelectPublisher';
export const AdGroupList = () => {
  const [adGroups, setAdGroups] = useState([]);
  const [publisherId, setPublisherId] = useState(
    sessionStorage.getItem('publisherId') ? Number(sessionStorage.getItem('publisherId')) : null,
  );
  const [error, setError] = useState(null);
  const [appState] = useStore();

  const loadAdGroupsByPublisherId = useCallback(async (publisherId) => {
    const { data } = await getData(`${ApiEndpoint.AdGroup.List}&publisherId=${publisherId}`, setError);

    if (data) {
      const result = data.items;
      sessionStorage.setItem('publisherId', publisherId);
      setAdGroups(result);
      setPublisherId(publisherId);
    }
  }, []);

  useEffect(() => {
    const loadAdGroups = async () => {
      const { data } = await getData(`${ApiEndpoint.AdGroup.List}`, setError);
      if (data) {
        const result = data.items;
        console.debug(result);
        setAdGroups(result);
      }
    };

    const savedPublisherId = sessionStorage.getItem('publisherId');
    if (savedPublisherId) {
      loadAdGroupsByPublisherId(Number(savedPublisherId));
    } else {
      loadAdGroups();
    }
  }, [loadAdGroupsByPublisherId]);

  const onDeleteAdGroup = async (id) => {
    deleteData(ApiEndpoint.AdGroup.Delete, id, null);
    // remove item from state
    setAdGroups(adGroups.filter((item) => item.id !== id));
  };

  const distinctPublisherNames = (publishers) => [...new Set(publishers.map((pub) => pub.name))];

  return (
    <div className="container">
      <h3>Ad Groups</h3>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={18}>
          <SelectPublisher value={publisherId} onChange={(id) => loadAdGroupsByPublisherId(id)} />
        </Col>
        <Col xs={6}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to={`/setting/adgroup/create?publisherId=${publisherId ? publisherId : ''}`}>
              <PlusOutlined style={{ marginRight: 5 }} />
              New Group
            </Link>
          </div>
        </Col>
      </Row>
      {error && (
        <div>
          <Alert type="error" message={error} />
        </div>
      )}
      <Table
        rowKey="id"
        dataSource={adGroups}
        columns={adGroupTableColumns({
          onDelete: onDeleteAdGroup,
          publisherData: distinctPublisherNames(appState.publishers),
          groupTypeData: appState.groupTypes,
        })}
        size="small"
        pagination={false}
        bordered
      />
    </div>
  );
};
