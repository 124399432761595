import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Form, Input, Button, Alert, Modal, Table, Switch, Select } from 'antd';
import { ApiEndpoint, DEFAULT_DROPDOWN_WIDTH, placements } from '../../config';
import { postData, putData, getDataById, getData, deleteData } from '../../api/helpers';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';
import { SelectPublisher } from '../Forms/SelectPublisher';
import { SelectAdGroupType } from '../Forms/SelectAdGroupType';
import { SelectPlacement } from '../Forms/SelectPlacement';
import { adUnitTableColumns } from '../AdUnit/adUnitTableColumns';
import { useStore } from '../../context/AppContext';

const { confirm } = Modal;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const AdGroupForm = () => {
  const [adUnit, setAdUnit] = useState([]);
  const [availablePlacement, setAvailablePlacement] = useState([]);
  const [error, setError] = useState(null);
  const [appState] = useStore();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const query = useQuery();
  const publisherId = query.get('publisherId');
  const isEdit = groupId !== undefined;
  const { groupTypes } = appState;

  useEffect(() => {
    // Pre-selected Publisher
    if (!isEdit && !isNaN(publisherId) && Number(publisherId) > 0) {
      form.setFieldsValue({ publisherId: Number(publisherId) });
    }
    const loadAvailablePlacement = async (publisherId) => {
      const { data } = await getData(`${ApiEndpoint.AdGroup.List}&publisherId=${publisherId}`, null);
      if (data && data.items && Array.isArray(data.items)) {
        const availablePlacements = placements.filter((p) => data.items.findIndex((c) => c.placement === p) < 0);
        setAvailablePlacement(availablePlacements);
      }
    };

    if (publisherId) {
      loadAvailablePlacement(publisherId);
    }
  }, [publisherId, form, isEdit]);

  useEffect(() => {
    const loadAdGroup = async (groupId) => {
      const { data: adGroupData } = await getDataById(ApiEndpoint.AdGroup.Get, groupId, setError);
      form.setFieldsValue({
        ...adGroupData,
        adGroupTypeId: adGroupData.adGroupType.id,
        publisherId: adGroupData.publisher.id,
        rewardItemKey: adGroupData.reward?.itemKey,
        rewardItemAmount: adGroupData.reward?.itemAmount,
      });
    };

    const loadAdUnitByGroupId = async (groupId) => {
      const { data } = await getData(`${ApiEndpoint.AdUnit.List}&adgroupId=${groupId}`, null);
      if (data && data.items && Array.isArray(data.items)) {
        setAdUnit(data.items);
      }
    };

    if (groupId) {
      loadAdGroup(groupId);
      loadAdUnitByGroupId(groupId);
    }
  }, [groupId, form]);

  const onDeleteAdUnit = async (id) => {
    deleteData(ApiEndpoint.AdUnit.Delete, id, null);
    // remove item from AdList state
    setAdUnit(adUnit.filter((item) => item.id !== id));
  };

  const onFinish = async (formValues) => {
    if (isEdit) {
      // Update Ad Unit
      const selectedGroup = groupTypes.find((x) => x.id === formValues.adGroupTypeId);
      const groupTypeName = selectedGroup ? selectedGroup.name.toLowerCase() : null;
      const { data } = await putData(
        ApiEndpoint.AdGroup.Update,
        groupId,
        {
          ...formValues,
          reward:
            groupTypeName === 'rewarded' && formValues.rewardItemKey
              ? {
                  itemKey: formValues.rewardItemKey,
                  itemAmount: Number(formValues.rewardItemAmount),
                }
              : null,
        },
        setError,
        'Update Ad Group Successful',
      );
      if (data) {
        navigate('/setting/adgroup');
      }
      // If editting, after update redirect to ad unit list
      // navigate(`/setting/adunit/`);
    } else {
      // Create New Ad Unit
      const selectedGroup = groupTypes.find((x) => x.id === formValues.adGroupTypeId);
      const groupTypeName = selectedGroup ? selectedGroup.name.toLowerCase() : null;
      const { data } = await postData(
        ApiEndpoint.AdGroup.Create,
        {
          ...formValues,
          reward:
            groupTypeName === 'rewarded' && formValues.rewardItemKey
              ? {
                  itemKey: formValues.rewardItemKey,
                  itemAmount: Number(formValues.rewardItemAmount),
                }
              : null,
        },
        setError,
        'Create New Ad Unit Successful',
      );
      if (data) {
        console.debug('Create Ad Group Successful');
        navigate('/setting/adgroup');
      }
    }
  };

  const onUpdateButtonClick = (formValues) => {
    confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onFinish(formValues);
      },
      onCancel() {},
    });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New Ad Group</h3>}
      {isEdit && <h3>Edit Ad Group</h3>}
      {error && (
        <div>
          <Alert type="error" message={error}></Alert>
        </div>
      )}
      <Form {...extendedFormLayout} form={form} name="ad-group-from" onFinish={onUpdateButtonClick}>
        <Form.Item
          name="name"
          label="Group Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="publisherId"
          label="Publisher"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <SelectPublisher />
        </Form.Item>
        <Form.Item
          name="adGroupTypeId"
          label="Group Type"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <SelectAdGroupType allowChange={!isEdit} />
        </Form.Item>
        <Form.Item
          name="placement"
          label="Available Placement"
          tooltip="แสดงเฉพาะ Placement ที่ยังไม่ถูกสร้าง"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectPlacement availablePlacement={availablePlacement} />
        </Form.Item>
        <Form.Item label="Reward Item Key" shouldUpdate>
          {({ getFieldValue }) => {
            const adGroupTypeId = getFieldValue('adGroupTypeId') || {};
            const selectedGroup = groupTypes.find((x) => x.id === adGroupTypeId);
            // selectedGroup might be undefined when not selected any group
            const groupTypeName = selectedGroup ? selectedGroup.name.toLowerCase() : null;

            if (!groupTypeName || groupTypeName !== 'rewarded') return <span>For Reward Group Type Only</span>;
            return (
              <Form.Item name="rewardItemKey" style={{ margin: 0 }}>
                <Select name="rewardItemKey" style={{ width: DEFAULT_DROPDOWN_WIDTH }}>
                  <Select.Option value="coin">Coin</Select.Option>
                  <Select.Option value="key">Key</Select.Option>
                  <Select.Option value="vip">VIP</Select.Option>
                  <Select.Option value="wildcard">Wildcard</Select.Option>
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        {/* <Form.Item
          name="reward.itemKey"
          label="Reward Item Key"
          rules={[
            {
              required: true,
            },
          ]}
        ></Form.Item> */}
        <Form.Item label="Reward Item Amount" shouldUpdate>
          {({ getFieldValue }) => {
            const adGroupTypeId = getFieldValue('adGroupTypeId') || {};
            const selectedGroup = groupTypes.find((x) => x.id === adGroupTypeId);
            // selectedGroup might be undefined when not selected any group
            const groupTypeName = selectedGroup ? selectedGroup.name.toLowerCase() : null;

            if (!groupTypeName || groupTypeName !== 'rewarded') return <span>For Reward Group Type Only</span>;
            return (
              <Form.Item name="rewardItemAmount" style={{ marginLeft: 0, marginRight: 0 }}>
                <Input style={{ width: DEFAULT_DROPDOWN_WIDTH }} type="number" />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item name="enabled" label="Enabled Group" valuePropName="checked">
          <Switch />
        </Form.Item>
        {/* <Form.Item shouldUpdate>
          {() => {
            return <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>;
          }}
        </Form.Item> */}
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit">
            {isEdit ? 'Update' : 'Create Ad Group'}
          </Button>
        </Form.Item>
      </Form>
      <div style={{ marginTop: 30 }}>
        <div>
          <Link className="ant-btn ant-btn-primary" to={`/setting/adunit/create?adgroupId=${groupId}`}>
            <PlusOutlined style={{ marginRight: 5 }} />
            New Ad Network Unit
          </Link>
        </div>
        <Table
          rowKey="id"
          dataSource={adUnit}
          columns={adUnitTableColumns({
            onDelete: onDeleteAdUnit,
            publisherData: [],
          })}
          size="small"
          pagination={false}
          bordered
        />
      </div>
    </div>
  );
};
