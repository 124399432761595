export function sortAdUnitByCountryCode(a, b) {
  var o1 = a.adGroup.publisher.name.toLowerCase();
  var o2 = b.adGroup.publisher.name.toLowerCase();

  var p1 = a.adGroup.publisher.countryCode.toLowerCase();
  var p2 = b.adGroup.publisher.countryCode.toLowerCase();

  if (o1 < o2) return -1;
  if (o1 > o2) return 1;
  if (p1 < p2) return -1;
  if (p1 > p2) return 1;
  return 0;
}
