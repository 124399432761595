import moment from 'moment';
import React from 'react';

export const creatorRevenueTableColumns = [
  {
    title: 'Day',
    dataIndex: 'day',
    key: 'day',
    render: (value) => <span>{moment(value).format('ddd, DD-MMM-YYYY')}</span>,
  },
  {
    title: 'Impression',
    dataIndex: 'impression',
    key: 'impression',
    render: (value) => <span>{value?.toLocaleString()}</span>,
  },
  {
    title: 'Click',
    dataIndex: 'click',
    key: 'click',
    render: (value) => <span>{value?.toLocaleString()}</span>,
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    render: (value, record) => <span>{value ? Number(value).toFixed(3) : 0}</span>,
  },
];
