import { message } from 'antd';
import moment from 'moment';

export function generateDayChartData(periods, impressionData, reachData = [], clickData = []) {
  if (!Array.isArray(impressionData)) {
    message.error('Impression data is invalid!');
    return [];
  }
  const startDate = periods[0];
  const endDate = periods[1];
  const dateDiff = Math.floor((Date.parse(endDate) - Date.parse(startDate)) / 86400000);
  const chartData = [];

  for (let i = 0; i <= dateDiff; i++) {
    const currentDay = moment(startDate).add(i, 'days').local(true).format('YYYY-MM-DD');

    const impressionDayData = impressionData.find((x) => moment(x.day).local(true).format('YYYY-MM-DD') === currentDay);
    const reachDayData = reachData.find((x) => moment(x.day).local(true).format('YYYY-MM-DD') === currentDay);

    const clickDayData = clickData.find((x) => moment(x.day).local(true).format('YYYY-MM-DD') === currentDay);

    // const impressionDayData = impressionData.find((x) => x.day === currentDay);
    // const reachDayData = reachData.find((x) => x.day === currentDay);
    chartData.push({
      day: moment(startDate).add(i, 'days').format('YYYY-MM-DD'),
      total: impressionDayData ? impressionDayData?.total || impressionDayData?.impression : 0,
      reach: reachDayData ? reachDayData?.total : 0,
      click: clickData ? clickDayData?.total : 0,
      totalWithoutReach: reachDayData ? Number(impressionDayData?.total) - Number(reachDayData?.total) : impressionDayData?.total,
    });
  }

  return chartData;
}

function findSameDayData(data, day) {
  return data.find((x) => moment(x.day).local(true).format('YYYY-MM-DD') === day);
}

export function generateGroupRequestChartData(
  periods,
  requestLogData,
  impOokbeeData = [],
  impAdmobData = [],
  impAppOdealData = [],
  impHuaweiData = [],
) {
  if (!Array.isArray(requestLogData)) {
    message.error('Impression data is invalid!');
    return [];
  }
  const startDate = periods[0];
  const endDate = periods[1];
  const dateDiff = Math.floor((Date.parse(endDate) - Date.parse(startDate)) / 86400000);
  const chartData = [];

  for (let i = 0; i <= dateDiff; i++) {
    const currentDay = moment(startDate).add(i, 'days').local(true).format('YYYY-MM-DD');

    const requestLogDay = findSameDayData(requestLogData, currentDay);

    // Hard-coded AdUnit
    const impOokbeeDay = findSameDayData(impOokbeeData, currentDay);
    const impAdmobDay = findSameDayData(impAdmobData, currentDay);
    const impAppOdealDay = findSameDayData(impAppOdealData, currentDay);
    const impHuaweiDay = findSameDayData(impHuaweiData, currentDay);

    chartData.push({
      day: moment(startDate).add(i, 'days').format('YYYY-MM-DD'),
      totalRequest: requestLogDay ? requestLogDay?.total : 0,
      impOokbee: impOokbeeDay ? impOokbeeDay?.total : 0,
      impAdmob: impAdmobDay ? impAdmobDay?.total : 0,
      impAppOdeal: impAppOdealDay ? impAppOdealDay?.total : 0,
      impHuawei: impHuaweiDay ? impHuaweiDay?.total : 0,
    });
  }

  return chartData;
}
