// Predefine BannerSize
// Ratio is calculated from Math.round(728/90 * 100) / 10

export const BannerSize = {
  SQUARE: { name: 'SQUARE 1:1', description: 'SQUARE - Ratio 1:1 - 250x250, 500x500', width: 250, height: 250, ratio: 10 },
  MEDIUM_REC: {
    name: 'MEDIUM_REC 6:5',
    description: 'MEDIUM/LARGE Rectangle - Ratio 6:5 - 300x250, 336x280, 600x500',
    width: 300,
    height: 250,
    ratio: 12,
  },
  // RECTANGLE: { name: "RECTANGLE 3:1", description: "Ratio 3:1 - 300x100, 600x200", width: 300, height: 100, ratio: 30 },
  // LARGE_REC: { name: "LARGE_REC 6:5", description: "Ratio 6:5 - 300x250, 336x280, 600x500", width: 336, height: 280, ratio: 12 },
  // FULL_BANNER: { name: "FULL_BANNER", width: 468, height: 60, ratio: 78 },
  // SKYSCRAPER: { name: "SKYSCRAPER", width: 120, height: 600, ratio: 2 },
  // WIDE_SKYSCRAPER: { name: "WIDE_SKYSCRAPER", width: 160, height: 600, ratio: 2.7 },
  // LEADERBOARD: { name: "LEADERBOARD", width: 728, height: 90, ratio: 8.09 },
};
