import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const adUnitTableColumns = ({ onDelete }) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Network Unit Name',
    dataIndex: 'adNetwork',
    key: 'adNetwork',
    filters: [
      {
        text: 'Ookbee',
        value: 'ookbee',
      },
      {
        text: 'AdMob',
        value: 'admob',
      },
      {
        text: 'AppOdeal',
        value: 'appodeal',
      },
      {
        text: 'Huawei',
        value: 'huawei',
      },
    ],
    onFilter: (value, record) => record.adNetwork.name === value,
    sorter: (a, b) => a.adNetwork.name.length - b.adNetwork.name.length,
    render: (adNetwork) => adNetwork.name ?? '-',
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Ad Group Name',
    dataIndex: 'adGroup',
    key: 'adGroup',
    render: (adGroup) => adGroup.name ?? '-',
    sorter: (a, b) => a.adGroup.name.localeCompare(b.adGroup.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Capping',
    dataIndex: 'frequencyCap',
    key: 'frequencyCap',
    render: (frequencyCap) => frequencyCap?.impression ?? 'Unlimit',
  },
  {
    title: 'Sort Seq',
    dataIndex: 'sortSeq',
    key: 'sortSeq',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <div>
        <Link to={`/setting/adunit/${record.id}`} className="ant-btn ant-btn-primary ant-btn-circle">
          <EditOutlined />
        </Link>
        <Popconfirm
          placement="top"
          title="Confirm Delete?"
          okButtonProps={{ size: 'middle' }}
          cancelButtonProps={{ size: 'middle' }}
          onConfirm={() => onDelete(record.id)}
          okText="Confirm Delete"
          cancelText="No"
        >
          <Button type="ghost" shape="circle" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    ),
  },
];
