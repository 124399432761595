import React from 'react';
import PropTypes from 'prop-types';
import { Card, Popconfirm, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const PreviewMedia = ({ media, onDeleteMedia = null, onChangeOrientation = null }) => (
  <Card bodyStyle={{ padding: 10 }}>
    <Card.Meta title={media.size ? media.size.name : media.position} style={{ marginBottom: 10 }} />
    {media.assetType.toLowerCase() === 'image' && (
      <a href={media.assetUrl} target="_blank" rel="noopener noreferrer">
        <img
          alt="banner"
          src={media.assetUrl}
          style={{
            width: 'auto',
            maxWidth: 300,
            height: 'auto',
            marginTop: 8,
            marginBottom: 8,
          }}
        />
      </a>
    )}
    {media.assetType.toLowerCase() === 'video' && (
      <>
        <div>
          <Select
            value={media.orientation}
            style={{ width: 150 }}
            size="middle"
            onChange={(value) => onChangeOrientation(value)}
            defaultValue="portrait"
          >
            <Select.Option value="portrait">Portrait</Select.Option>
            <Select.Option value="landscape">Landscape</Select.Option>
          </Select>
        </div>
        <a href={media.assetUrl} target="_blank" rel="noopener noreferrer" className="d-block">
          <video width="320" height="240" controls>
            <source src={media.assetUrl} type="video/mp4" />
          </video>
        </a>
      </>
    )}

    <div>
      <Popconfirm
        title="Confirm delete media?"
        okButtonProps={{ size: 'middle' }}
        cancelButtonProps={{ size: 'middle' }}
        onConfirm={onDeleteMedia}
      >
        <Button type="ghost" danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  </Card>
);

PreviewMedia.propTypes = {
  media: PropTypes.shape({
    position: PropTypes.string,
    assetUrl: PropTypes.string,
    assetType: PropTypes.string,
  }),
  onDeleteMedia: PropTypes.func,
};
