import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { postData, putData, getData } from '../../api/helpers';
import { ApiEndpoint } from '../../config';
import { useStore } from '../../context/AppContext';
import { CMS_ACTION } from '../../context/actionsType';
import { useRouter } from '../../hooks/useRouter';
import { DisplayError } from '../DisplayError';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export const EditAvertiserForm = () => {
  const [error, setError] = useState(null);
  const [, dispatch] = useStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const [form] = Form.useForm();
  const { navigate, query } = useRouter();
  const { id } = query;

  const loadAdvertiserData = useCallback(
    async (id) => {
      const { data } = await getData(`${ApiEndpoint.Advertiser.Get}/${id}`, setError);
      if (data) {
        // console.debug(data);
        form.setFieldsValue(data);
      }
    },
    [form],
  );

  useEffect(() => {
    if (id) {
      loadAdvertiserData(id);
    }
  }, [id, loadAdvertiserData]);

  const onError = (values) => {
    setError(values);
    setIsUpdating(false);
  };

  const fetchAdvertiser = async () => {
    const response = await getData(ApiEndpoint.Advertiser.List);

    if (response && response.data) {
      const { items } = await response.data;
      return items;
    } else {
      message.error('Error loading Advertiser data');
    }
  };

  const onFinish = async (formValues) => {
    // clear previously error
    setError(null);
    setIsUpdating(true);
    if (id) {
      // Update existing data
      const { data } = await putData(ApiEndpoint.Advertiser.Update, id, formValues, onError, 'Update Advertiser Successful');
      if (data && data.data) {
        const items = await fetchAdvertiser();
        dispatch({
          type: CMS_ACTION.ADVERTISER.SET,
          payload: items,
        });
        navigate(`/advertiser/${id}/campaign`);
      }
    } else {
      const { data } = await postData(ApiEndpoint.Advertiser.Create, formValues, onError, 'Created new Advertiser successful');
      if (data) {
        const items = await fetchAdvertiser();
        dispatch({
          type: CMS_ACTION.ADVERTISER.SET,
          payload: items,
        });

        // After created new advertiser, redirect to advertiser list page
        navigate(`/advertiser`);
      }
    }
  };

  return (
    <div className="container">
      {id === undefined && <h3>Add New Advertiser</h3>}
      {id !== undefined && <h3>Edit Advertiser</h3>}
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Advertiser Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imagePath"
          label="Logo Image Url"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="contact"
          label="Contact Address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {id === undefined ? 'Create' : 'Update'}
          </Button>
        </Form.Item>
        <DisplayError error={error} />
      </Form>
    </div>
  );
};
