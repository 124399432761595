import React from 'react';
import { Select } from 'antd';
import { useStore } from '../../context/AppContext';
import { DEFAULT_DROPDOWN_WIDTH } from '../../config';

export const SelectAdGroupType = ({ value, onChange, showAllOption = false, allowChange = true }) => {
  const [appState] = useStore();

  const { groupTypes } = appState;

  return (
    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width: DEFAULT_DROPDOWN_WIDTH }}
      disabled={!allowChange}
      placeholder={showAllOption ? 'Filter by Group Type' : 'Select Group Type'}
      onChange={onChange}
    >
      {groupTypes.map((groupType) => (
        <Select.Option key={groupType.id} value={groupType.id}>
          {groupType.name}
        </Select.Option>
      ))}
    </Select>
  );
};
