import React from 'react';
import { Select } from 'antd';
import { DEFAULT_DROPDOWN_WIDTH, placements } from '../../config';

export const SelectPlacement = ({ value, onChange, allowChange = true, availablePlacement = placements }) => {
  console.debug(value);
  console.debug(availablePlacement);
  return (
    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width: DEFAULT_DROPDOWN_WIDTH }}
      disabled={!allowChange}
      placeholder="Select Available Placement"
      onChange={onChange}
    >
      {availablePlacement.map((placement) => (
        <Select.Option key={placement} value={placement}>
          {placement}
        </Select.Option>
      ))}
    </Select>
  );
};
