import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { DEFAULT_DROPDOWN_WIDTH } from '../../config';

const DEFAULT_STYLE = { width: '100%' };
export const SelectAdUnitFormItem = React.memo(
  ({
    data,
    value,
    useAdGroupName,
    onChange = null,
    error = null,
    allowChange = true,
    disabled = false,
    style = DEFAULT_STYLE,
    width = DEFAULT_DROPDOWN_WIDTH,
  }) => {
    const [adUnits, setAdUnits] = useState(data);
    // console.debug({ value, data });
    useEffect(() => {
      // const loadAdUnits = async () => {
      //   console.debug("Load all adunits inside SelectAdUnit");
      //   const { _data } = await getData(`${ApiEndpoint.AdUnit.List}`, null);
      //   if (_data) {
      //     const result = _data.items;
      //     setAdUnits(result);
      //   }
      // };

      if (!data || data.length <= 0) {
        //   loadAdUnits();
      } else {
        // console.debug("Set AdUnits data from parent (props) data", data);
        setAdUnits(data);
      }
    }, [data]);

    return (
      <Form.Item label="Placement" validateStatus={error ? 'error' : 'success'}>
        <Select
          value={value}
          dropdownMatchSelectWidth={false}
          showSearch={false}
          style={{ width: width ?? DEFAULT_DROPDOWN_WIDTH, ...style }}
          disabled={!allowChange || disabled}
          placeholder={useAdGroupName ? 'Select Placement' : 'Select Ad Unit'}
          onChange={onChange}
        >
          {adUnits.map((adUnit) => (
            <Select.Option key={adUnit.id} value={adUnit.id}>
              {useAdGroupName
                ? `${adUnit.adGroup.publisher.name} (${adUnit.adGroup.publisher.countryCode}) - ${adUnit.adGroup.name} [${adUnit.adGroup.adGroupType.name}]`
                : adUnit.adNetwork}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
