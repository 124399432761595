import React, { lazy, useEffect, useState } from 'react';
import { Route, Outlet, Routes } from 'react-router-dom';
import { Layout, message } from 'antd';

// CSS
import './App.css';

// UI Layout
import { LeftMenu } from './layout/LeftMenu';

// Components
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import SignIn from './containers/Signin';
import DashboardCRM from './components/Dashboard/Dashboard';
import { AdGroupForm } from './components/AdGroup/EditAdGroupForm';
import { AdGroupList } from './components/AdGroup/AdGroupList';
import { AdsList } from './components/Ads/AdList';
import { AdUnitForm } from './components/AdUnit/EditAdUnitForm';
import { AdUnitList } from './components/AdUnit/AdUnitList';
import { AdvertiserList } from './components/Advertiser/AdvertiserList';
import { CampaignInfo } from './components/Campaign/CampaignInfo';
import { CampaignList } from './components/Campaign/CampaignList';
import { CampaignReport } from './components/Report/CampaignReport';
import { CreateAdsForm } from './components/Ads/EditAdForm';
import { EditAvertiserForm } from './components/Advertiser/EditAdvertiserForm';
import { EditCampaignForm } from './components/Campaign/EditCampaignForm';
import { EditRoleForm } from './components/Users/EditRoleForm';
import { EditRolePermissionForm } from './components/Users/EditRolePermissionForm';
import { EditUserForm } from './components/Users/EditUserForm';
import { GroupReportByGroupId } from './components/Report/GroupReportByGroupId';
import { PublisherForm } from './components/Publisher/EditPublisherForm';
import { PublisherList } from './components/Publisher/PublisherList';
import { PublisherReport } from './components/Report/PublisherReport';
import { CreatorRevenue } from './components/Report/CreatorRevenue';
import { RolePermission } from './components/Users/RolePermission';
import { Roles } from './components/Users/Roles';
import { UserList } from './components/Users/UserList';

// Configuration and Utility
import { ApiEndpoint, getBackgrounColor, getIdentityApiUrl, getRedisKeysThreshold } from './config';
import axios from './api/axios';
import { getData } from './api/helpers';

// Context
import { CMS_ACTION } from './context/actionsType';
import { useStore } from './context/AppContext';
// import InfoView from "./components/InfoView";

import moment from 'moment-timezone';
import AppInfoView from './components/AppInfoView';
import Loader from 'react-spinners/BarLoader';
import TestModalContext from './components/Users/TestModal';

const ActivityLogs = lazy(() => import('./components/Users/ActivityLog'));

moment.locale('th');
moment.tz.setDefault('Asia/Bangkok');

const bgColor = getBackgrounColor();

const App = () => {
  const [appState, dispatch] = useStore();
  const [redisKeysCount, setRedisKeysCount] = useState(0);
  const { user } = appState;
  const isAgencyPath = window.location.pathname.indexOf('agency') >= 0;

  // UI Components
  const { Content, Footer } = Layout; // AntD UI => https://ant.design/components/layout/

  useEffect(() => {
    async function fetchAdvertiser() {
      const response = await getData(ApiEndpoint.Advertiser.List);

      if (response && response.data) {
        const { items } = await response.data;
        dispatch({ type: CMS_ACTION.ADVERTISER.SET, payload: items });
      } else {
        message.error('Error loading Advertiser data');
      }
    }

    async function fetchPublisher() {
      const response = await getData(ApiEndpoint.Publisher.List);
      if (response && response.data) {
        const { items } = await response.data;
        dispatch({ type: CMS_ACTION.PUBLISHER.SET, payload: items });
      }
    }

    // async function fetchPricingModel() {
    //   const response = await getData(ApiEndpoint.PricingModel.List);
    //   if (response && response.data) {
    //     const { items } = await response.data;
    //     dispatch({ type: CMS_ACTION.PRICINGMODEL.Get, payload: items });
    //   }
    // }

    async function fetchAdGroupType() {
      const response = await getData(ApiEndpoint.AdGroupType.List);
      if (response && response.data) {
        const { items } = await response.data;
        dispatch({ type: CMS_ACTION.GROUPTYPE.SET, payload: items });
      }
    }

    async function fetchRedisKeysCount() {
      const response = await getData(ApiEndpoint.Redis.KeysCount);
      if (response && response.data) {
        const value = await response.data;
        setRedisKeysCount(value);

        if (value < getRedisKeysThreshold()) {
          message.warn(`Cache keys are below threshold!! (Current: ${value}, Expected: ${getRedisKeysThreshold()})`, 10);
        }
      }
    }

    fetchAdvertiser();
    fetchPublisher();
    fetchAdGroupType();
    fetchRedisKeysCount();

    const interval = setInterval(() => {
      console.log('This will run every 15 seconds!');
      fetchRedisKeysCount();
    }, 180000);
    return () => clearInterval(interval);
  }, [dispatch]);

  // Use for backward Ookbee Authentication
  //   useEffect(() => {
  //     if (code) {
  //       // ถ้ามี query string 'code' มา ให้เอาไปใส่ใน global state
  //       dispatch({ type: CMS_ACTION.AUTH.SET_TOKEN, payload: code });
  //     }
  //   }, [code]);

  useEffect(() => {
    if (user.accessToken && !isAgencyPath) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.accessToken;

      // TODO: Check for accessToken ExpiresDate
      if (!user.firstName) {
        console.debug('No user profile, fetching profile...');
        dispatch({ type: CMS_ACTION.FETCH_START });
        // Get Ookbee user profile
        axios
          .get(`${getIdentityApiUrl()}/accounts/${user.ookbeeNumericId}/profile`, {})
          .then(({ data: profileData }) => {
            dispatch({
              type: CMS_ACTION.AUTH.SET_USER_PROFILE,
              payload: { ...profileData.data },
            });
          })
          .catch((error) => {
            console.debug(error);
            dispatch({
              type: CMS_ACTION.FETCH_ERROR,
              payload: error.response?.data?.error?.message,
            });
          });
      }
    }
  }, [user, dispatch, isAgencyPath]);

  if (!user.accessToken && !isAgencyPath) {
    console.debug('not authorized (no accessToken found), show SignIn page');
    // Any components that use router must be wrapped inside <Router>
    return (
      <Routes>
        <Route path="/" element={<SignIn />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout className="layout">
            <Content style={{ padding: '0', display: 'flex' }}>
              <div
                style={{
                  minHeight: '90vh',
                  backgroundColor: bgColor,
                }}
              >
                <LeftMenu />
              </div>
              <div className="site-content">
                {/* <AppInfoView> is like an application state viewer. 
                Which display erros and loading indicator */}
                <AppInfoView />

                <SuspenseWithChunkError fallback={<Loader />}>
                  {/* <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
                  <Outlet />
                </SuspenseWithChunkError>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <h4>Ookbee Ads System</h4>
              <div style={{ fontSize: 12 }}>Redis Cache Keys: {redisKeysCount}</div>
            </Footer>
          </Layout>
        }
      >
        {/* ลำดับใน Route ห้าม Auto Sort!!! */}
        <Route path="/" exact element={<DashboardCRM />} />
        <Route path={'/advertiser'} exact element={<AdvertiserList />} />
        <Route path="/report/campaign" element={<CampaignReport />} />

        <Route path={'/report/group'} element={<GroupReportByGroupId />} />
        <Route path={'/report/publisher'} element={<PublisherReport />} />
        <Route path={'/report/creator'} element={<CreatorRevenue />} />
        <Route path={'/signin'} exact element={<SignIn />} />
        <Route path={'/ads'} exact element={<AdsList />} />
        <Route path={'/ads/create'} exact element={<CreateAdsForm />} />
        <Route path={'/ads/:adId/edit'} element={<CreateAdsForm />} />
        <Route path={'/advertiser/create'} exact element={<EditAvertiserForm />} />
        <Route path={'/advertiser/:id'} element={<EditAvertiserForm />} />
        <Route path={'/advertiser/:advertiserId/campaign'} exact element={<CampaignList />} />
        <Route path={'/campaign/create'} element={<EditCampaignForm />} />
        <Route path={'/campaign'} exact element={<CampaignList />} />
        <Route path={'/campaign/:campaignId/edit'} element={<EditCampaignForm />} />
        <Route path={'/campaign/:campaignId'} element={<CampaignInfo />} />
        <Route path={'/permission'} exact element={<RolePermission />} />
        <Route path={'/permission'} exact element={<EditRolePermissionForm />} />
        <Route path={'/role'} exact element={<Roles />} />
        <Route path={'/role/create'} exact element={<EditRoleForm />} />
        <Route path={'/role/:roleId'} exact element={<EditRoleForm />} />
        <Route path={'/setting/adgroup'} exact element={<AdGroupList />} />
        <Route path={'/setting/adgroup/create'} exact element={<AdGroupForm />} />
        <Route path={'/setting/adgroup/:groupId'} exact element={<AdGroupForm />} />
        <Route path={'/setting/adunit'} exact element={<AdUnitList />} />
        <Route path={'/setting/adunit/create'} exact element={<AdUnitForm />} />
        <Route path={'/setting/adunit/:unitId'} exact element={<AdUnitForm />} />
        <Route path={'/setting/publisher'} exact element={<PublisherList />} />
        <Route path={'/setting/publisher/create'} exact element={<PublisherForm />} />
        <Route path={'/setting/publisher/:publisherId'} exact element={<PublisherForm />} />
        <Route path={'/user'} exact element={<UserList />} />
        <Route path={'/user/activity'} exact element={<ActivityLogs />} />
        <Route path={'/user/create'} exact element={<EditUserForm />} />
        <Route path={'/user/:userId'} exact element={<EditUserForm />} />
        <Route path={'/test/modal'} exact element={<TestModalContext />} />
      </Route>
      <Route path="/agency" element={<CampaignReport />} />
    </Routes>
  );
};

App.whyDidYouRender = false;

export default App;
