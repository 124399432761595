import React from 'react';
import { Form, Input } from 'antd';

const MyInputFormItem = ({
  required = true,
  name = null,
  value = null,
  onChange = null,
  placeholder = null,
  label = null,
  maxLength = null,
  suffix = null,
  disabled = false,
  onBlur,
  help,
  error,
  ...props
}) => (
  <Form.Item help={help} label={label} hasFeedback={false} validateStatus={error ? 'error' : 'success'} {...props}>
    <Input
      name={name}
      required={required}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={maxLength}
      suffix={suffix}
    />
  </Form.Item>
);

export const AntFormInputItem = React.memo(MyInputFormItem);
