import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Col, Popconfirm, Spin, message } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { useStore } from '../../context/AppContext';
import { ApiEndpoint } from '../../config';
import { SelectAdvertiser } from '../Forms/SelectAdvertiser';
import { postData, getData, putData, deleteData } from '../../api/helpers';
import { CMS_ACTION } from '../../context/actionsType';
import { useRouter } from '../../hooks/useRouter';
import { DisplayError } from '../DisplayError';

export const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export const layoutWithoutLabel = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
    offset: 8,
  },
};

export const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export const EditCampaignForm = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationEmails, setNotificationEmails] = useState([]);
  const [newEmail, setNewEmail] = useState(null);
  const [form] = Form.useForm();
  const [appState, dispatch] = useStore();
  const { navigate, query } = useRouter();
  const { campaignId } = query;
  const isEdit = campaignId !== undefined;

  useEffect(() => {
    const loadCampaignData = async (campaignId) => {
      const { data } = await getData(`${ApiEndpoint.Campaign.Get}/${campaignId}`, setError);
      if (data) {
        const result = {
          ...data,
          advertiserId: data.advertiser.id,
        };
        form.setFieldsValue(result);
      }
      setIsLoading(false);
    };

    const loadNotificationEmailData = async (campaignId) => {
      const { data } = await getData(`${ApiEndpoint.NotificationEmails.List}&campaignId=${campaignId}`, setError);
      if (data) {
        console.debug(data.items);
        form.setFieldsValue({
          notificationEmails: data.items,
        });
        setNotificationEmails(data.items);
      }
      setIsLoading(false);
    };

    // Ensure app state has loaded all required data
    if (campaignId && appState && appState.publishers.length > 0 && appState.groupTypes.length > 0 && appState.advertisers.length > 0) {
      setIsLoading(true);
      loadCampaignData(campaignId);
      loadNotificationEmailData(campaignId);
    }
  }, [campaignId, form, appState]);

  async function fetchAdvertiser() {
    console.debug('fetchAdvertiser()');
    const response = await getData(ApiEndpoint.Advertiser.List);

    if (response && response.data) {
      const { items } = await response.data;
      dispatch({ type: CMS_ACTION.ADVERTISER.SET, payload: items });
    } else {
      message.error('Error loading Advertiser data');
    }
  }

  const onDeleteCampaign = async () => {
    await deleteData(ApiEndpoint.Campaign.Delete, campaignId, setError);
    fetchAdvertiser();
    navigate('/campaign');
  };

  const onRemoveEmail = async (item) => {
    console.debug(item);
    await deleteData(ApiEndpoint.NotificationEmails.Delete, item.id, setError);
    const _data = notificationEmails.filter((x) => x.id !== item.id);
    setNotificationEmails(_data);
  };

  const onAddNewEmail = async () => {
    if (!newEmail) return null;

    const postBody = {
      campaignId: campaignId,
      displayName: newEmail,
      email: newEmail,
      enabled: true,
    };
    const { data } = await postData(ApiEndpoint.NotificationEmails.Create, postBody, setError);

    if (data) {
      console.debug(data);
      postBody.id = data.data;
      const _ = [...notificationEmails, postBody];
      // const _data = notificationEmails.filter((x) => x.id !== item.id);
      setNewEmail(null);
      setNotificationEmails(_);
    }
  };

  const onFinish = async (formValues) => {
    if (isEdit) {
      // if there are pending add email
      if (newEmail) {
        await onAddNewEmail();
      }

      const { data } = await putData(
        ApiEndpoint.Campaign.Update,
        campaignId,
        {
          ...formValues,
          optionals: {
            ...formValues.optionals,
          },
        },
        setError,
        'Update Campaign Successful',
      );

      if (data) {
        navigate(`/campaign/${campaignId}`);
      }
    } else {
      // Create New Campaign
      const { data } = await postData(
        ApiEndpoint.Campaign.Create,
        {
          ...formValues,
          optionals: {
            ...formValues.optionals,
          },
        },
        setError,
        'Create New Campaign Successful',
      );
      const returnId = data.data;
      if (data && returnId && !isNaN(returnId)) {
        fetchAdvertiser();
        navigate(`/campaign/${returnId}`);
      }
    }
  };

  return (
    <div className="container">
      {campaignId === undefined && <h3>Add New Campaign</h3>}
      {campaignId !== undefined && <h3>Edit Campaign</h3>}
      {isLoading && <Spin size="large" />}
      <DisplayError error={error} />
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={{
          advertiserId: null,
          quota: 0,
        }}
      >
        <Form.Item
          name="advertiserId"
          label="Advertiser"
          valuePropName="selectedValue"
          rules={[
            {
              required: true,
              message: 'Please select Advertiser!',
            },
          ]}
        >
          <SelectAdvertiser controlId="advertiserId" advertiserList={appState.advertisers} showAllOption={false} allowChange={true} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Campaign Name"
          rules={[
            {
              required: true,
              message: "'Campaign name' is required",
            },
          ]}
        >
          <Input maxLength={40} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "'Campaign description' is required",
            },
          ]}
        >
          <Input maxLength={500} />
        </Form.Item>

        {notificationEmails.map((item, index) => (
          <Form.Item
            {...(index === 0 ? layout : layoutWithoutLabel)}
            label={index === 0 ? 'Summary Report to Emails' : ''}
            required={false}
            key={item.id}
          >
            <span>{item.email}</span>

            {notificationEmails.length > 0 ? (
              <MinusCircleFilled
                style={{ marginLeft: 5, color: 'red', fontSize: 20 }}
                onClick={() => {
                  onRemoveEmail(item);
                }}
              />
            ) : null}
          </Form.Item>
        ))}
        {isEdit && (
          <Form.Item {...layoutWithoutLabel}>
            <Input
              placeholder="Add Email address"
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              style={{ width: '70%' }}
            />
            <PlusCircleFilled style={{ marginLeft: 5, color: 'green', fontSize: 20 }} onClick={onAddNewEmail} />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <div style={{ display: 'flex' }}>
            <Col xs={12}>
              <Button type="primary" htmlType="submit">
                {campaignId !== undefined ? 'Update' : 'Create Campaign'}
              </Button>
            </Col>
            <Col
              xs={12}
              style={{
                alignContent: 'end',
                justifyContent: 'end',
                textAlign: 'right',
              }}
            >
              {campaignId !== undefined && (
                <Popconfirm title="Confirm delete this campaign?" onConfirm={onDeleteCampaign}>
                  <Button type="danger">Delete</Button>
                </Popconfirm>
              )}
            </Col>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
