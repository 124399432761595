import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert, Modal, Select, message } from 'antd';
import { ApiEndpoint, getIdentityApiUrl } from '../../config';
import { postData, putData, getDataById, getData } from '../../api/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { extendedFormLayout } from '../Forms/Layout/form';
import { extendedFooterFormLayout } from '../Forms/Layout/footer';
import axios from '../../api/axios';

const { confirm } = Modal;
const NOT_REGISTERED_OOKBEE_ERROR_MSG = 'This email is not registered with Ookbee account!';

export const EditUserForm = () => {
  const [error, setError] = useState(null);
  const [isValidOokbeeEmail, setIsValidOokbeeEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validatingEmailStatus, setValidatingEmailStatus] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [existingUsers, setExistingUsers] = useState([]);
  const [form] = Form.useForm();
  const { userId } = useParams();
  const navigate = useNavigate();
  const isEdit = userId !== undefined;

  const loadUserData = useCallback(
    async (id) => {
      setIsLoading(true);
      const { data } = await getDataById(ApiEndpoint.User.Get, id, setError);

      console.debug(data);
      if (data) {
        form.setFieldsValue(data);
        setIsValidOokbeeEmail(true);
      }
      setIsLoading(false);
    },
    [form],
  );

  useEffect(() => {
    const loadUserRoles = async () => {
      const { data } = await getData(ApiEndpoint.UserRole.List, setError);
      setUserRoles(data.items);
    };

    const loadExistingUsers = async () => {
      const { data } = await getData(ApiEndpoint.User.List, setError);
      setExistingUsers(data.items);
    };

    loadUserRoles();
    loadExistingUsers();
  }, []);

  useEffect(() => {
    if (userId) {
      loadUserData(userId);
    }
  }, [userId, loadUserData]);

  const onFinish = async (formValues) => {
    setIsLoading(true);
    if (isEdit) {
      const response = await putData(ApiEndpoint.User.Update, userId, formValues, setError, 'Update User Successful');
      setIsLoading(false);
      if (response && response.data) {
        navigate(`/user`);
      }
    } else {
      // Create New User
      const response = await postData(ApiEndpoint.User.Create, formValues, setError, 'Create New User Successful');
      setIsLoading(false);
      if (response && response.data) {
        navigate(`/user`);
      }
    }
  };

  const onUpdateButtonClick = (formValues) => {
    confirm({
      title: isEdit ? 'Confirm Update?' : 'Confirm Create?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      async onOk() {
        await onFinish(formValues);
      },
      onCancel() {},
    });
  };

  const validateOokbeeEmail = async (event) => {
    if (!event.target.value) {
      return;
    }
    setValidatingEmailStatus('validating');
    setError(null);
    form.setFieldsValue({ userName: event.target.value });
    axios
      .get(`${getIdentityApiUrl()}/accounts/lookup?email=${event.target.value}`)
      .then(({ data }) => {
        if (data.data && data.data.id) {
          const { id } = data.data;
          if (existingUsers.find((u) => u.id === id)) {
            message.error('This user already in Ads System');
            setIsValidOokbeeEmail(false);
            setValidatingEmailStatus('error');
          } else {
            form.setFieldsValue({ id: Number(id) });
            setIsValidOokbeeEmail(true);
            setValidatingEmailStatus('success');
          }
        } else {
          setError(NOT_REGISTERED_OOKBEE_ERROR_MSG);
          setIsValidOokbeeEmail(false);
          setValidatingEmailStatus('error');
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          message.error(NOT_REGISTERED_OOKBEE_ERROR_MSG);
          setError(NOT_REGISTERED_OOKBEE_ERROR_MSG);
        } else {
          message.error(err.message);
          setError(err.message);
        }
        setIsValidOokbeeEmail(false);
        setValidatingEmailStatus('error');
      });
  };

  return (
    <div className="container">
      {!isEdit && <h3>Add New User</h3>}
      {isEdit && <h3>Edit User</h3>}
      {error && error.message && (
        <div>
          <Alert type="error" message={error.message}></Alert>
        </div>
      )}

      <Form {...extendedFormLayout} form={form} name="user-from" onFinish={onUpdateButtonClick}>
        {!isEdit && (
          <Form.Item
            name="email"
            label="Ookbee Account Email"
            validateStatus={validatingEmailStatus}
            // help={
            //   form.isFieldTouched("email") && !isValidOokbeeEmail
            //     ? "Email is not valid or not registered in Ookbee."
            //     : null
            // }
            hasFeedback={form.isFieldTouched('email')}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
          >
            <Input type="email" onBlur={validateOokbeeEmail} />
          </Form.Item>
        )}
        <Form.Item
          name="id"
          label="Ookbee Id"
          style={{ display: 'none' }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" disabled={true} />
        </Form.Item>
        <Form.Item
          name="userName"
          label="User Name"
          style={{ display: 'none' }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="displayName"
          label="Display Name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item label="Roles" name="roleId">
          <Select>
            {userRoles.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
          {/* {({ getFieldValue }) => {
            const roles = getFieldValue("roles") || [];
            // console.debug(adNetwork);
            if (!roles || roles.length === 0) return null;
            return roles.length ? (
              <>
                {roles.map((role, index) => (
                  <div key={index} className="role">
                    <Button
                      size="small"
                      onClick={() => onDeleteUserRoleMapping(role.id)}
                    >
                      <DeleteOutlined style={{ color: "red" }} />
                    </Button>
                    <span>{role.name}</span>
                  </div>
                ))}
              </>
            ) : (
              <div>None</div>
            );
          }} */}
        </Form.Item>
        <Form.Item {...extendedFooterFormLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={!isValidOokbeeEmail}>
            {isEdit ? 'Update' : 'Add User'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
