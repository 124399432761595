import React from 'react';
import { Form, Select } from 'antd';
import { DEFAULT_DROPDOWN_WIDTH } from '../../config';

export const SelectCampaignFormItem = React.memo(({ data, name, value, onChange, onBlur, error, allowChange = true, disabled, style }) => {
  return (
    <Form.Item label="Campaign" hasFeedback={false} validateStatus={error ? 'error' : 'success'}>
      <Select
        value={value}
        dropdownMatchSelectWidth={false}
        showSearch={false}
        style={{ width: DEFAULT_DROPDOWN_WIDTH, ...style }}
        disabled={!allowChange || disabled}
        placeholder={'Select Campaign'}
        onChange={(campaignId) => onChange({ target: { name: name, value: campaignId } })}
        onBlur={onBlur}
      >
        {data.map((campaign) => (
          <Select.Option key={campaign.id} value={campaign.id}>
            {campaign.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
});
