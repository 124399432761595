import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { DEFAULT_DROPDOWN_WIDTH, ApiEndpoint } from '../../config';
import { getData } from '../../api/helpers';

export const SelectAdGroup = ({
  value,
  data,
  onChange,
  showAllOption = false,
  allowChange = true,
  disabled = false,
  width = DEFAULT_DROPDOWN_WIDTH,
  filteredAdGroupId,
}) => {
  const [adGroups, setAdGroups] = useState([]);

  useEffect(() => {
    const loadAdGroups = async () => {
      const { data } = await getData(`${ApiEndpoint.AdGroup.List}`, null);
      if (data) {
        let result = data.items;

        setAdGroups(result);
      }
    };

    if (data === null || data === undefined) {
      loadAdGroups();
    } else {
      setAdGroups(data);
    }
  }, [data, filteredAdGroupId]);

  return (
    <Select
      value={value ?? filteredAdGroupId}
      dropdownMatchSelectWidth={false}
      showSearch={false}
      style={{ width }}
      disabled={!allowChange || disabled}
      placeholder={showAllOption ? 'Filter by Placement' : 'Select Placement'}
      onChange={onChange}
    >
      {adGroups.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.publisher.name} ({item.publisher.countryCode}) - {item.name} [{item.adGroupType.name}]
        </Select.Option>
      ))}
    </Select>
  );
};
