import React from 'react';
import { Button } from 'antd';
import moment from 'moment';

export const SetThisMonthButton = React.memo(({ setFilter, filter }) => (
  <Button
    type="link"
    size="small"
    onClick={() =>
      setFilter({
        ...filter,
        periods: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      })
    }
  >
    This Month
  </Button>
));
