import { getApiUrl, getUserAudienceManagementApiUrl } from '../config';
import { message } from 'antd';
import axios from './axios';

export function encodeQueryString(src) {
  return src.replaceAll('+', '%2B');
}

export const getData = async (path, onError = null) => {
  try {
    const response = await axios.get(getApiUrl() + encodeQueryString(path));
    return response.data;
  } catch (error) {
    const { data: errorData } = error.response;
    if (error.response.status === 404 && errorData.errors && Array.isArray(errorData.errors) && errorData.errors[0] === 'Data not found.') {
      // console.debug(error.response.data);
      return { data: { items: [] } };
    }
    message.error(error.message, 10);
    if (onError) {
      console.error(error);
      onError('Error loading data: ' + error.message);
    }
    return { error, data: null };
  }
};

export const getUserSummaryData = async (path, onError = null) => {
  try {
    const response = await axios.get(getUserAudienceManagementApiUrl() + '/user-summary-reports' + encodeQueryString(path), {
      headers: {
        'X-Ob-Api-Key': '9df734ef-3bcb-4377-8129-fac60c73ea3e',
        'X-Api-Key': '9df734ef-3bcb-4377-8129-fac60c73ea3e',
      },
    });
    return response.data;
  } catch (error) {
    console.debug(error);
    console.error(error);
    throw error;
    // const { data: errorData } = error;
    // if (error.response.status === 404 && errorData.errors && Array.isArray(errorData.errors) && errorData.errors[0] === 'Data not found.') {
    //   // console.debug(error.response.data);
    //   return { data: { items: [] } };
    // }
    // message.error(error.message, 10);
    // if (onError) {
    //   console.error(error);
    //   onError('Error loading data: ' + error.message);
    // }
    // return { error, data: null };
  }
};

export const getDataById = async (path, id, onError = null, optionalParams = '') => {
  try {
    const response = await axios.get(getApiUrl() + path + '/' + id + encodeQueryString(optionalParams));
    return response.data;
  } catch (error) {
    message.error(error.message, 10);
    if (onError) {
      console.error(error);
      onError('Error loading data: ' + error.message);
    }
    return { error };
  }
};

export const postData = async (path, postData, onError = null, successMessage = 'Create successful') => {
  try {
    const response = await axios.post(getApiUrl() + path, postData);
    if (successMessage !== '-') message.success(successMessage);
    return { data: response.data };
  } catch (error) {
    const { data } = error.response;
    if (data) {
      if (Array.isArray(data.errors)) {
        data.errors.forEach((err) => {
          message.error(err);
        });
      }
      if (onError) {
        onError(data.errors);
      }
    } else {
      message.error(error.message);
    }
    return { error };
  }
};

export const deleteData = async (path, id, onError = null) => {
  try {
    const response = await axios.delete(getApiUrl() + path + (id ? '/' + id : ''));
    message.success('Deleted item successful');
    return { data: response.data, error: null };
  } catch (error) {
    const { data } = error.response;
    if (data) {
      if (Array.isArray(data.errors)) {
        data.errors.forEach((err) => {
          message.error(err);
        });
      }
      if (onError) {
        onError(data.errors);
      }
    } else {
      message.error(error.message);
    }
    return { data: null, error };
  }
};

export const putData = async (path, id, postData, onError = null, successMessage = 'Update Successful') => {
  try {
    const response = await axios.put(getApiUrl() + path + '/' + id, postData);
    message.success(successMessage);
    return { data: response.data, error: null };
  } catch (error) {
    const { data } = error.response;
    if (data) {
      if (Array.isArray(data.errors) && data.errors.length > 0) {
        data.errors.forEach((err) => {
          message.error(err);
          if (onError) onError(err);
        });
      } else {
        if (onError) onError(data.message);
        message.error(data.message);
      }
    } else {
      message.error(error.message);
    }
    return { data: null, error };
    // if (onError) {
    //   onError(error);
    // }
  }
};

// export function fileReader(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       resolve(reader.result);
//     };

//     reader.readAsArrayBuffer(file);
//   });
// }

export function fileReader(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export function transformToValue(items, propertyName) {
  const transform = [];
  items.forEach((i) => {
    transform.push({
      name: i.name,
      value: i[propertyName],
    });
  });

  return transform;
}
