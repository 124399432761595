import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldArray } from 'formik';

const MyAntFormMultipleInputItem = ({ value = [], name = null, onChange = null, label = null }) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <>
        {value && Array.isArray(value) && value.length > 0 ? (
          value.map((url, index) => (
            <Form.Item key={index} label={label} required>
              {/* <Field name={`analytics.${index}`} />
               */}
              <Input id={`${name}.${index}`} value={url} style={{ width: 'auto', minWidth: 250 }} onChange={onChange} required />
              <Button
                type="link"
                style={{ padding: 8 }}
                danger
                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
              >
                <MinusCircleOutlined />
              </Button>
              <Button
                type="link"
                style={{ padding: 8 }}
                onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
              >
                <PlusCircleOutlined />
              </Button>
            </Form.Item>
          ))
        ) : (
          <Form.Item label={label}>
            <Button type="ghost" onClick={() => arrayHelpers.push('')}>
              <PlusOutlined /> Click to Add
            </Button>
          </Form.Item>
        )}
      </>
    )}
  />
);

MyAntFormMultipleInputItem.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export const AntFormMultipleInputItem = React.memo(MyAntFormMultipleInputItem);
