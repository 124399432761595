import { Spin } from 'antd';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, Legend } from 'recharts';

import Widget from '../../../components/Widget';
import { chartColor } from '../../../config/chartColor';

const COLORS = [chartColor.DarkPink, '#FA8C16', '#f5222d', '#d9d9d9'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" fontSize={14} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const PlatformDistributionChart = ({ title, isLoading = false, data = [], height = 125, innerRadius = 20, outerRadius = 45 }) => {
  return (
    <Widget title={title} styleName="gx-text-center">
      {isLoading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {!isLoading && (
        <ResponsiveContainer width="100%" height={height}>
          <PieChart>
            <Tooltip />
            <Legend verticalAlign="middle" align="right" layout="vertical" iconSize={10} />
            {/* <text
            x="50%"
            className="h1"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            %
          </text> */}
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    </Widget>
  );
};
