export const standardFooterFormLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export const extendedFooterFormLayout = {
  wrapperCol: {
    offset: 9,
    span: 15,
  },
};
