import React from 'react';
import { Row, Col } from 'antd';

const Page = ({ children, title }) => {
  return (
    <div className="container">
      <Row>
        <Col xs={24}>
          <h3>{title}</h3>
        </Col>
      </Row>
      {children}
    </div>
  );
};

export default Page;
