import React from 'react';
import { Select, Form } from 'antd';
import { DEFAULT_DROPDOWN_WIDTH } from '../../../config';

export const SelectAdMinimumDisplayFormItem = ({ style, value, onChange, adType = 'video' }) => {
  return (
    <Form.Item
      label="Minimum Display"
      tooltip="แสดงปุ่ม “ปิด | X” หลังแสดง ad ตามวินาทีที่กำหนด (ไม่กำหนด แสดงปุ่ม “ปิด | X” ทันทีเมื่อเปิด Ad, -1 ตาม Video Length)"
    >
      <Select
        name="format.minimumDisplay"
        id="minimumDisplay"
        onChange={onChange}
        value={value}
        style={{ width: DEFAULT_DROPDOWN_WIDTH, ...style }}
      >
        {adType === 'video' && <Select.Option value={-1}>Video Length</Select.Option>}
        <Select.Option value={0}>None</Select.Option>
        <Select.Option value={3}>3 Seconds</Select.Option>
        <Select.Option value={4}>4 Seconds</Select.Option>
        <Select.Option value={5}>5 Seconds</Select.Option>
        <Select.Option value={6}>6 Seconds</Select.Option>
        <Select.Option value={7}>7 Seconds</Select.Option>
        <Select.Option value={8}>8 Seconds</Select.Option>
        <Select.Option value={9}>9 Seconds</Select.Option>
        <Select.Option value={10}>10 Seconds</Select.Option>
        <Select.Option value={12}>12 Seconds</Select.Option>
        <Select.Option value={15}>15 Seconds</Select.Option>
        <Select.Option value={20}>20 Seconds</Select.Option>
      </Select>
    </Form.Item>
  );
};
