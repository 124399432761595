import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Card, Button } from 'antd';
import { getData } from '../../api/helpers';
import { IS_PRODUCTION } from '../../config';
import { DailyChart } from './components/DailyChart';
import { generateDayChartData } from './helpers/generateDayChartData';
import { ReloadOutlined } from '@ant-design/icons';
import { SelectPublisher } from '../Forms/SelectPublisher';
import { useStore } from '../../context/AppContext';
import ReactExport from 'react-data-export';
import moment from 'moment';

export const PublisherReport = () => {
  const [filter, setFilter] = useState({
    publisherId: null,
    periods: [null, null],
    reload: 0,
  });

  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [dailyChartData, setDailyChartData] = useState([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [appState] = useStore();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const loadPublisherReportByPublisherId = useCallback(async (publisherId, periods) => {
    setIsLoadingChartData(true);
    const { data: chartSummaryData } = await getData(
      '/report/publisher/' + publisherId + '/inhouse?start=' + periods[0] + '&end=' + periods[1],
    );

    console.debug(chartSummaryData);
    if (chartSummaryData && chartSummaryData.items) {
      const dailyChartData = generateDayChartData(periods, chartSummaryData.items);
      setDailyChartData(dailyChartData);
    }

    setIsLoadingChartData(false);
  }, []);

  // When selected publisher from dropdown changed
  useEffect(() => {
    if (filter.publisherId) {
      setSelectedPublisher(appState.publishers.find((publisher) => publisher.id === Number(filter.publisherId)));
    }
  }, [appState.publishers, filter.publisherId]);

  // When all filter has value
  useEffect(() => {
    console.debug('filter changed');

    if (filter.publisherId && filter.periods[0] && filter.periods[1]) {
      loadPublisherReportByPublisherId(filter.publisherId, filter.periods);
    }
  }, [filter.publisherId, filter.periods, filter.reload, loadPublisherReportByPublisherId]);

  return (
    <div className="container">
      <Row style={{ marginBottom: 8 }}>
        <Col xs={24} lg={24}>
          <h2>Ookbee Ad Report by Publisher</h2>
          <Row gutter={24}>
            <Col xs={24} md={14} lg={16}>
              <Card hoverable className="gx-card-widget" title="Filter" type="inner">
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24}>
                    <SelectPublisher
                      style={{ width: '100%' }}
                      value={filter.publisherId}
                      onChange={(id) => setFilter({ ...filter, publisherId: id })}
                      allowChange={!isLoadingChartData}
                    />
                  </Col>
                </Row>
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col xs={21}>
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      disabled={isLoadingChartData}
                      value={[filter.periods[0] ? moment(filter.periods[0]) : null, filter.periods[1] ? moment(filter.periods[1]) : null]}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          periods: [value ? value[0].format('YYYY-MM-DD') : null, value ? value[1].format('YYYY-MM-DD') : null],
                        })
                      }
                    />
                  </Col>
                  <Col xs={3}>
                    <Button
                      type="default"
                      disabled={isLoadingChartData}
                      style={{ width: '100%' }}
                      onClick={() =>
                        setFilter({
                          ...filter,
                          reload: !isNaN(filter.reload) ? filter.reload + 1 : 0,
                        })
                      }
                    >
                      <ReloadOutlined spin={isLoadingChartData} />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={10} lg={8}>
              {/* <PlatformDistributionChart
                title="Platform distribution"
                isLoading={isLoadingPlatformData}
                height={140}
                innerRadius={20}
                outerRadius={60}
                data={platformDistributionData}
              /> */}
            </Col>
          </Row>
          <DailyChart reportData={dailyChartData} chartName="Impression/Day" isLoading={isLoadingChartData} />
          {filter.publisherId && filter.periods[0] && filter.periods[1] && (
            <ExcelFile
              filename={selectedPublisher.name}
              element={
                <Button type="primary" disabled={isLoadingChartData} style={{ marginTop: 8 }}>
                  Export to Excel
                </Button>
              }
            >
              <ExcelSheet data={dailyChartData} name={selectedPublisher.name}>
                <ExcelColumn label="Day" value="day" width={{ wpx: 50 }} style={{ width: 150 }} />
                <ExcelColumn label="Impressions" value="total" width={150} />
              </ExcelSheet>
            </ExcelFile>
          )}
        </Col>
      </Row>
      {!IS_PRODUCTION && <pre>{JSON.stringify(filter, null, 2)}</pre>}
      {!IS_PRODUCTION && <pre>{JSON.stringify(selectedPublisher, null, 2)}</pre>}
    </div>
  );
};
