import React from 'react';
import { Modal } from 'antd';
import { SelectCreator } from './SelectCreator';

export const SelectCreatorPresetModal = React.memo(
  ({ creatorModalVisible, setCreatorModalVisible, creatorId, onChangeCreatorId, isLoadingChartData }) => (
    <Modal
      title="Select by Creator Name"
      visible={creatorModalVisible}
      cancelButtonProps={{ disabled: true }}
      onCancel={() => setCreatorModalVisible(false)}
      onOk={() => setCreatorModalVisible(false)}
    >
      <SelectCreator style={{ width: '100%' }} value={creatorId} onChange={onChangeCreatorId} allowChange={!isLoadingChartData} />
    </Modal>
  ),
);
