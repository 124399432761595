// const API_BASE_URL_DEV_HTTPS = "http://ads-bkk-dev-1.qc:8101/api";
export const AdPosition = {
  Default: 'Center',
  Center: 'Center',
  Left: 'Left',
  Right: 'Right',
  ActionImage: 'ActionImage',
  Banner: 'BANNER',
  Video: 'Video',
  Logo: 'Logo',
};
