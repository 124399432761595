export function validateAdForm() {
  return (values) => {
    console.debug(values);
    const errors = {};
    const isEdit = values.id !== undefined;
    const adGroupType = values.adUnit?.adGroup?.adGroupType.name.toLowerCase();

    if (!isEdit && adGroupType) {
      // When create a new Ad, check image upload from input
      if (adGroupType === 'mrec' && values.Center === undefined) {
        errors.images = 'At least 1 image is required for mRec banner!';
      }
      if (adGroupType === 'banner') {
        if (values.Center?.SQUARE === undefined && values.Center?.MEDIUM_REC === undefined) {
          errors.images = 'At least 1 image is required for adaptive banner!';
        }
      }
      if (adGroupType === 'tab' && values.Center === undefined) {
        errors.images = 'Center image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Left === undefined) {
        errors.images = 'Left image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Right === undefined) {
        errors.images = 'Right image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Right === undefined) {
        errors.images = 'Right image is required for tab chat banner!';
      }
    }

    if (isEdit && values.mediaFiles?.length <= 0) {
      // When edit existing Ad, check image upload from input
      if (adGroupType === 'mrec' && values.Center === undefined) {
        errors.images = 'At least 1 image is required for mRec banner!';
      }
      if (adGroupType === 'banner' && values.Center === undefined) {
        errors.images = 'At least 1 image is required for adaptive banner!';
      }
      if (adGroupType === 'tab' && values.Center === undefined) {
        errors.images = 'Center image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Left === undefined) {
        errors.images = 'Left image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Right === undefined) {
        errors.images = 'Right image is required for tab chat banner!';
      }
      if (adGroupType === 'tab' && values.Right === undefined) {
        errors.images = 'Right image is required for tab chat banner!';
      }
    }

    const fullRGBRegex = new RegExp('^#([a-f]|[A-F]|[0-9]){6}$');

    if (values.format?.backgroundColor && !fullRGBRegex.test(values.format.backgroundColor)) {
      errors.backgroundColor = 'Background color is not valid (eg #ab00cc)';
    }

    if (values.format?.color && !fullRGBRegex.test(values.format.color)) {
      errors.color = 'Color is not valid (eg #ab00cc)';
    }

    if (!values.name) {
      errors.name = 'Ad Name is required';
    }

    if (!values.description) {
      errors.description = 'Ad Description is required';
    }

    if (!values.adUnitId) {
      errors.adUnitId = 'Please select Ad Unit';
    }

    if (!values.advertiserId) {
      errors.advertiserId = 'Please select Advertiser';
    }

    if (!values.campaignId) {
      errors.campaignId = 'Please select Campaign';
    }

    if (!values.startAt) {
      errors.startAt = 'Please select Ad start date';
    }

    if (!values.endAt) {
      errors.endAt = 'Please select Ad end date';
    }

    if (!values.quota) {
      errors.quota = 'Please specify impression quota limit';
    }

    return errors;
  };
}
