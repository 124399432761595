/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Table, Card, Spin, Button } from 'antd';
import { getData, getDataById, transformToValue } from '../../api/helpers';
import { SelectAdGroup } from '../Forms/SelectAdGroup';
import { ApiEndpoint } from '../../config';
import { groupReportTableColumn } from './tableColumns/groupReportTableColumn';
import { getTotalImpressionAndClick } from './helpers/getTotalImpressionAndClick';
import { generateGroupRequestChartData } from './helpers/generateDayChartData';
import { SelectPublisher } from '../Forms/SelectPublisher';
import { ReloadOutlined } from '@ant-design/icons';
import { DailyChartGroupRequestLog } from './components/DailyChartGroupRequestLog';
import moment from 'moment';

// const mapChartColor = {
//   Ookbee: ChartColor.DarkPink,
//   Admob: ChartColor.DarkPink,
//   AppOdeal: ChartColor.LightGreen,
//   Available: ChartColor.Available,
// };

export const GroupReportByGroupId = () => {
  const [filter, setFilter] = useState({
    publisherId: null,
    adGroupId: null,
    periods: [null, null],
    reload: 1,
  });
  const [dailyChartData, setDailyChartData] = useState([]);
  const [adUnitSummary, setAdUnitSummary] = useState([]);
  const [adGroups, setAdGroups] = useState([]);
  const [platformDistributionData, setPlatformDistributionData] = useState([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [isLoadingPlatformData, setIsLoadingPlatformData] = useState(false);
  const [isLoadingAdUnitImpression, setIsLoadingAdUnitImpression] = useState(false);

  // เมื่อเลือก Publisher แล้วจะ Load Ad Group List
  const loadAdGroupList = useCallback(async (publisherId) => {
    const { data } = await getData(ApiEndpoint.AdGroup.List + (publisherId ? '&publisherId=' + publisherId : ''));
    if (data && data.items) {
      setAdGroups(data.items);
    }
  }, []);

  // เมื่อเลือก AdGroup ค่อยดึง Report
  const loadAdGroupReport = useCallback(async (adGroupId, periods) => {
    setIsLoadingChartData(true);
    setDailyChartData([]);
    const start = moment(periods[0]).add(-1, 'day').format('YYYY-MM-DD');
    const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');
    const { data } = await getData('/report/group/' + adGroupId + '?start=' + start + '&end=' + periods[1]);

    // โหลด AdUnit Id ที่อยู่ใน AdGroup นี้
    const { data: adUnits } = await getData(ApiEndpoint.AdUnit.List + '&adGroupId=' + adGroupId);

    if (data && data.items) {
      const adUnitImpressions = {};
      for await (const adUnit of adUnits.items) {
        const { data: adUnitImpressionData } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `?start=${periods[0]}&end=${endPeriods}`,
        );
        const adUnitName = adUnit.adNetwork.name;
        console.debug(adUnitImpressionData);
        adUnitImpressions[adUnitName] = adUnitImpressionData.items;
      }
      const chartData = generateGroupRequestChartData(
        periods,
        data.items,
        adUnitImpressions.ookbee ?? [],
        adUnitImpressions.admob ?? [],
        adUnitImpressions.appodeal ?? [],
        adUnitImpressions.huawei ?? [],
      );
      setDailyChartData(chartData);
    }
    setIsLoadingChartData(false);
  }, []);

  const loadAdGroupPlatformReport = useCallback(async (adGroupId, periods) => {
    setPlatformDistributionData([]);
    setIsLoadingPlatformData(true);
    const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');
    const { data } = await getData('/report/group/' + adGroupId + '/platform?start=' + periods[0] + '&end=' + endPeriods);
    if (data && data.items) {
      const items = transformToValue(data.items, 'total');
      setPlatformDistributionData(items);
      setIsLoadingPlatformData(false);
    }
  }, []);

  const loadAdUnitsByGroup = useCallback(async (adGroupId, periods) => {
    setIsLoadingAdUnitImpression(true);
    setAdUnitSummary([]);
    const { data: adUnits } = await getData(ApiEndpoint.AdUnit.List + '&adGroupId=' + adGroupId);
    if (adUnits && adUnits.items) {
      const adUnitSummaryData = [];
      const endPeriods = moment(periods[1]).format('YYYY-MM-DDT00:00:00[Z]');
      for await (const adUnit of adUnits.items) {
        // Load Ad Stat By Each Unit
        const { data: adUnitImpressionData } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `?start=${periods[0]}&end=${endPeriods}`,
        );

        const { data: impressionDataAndroid } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `?start=${periods[0]}&end=${endPeriods}&platformId=1`,
        );
        const totalImpressionAndroid = impressionDataAndroid?.items.reduce((acc, cur) => {
          return acc + cur.total;
        }, 0);

        const { data: impressionDataIos } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `?start=${periods[0]}&end=${endPeriods}&platformId=2`,
        );
        const totalImpressionIos = impressionDataIos?.items.reduce((acc, cur) => {
          return acc + cur.total;
        }, 0);

        const { data: impressionDataHuawei } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `?start=${periods[0]}&end=${endPeriods}&platformId=4`,
        );
        const totalImpressionHuawei = impressionDataHuawei?.items.reduce((acc, cur) => {
          return acc + cur.total;
        }, 0);

        const { data: adUnitClickData } = await getDataById(
          ApiEndpoint.Report.AdUnitReport,
          adUnit.id,
          null,
          `/click?start=${periods[0]}&end=${endPeriods}`,
        );
        const summaryData = {
          adGroupName: adUnit.adGroup.name,
          adUnitId: adUnit.id,
          adUnitName: adUnit.adNetwork.name,
          totalImpressionAndroid,
          totalImpressionIos,
          totalImpressionHuawei,
          ...getTotalImpressionAndClick(adUnitImpressionData?.items, adUnitClickData?.items),
        };
        adUnitSummaryData.push(summaryData);
      }
      console.debug('setAdUnitSummary');
      setAdUnitSummary(adUnitSummaryData);
      console.debug('setIsLoadingAdUnitImpression');
      setIsLoadingAdUnitImpression(false);
    }
  }, []);

  useEffect(() => {
    if (filter.publisherId) {
      setAdGroups([]);
      loadAdGroupList(filter.publisherId);
    }
  }, [filter.publisherId, loadAdGroupList]);

  useEffect(() => {
    console.debug('reloading...');
    if (filter.adGroupId && filter.periods[0] && filter.periods[1]) {
      setAdUnitSummary([]);
      loadAdGroupReport(filter.adGroupId, filter.periods);
      loadAdUnitsByGroup(filter.adGroupId, filter.periods);
      // loadAdGroupPlatformReport(filter.adGroupId, filter.periods);
    }
  }, [filter.adGroupId, filter.periods, filter.reload, loadAdGroupReport, loadAdUnitsByGroup]);

  return (
    <div className="container">
      <Row style={{ marginBottom: 8 }}>
        <Col xs={24} lg={24}>
          <h2>Group/Unit Report</h2>
          <Row gutter={24}>
            <Col xs={24} md={24} lg={16}>
              <Card hoverable className="gx-card-widget" title="Report Filter" type="inner">
                <Row gutter={12}>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <SelectPublisher
                      style={{ width: '100%' }}
                      value={filter.publisherId}
                      onChange={(id) => setFilter({ ...filter, publisherId: id })}
                      allowChange={!isLoadingPlatformData || !isLoadingChartData}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <SelectAdGroup
                      width="100%"
                      data={adGroups}
                      value={filter.adGroupId}
                      disabled={isLoadingPlatformData || isLoadingChartData}
                      onChange={(value) => setFilter({ ...filter, adGroupId: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col xs={22}>
                    <DatePicker.RangePicker
                      disabled={isLoadingChartData || isLoadingPlatformData}
                      style={{ width: '100%' }}
                      picker="date"
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          periods: [
                            value ? value[0]?.format('YYYY-MM-DDT00:00:00Z') : null,
                            value ? value[1]?.format('YYYY-MM-DDT00:00:00Z') : null,
                          ],
                        })
                      }
                    />
                  </Col>
                  <Col xs={2}>
                    <Button
                      type="default"
                      disabled={isLoadingChartData || isLoadingPlatformData}
                      style={{ width: '100%' }}
                      onClick={() =>
                        setFilter({
                          ...filter,
                          reload: !isNaN(filter.reload) ? filter.reload + 1 : 1,
                        })
                      }
                    >
                      <ReloadOutlined spin={isLoadingPlatformData || isLoadingPlatformData} />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={10} lg={8}>
              {/* <PlatformDistributionChart
                title="Request by Platform"
                isLoading={isLoadingPlatformData}
                height={140}
                innerRadius={20}
                outerRadius={60}
                data={platformDistributionData}
              /> */}
            </Col>
          </Row>
          <Row style={{ height: 40, backgroundColor: 'white', padding: 15 }}>
            <Col xs={12}>
              {/* <Checkbox.Group
                options={adNetworkOptions}
                defaultValue={adNetworkOptions}
                onChange={onAdNetworkCheckedChange}
              /> */}
            </Col>
            <Col
              xs={12}
              style={{
                display: 'flex',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                flexDirection: 'column',
                right: 0,
              }}
            >
              {isLoadingChartData && (
                <div>
                  <Spin />
                </div>
              )}
            </Col>
          </Row>
          <DailyChartGroupRequestLog reportData={dailyChartData} />
          <Table
            loading={isLoadingAdUnitImpression}
            style={{ marginTop: 25 }}
            dataSource={adUnitSummary}
            rowKey="adUnitId"
            columns={groupReportTableColumn}
            pagination={false}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col flex>
          <Button type="primary">
            <ExportOutlined />
            Export Stats to Excel
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};
