import React from 'react';
import { Select, Form } from 'antd';

export const SelectAdTemplateFormItem = ({ style, value, onChange, disabled }) => {
  return (
    <Form.Item label="Ad Template">
      <Select disabled={disabled} style={{ width: '100%', ...style }} value={value} onChange={onChange} defaultValue={1}>
        <Select.Option value={1}>1 (with Logo)</Select.Option>
        <Select.Option value={2}>2 (without Logo)</Select.Option>
        <Select.Option value={3}>3 (Full screen)</Select.Option>
      </Select>
    </Form.Item>
  );
};
