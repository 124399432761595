import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Popconfirm, Alert, Button } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { deleteData, getData } from '../../api/helpers';
import { ApiEndpoint } from '../../config';

const userListTableColumn = ({ onDelete }) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text, record) => <Link to={`/user/${record.id}`}>{text}</Link>,
  },
  {
    title: 'Username',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    key: 'roleName',
  },
  // {
  //   title: "Role",
  //   dataIndex: "role",
  //   key: "role",
  //   render: (text, record) => <Link to={`/campaign/${record.id}`}>{text}</Link>,
  // },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Popconfirm title="Are you sure?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => onDelete(record.id)}>
        <Button size="small" danger>
          Delete
        </Button>
      </Popconfirm>
    ),
  },
];

export const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserList = async () => {
      const { data } = await getData(ApiEndpoint.User.List, setError);
      if (data && data.items) {
        setUsers(data.items);
      }
    };

    loadUserList();
  }, []);

  if (error) {
    return (
      <div className="container">
        <Alert type="error" message={error}></Alert>
      </div>
    );
  }

  const onDeleteUser = async (id) => {
    deleteData(ApiEndpoint.User.Delete, id, null);
    setUsers(users.filter((item) => item.id !== id));
  };

  return (
    <div className="container">
      <Row>
        <Col xs={24}>
          <h3>Users</h3>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={24}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link className="ant-btn ant-btn-primary" to="/user/create">
              <PlusOutlined style={{ marginRight: 5 }} />
              New User
            </Link>
          </div>
        </Col>
      </Row>
      <Table columns={userListTableColumn({ onDelete: onDeleteUser })} rowKey="id" dataSource={users} pagination={false} />
    </div>
  );
};
