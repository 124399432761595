import React from 'react';
import { useStore } from '../../../context/AppContext';

const Summary = ({ totalAdvertiser = 0, totalCampaigns = 0, totalAds = 0, pendingApproval = 0, pendingPreview = 0 }) => {
  const [appState] = useStore();

  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h2 className="gx-mb-2">Welcome {appState.user.displayName}</h2>
      <ul className="gx-list-group">
        <li>
          <span>
            <strong>{totalAdvertiser}</strong> Advertiser
          </span>
        </li>
        <li>
          <span>
            <strong>{totalCampaigns}</strong> Active campaigns
          </span>
        </li>
        <li>
          <span>
            <strong>{totalAds}</strong> Active banners
          </span>
        </li>
        <li>
          <span>
            <strong>{pendingApproval}</strong> Pending approval ads
          </span>
        </li>
        <li>
          <span>
            <strong>{pendingPreview}</strong> Pending preview ads
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Summary;
