import React from 'react';

export const creatorImpressionBreakdown = [
  {
    title: 'Content Id',
    dataIndex: 'contentId',
    key: 'contentId',
    render: (value) => <span>{value}</span>,
  },
  {
    title: 'Impression',
    dataIndex: 'impression',
    key: 'impression',
    render: (value) => <span>{value?.toLocaleString()}</span>,
  },
  {
    title: 'Click',
    dataIndex: 'click',
    key: 'click',
    render: (value) => <span>{value ? value.toLocaleString() : 0}</span>,
  },
];
