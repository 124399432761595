import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

export const DateRangePicker = React.memo(({ isLoadingChartData, filter, setFilter, dateValueFormat = 'YYYY-MM-DD' }) => (
  <DatePicker.RangePicker
    style={{ width: '100%' }}
    showHour={false}
    showNow={true}
    disabled={isLoadingChartData}
    value={[filter.periods[0] ? moment(filter.periods[0]) : null, filter.periods[1] ? moment(filter.periods[1]) : null]}
    onChange={(value) =>
      setFilter({
        ...filter,
        periods: [value ? value[0].format(dateValueFormat) : null, value ? value[1].format(dateValueFormat) : null],
      })
    }
  />
));
