import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiEndpoint } from '../../config';
import { getData } from '../../api/helpers';
import { useStore } from '../../context/AppContext';
import { useRouter } from '../../hooks/useRouter';

import { SelectAdvertiser } from '../Forms/SelectAdvertiser';
import { campaignListTableColumns } from './campaignListTableColumns';

export const CampaignList = () => {
  const [appState] = useStore();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const { navigate, query } = useRouter();
  const { advertisers } = appState;
  let { advertiserId } = query;

  const loadCampaignList = useCallback(async (advertiserId) => {
    const url = advertiserId ? `${ApiEndpoint.Campaign.List}&advertiserId=${advertiserId}` : ApiEndpoint.Campaign.List;
    const { data, error } = await getData(url, setError);

    if (error) {
      setError(error);
    } else {
      if (data.items && Array.isArray(data.items)) {
        setCampaigns(data.items.sort((a, b) => b.id - a.id));
      }
    }
    setIsLoading(false);
    return data.items;
  }, []);

  const onSelectedAdvertiserChange = async (selectedAdvertiserId) => {
    if (selectedAdvertiserId) {
      navigate(`/advertiser/${selectedAdvertiserId}/campaign`);
    } else {
      navigate(`/campaign`);
    }
  };

  useEffect(() => {
    // ในกรณีที่เปิดหน้านี้ครั้งแรก จะรอให้ AppState โหลด Advertisers ก่อน ไม่งั้นจะเกิดการ fetching ซ้ำ เนื่องจาก hook นี้ depend on advertisers
    if (advertisers.length > 0) {
      setIsLoading(true);

      if (advertiserId) {
        setSelectedAdvertiser(advertisers.find((adv) => adv.id === advertiserId));
        loadCampaignList(advertiserId);
      } else {
        loadCampaignList();
      }
    }
  }, [advertiserId, advertisers, loadCampaignList]);

  return (
    <div className="container">
      <Row>
        <Col xs={24}>
          {selectedAdvertiser && <h3>Campaign List for {selectedAdvertiser.name}</h3>}
          {!selectedAdvertiser && <h3>Manage Campaigns</h3>}
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={18}>
          <SelectAdvertiser
            showAllOption={true}
            onChange={onSelectedAdvertiserChange}
            selectedValue={advertiserId ? Number(advertiserId) : null}
          />
        </Col>
        <Col xs={6}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 0,
              fontWeight: 'bold',
              width: 'max-content',
            }}
          >
            <Link
              className="ant-btn ant-btn-primary"
              to={advertiserId ? `/campaign/create?advertiserId=${advertiserId}` : `/campaign/create`}
            >
              <PlusOutlined style={{ marginRight: 5 }} />
              New Campaign
            </Link>
          </div>
        </Col>
      </Row>
      {error && (
        <div>
          <Alert type="error" message={error} />
        </div>
      )}
      <Table size="small" rowKey="id" loading={isLoading} columns={campaignListTableColumns} dataSource={campaigns} pagination={false} />
    </div>
  );
};
