/* eslint-disable no-unused-vars */
import React, { createContext, useState } from 'react';

// interface ModalsContext {
//   isOpen: boolean;
//   nodeId: string;
//   modalNode: React.ReactNode;
//   setModalNode: React.Dispatch<React.SetStateAction<React.ReactNode>>;
//   onPresent: (node: React.ReactNode, newNodeId: string) => void;
//   onDismiss: Handler;
//   setCloseOnOverlayClick: React.Dispatch<React.SetStateAction<boolean>>;
// }

export const ModalContext = createContext({
  isOpen: false,
  nodeId: '',
  modalNode: null,
  setModalNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
});

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNode, setModalNode] = useState();
  const [nodeId, setNodeId] = useState('');
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

  const handlePresent = (node, newNodeId) => {
    setModalNode(node);
    setIsOpen(true);
    setNodeId(newNodeId);
  };

  const handleDismiss = () => {
    setModalNode(undefined);
    setIsOpen(false);
    setNodeId('');
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        nodeId,
        modalNode,
        setModalNode,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        setCloseOnOverlayClick,
      }}
    >
      {isOpen && (
        <>
          {/* Need to provide custom Overlay component <Overlay show onClick={handleOverlayDismiss} /> */}
          {React.isValidElement(modalNode) &&
            React.cloneElement(modalNode, {
              onDismiss: handleDismiss,
              onCancel: handleDismiss,
            })}
        </>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
