import React from 'react';
import { AntFormInputItem } from '../../Forms/AntFormInputItem';
import { useWhyDidYouUpdate } from '../../../hooks/useWhyDidYouUpdate';

const MyFormItem = ({ label, name, style, value, onChange, onBlur, error }) => {
  useWhyDidYouUpdate(`InputColorPickerFormItem[${name}]`, { label, name, style, value, onChange, onBlur, error });
  return (
    <AntFormInputItem
      label={label}
      name={name}
      placeholder="RGB Hex Color eg. #338800"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      style={{ width: '100%', ...style }}
      error={error}
      help={error}
      required
      suffix={
        <div
          style={{
            fontSize: 16,
            width: 35,
            height: 18,
            borderRadius: 6,
            backgroundColor: value,
          }}
        />
      }
    />
  );
};

export const InputColorPickerFormItem = React.memo(MyFormItem);
