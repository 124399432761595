import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Tooltip } from 'antd';
import {
  HomeFilled,
  ContainerFilled,
  AppstoreFilled,
  PieChartFilled,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useStore } from '../context/AppContext';
import { CMS_ACTION } from '../context/actionsType';
import { getMenuBackgrounColor } from '../config';
import { useRouter } from '../hooks/useRouter';
import Can from '../components/Can';
// import { useWhyDidYouUpdate } from "../hooks/useWhyDidYouUpdate";

const { SubMenu } = Menu;
const menuColor = getMenuBackgrounColor(); // Menu background color will be different on Production/Development

export const LeftMenu = () => {
  const [appState, dispatch] = useStore();
  const { location, navigate } = useRouter();
  const { firstName, lastName, roleName, displayName } = appState.user;
  const isAgency = location?.pathname.toLowerCase().indexOf('agency') >= 0;

  if (isAgency) {
    return null;
  }

  return (
    <Menu
      defaultSelectedKeys={[]}
      defaultOpenKeys={[]}
      subMenuCloseDelay={250}
      subMenuOpenDelay={250}
      mode="inline"
      theme="dark"
      inlineCollapsed={false}
      style={{ width: 210, background: menuColor }}
    >
      <Menu.Item key="user-profile">
        <div>
          <Tooltip title={`${firstName} ${lastName} (${roleName})`}>
            <span className="text-bold" style={{ color: 'white' }}>
              Welcome {firstName}
            </span>
          </Tooltip>
        </div>
      </Menu.Item>
      <Menu.Item key="home">
        <HomeFilled />
        <Link to="/" style={{ marginLeft: 10 }}>
          Dashboard
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub2-campaign"
        title={
          <span>
            <ContainerFilled />
            <span>Campaign</span>
          </span>
        }
      >
        <Menu.Item key="10">
          <Link to="/campaign">Manage Campaign</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub-banner"
        title={
          <span>
            <AppstoreFilled />
            <span>Ads</span>
          </span>
        }
      >
        <Menu.Item key="manage-ads">
          <Link to="/ads">Manage Ads</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub-advertiser"
        title={
          <span>
            <AppstoreFilled />
            <span>Advertiser</span>
          </span>
        }
      >
        <Menu.Item key="new-advertiser">
          <Link to="/advertiser/create">New Advertiser</Link>
        </Menu.Item>

        <Menu.Item key="manage-advertiser">
          <Link to="/advertiser">Manage Advertiser</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="submenu-report"
        title={
          <span>
            <PieChartFilled />
            <span>Report</span>
          </span>
        }
      >
        <Menu.Item key="report-banner">
          <Link to="/report/campaign">Campaign/Ad Report</Link>
        </Menu.Item>
        <Menu.Item key="report-campaign">
          <Link to="/report/group">Group/Unit Report</Link>
        </Menu.Item>
        <Menu.Item key="report-publisher">
          <Link to="/report/publisher">Publisher Report</Link>
        </Menu.Item>
        {/* <Menu.Item key="report-content-creator">
          <Link to="/report/creator">Creators Report</Link>
      </Menu.Item>*/}
      </SubMenu>
      <Can
        role={roleName}
        perform="menu:settings"
        yes={({ yes, no, ...props }) => (
          <SubMenu
            key="submenu-settings"
            title={
              <span>
                <SettingOutlined />
                <span>Settings</span>
              </span>
            }
            {...props}
          >
            <Menu.Item key="submenu-service-publisher">
              <Link to="/setting/publisher">Publisher</Link>
            </Menu.Item>
            <Menu.Item key="submenu-service-adgroup">
              <Link to="/setting/adgroup">Ad Group</Link>
            </Menu.Item>
            <Menu.Item key="submenu-service-adunit">
              <Link to="/setting/adunit">Ad Network Unit</Link>
            </Menu.Item>
            <Menu.Item key="submenu-service-reload">
              <a onClick={() => (window.location.pathname = '#' + new Date().toISOString())}>
                Reload App <ReloadOutlined />
              </a>
            </Menu.Item>
            {/* <Menu.Item key="submenu-service-network-adunit">
          <Link to="/setting/adnetworkunit">Ad Network Unit</Link>
        </Menu.Item> */}
          </SubMenu>
        )}
        no={() => null}
      />
      <Can
        role={roleName}
        perform="menu:users"
        yes={({ yes, no, ...props }) => (
          <SubMenu
            key="submenu-user"
            title={
              <span>
                <UserOutlined />
                <span>Users</span>
              </span>
            }
            {...props}
          >
            <Menu.Item key="user-manage">
              <Link to="/user">Users</Link>
            </Menu.Item>
            <Menu.Item key="user-role">
              <Link to="/role">Roles</Link>
            </Menu.Item>
            {/* <Menu.Item key="user-permission">
              <Link to="/permission">Role Permission</Link>
            </Menu.Item> */}
            <Menu.Item key="user-activity-log">
              <Link to="/user/activity">Activity Log</Link>
            </Menu.Item>
          </SubMenu>
        )}
        no={() => null}
      />
      <Menu.Item
        key="submenu-auth-logout"
        onClick={() => {
          dispatch({
            type: CMS_ACTION.AUTH.LOGOUT,
          });
          navigate('/');
        }}
      >
        <LogoutOutlined />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
};
