import React, { useEffect } from 'react';
import { message } from 'antd';
import { clearError, hideMessage } from '../../context/actions/Common';
import { useStore } from '../../context/AppContext';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #13c2c2;
`;

const AppInfoView = () => {
  const [appState, dispatch] = useStore();
  const { error: applicationError, message: applicationMessage, loading } = appState;

  useEffect(() => {
    if (applicationMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 5000);
    }

    if (applicationError) {
      setTimeout(() => {
        dispatch(clearError());
      }, 10000);
    }
  }, [applicationError, applicationMessage, dispatch]);

  if (applicationMessage && typeof applicationMessage === 'string') {
    message.info(<span id="message-id">{applicationMessage}</span>);
  }
  if (applicationError && typeof applicationError === 'string') {
    message.error(applicationError);
  }

  if (applicationError && Array.isArray(applicationError)) {
    applicationError.forEach((e) => message.error(e));
  }

  return (
    <div className="center-screen" style={{ position: 'fixed', top: 0 }}>
      <BarLoader color="#13c2c2" css={override} loading={loading} width={400} height={4} speedMultiplier={1} />
    </div>
  );
};

export default React.memo(AppInfoView);
